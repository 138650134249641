import React, { useState } from 'react';
import InputComponent from './input-component';

const RadioSwitch = (props) => {
  const {radioValue,defaultValue,onRadioChange,radiOption } = props
  // * Choose value which is selected, if defaultValue is included in radioValue select, otherwise default value I mean input value
  const [selectedRadio, setSelectedRadio] = useState( radioValue.some((element) => element.value === defaultValue) ? defaultValue : radioValue[1].value);
  // * handleInputValue receives defaultValue which is in the input tag (InputComponent) when we choose "other place", otherwise an empty string 
 const [handleInputValue, setHandleInputValue] = useState(radioValue.some((element) => element.value === defaultValue) ? "" : defaultValue);

  /**
   * Method handleRadioChange
   * @param {*} e 
   * * This methode help us to send the value choosen according to "radiOption"
   * ! If radiOption exist then we send inputValue which is the value of input tag and boolean to enable or disable
   * ! the button when user is typing. Otherwise we send the value chosen by the user
   */
  const handleRadioChange = (e) => {
    setSelectedRadio(e);
    const inputValue = handleInputValue !== "" && selectedRadio === radioValue.at(1).value ? handleInputValue !== "" && selectedRadio === radioValue.at(1).value ? e :handleInputValue  : e
    if (radiOption) onRadioChange({input :inputValue ,btnDisabled: e === radioValue.at(0).value ? false : true});
    else onRadioChange(e);
    
  };
  /**
   * Method handleInput
   * @param {*} e 
   * This methode help us to handle user input. We change the value of handleInputValue according to user typing
   */
   const handleInput = (e) => {
      setHandleInputValue(e.value);
    onRadioChange({input :e.value ,btnDisabled:e.btnDisabled});
    }
  return (
    <div>     
        <div className='d-flex' style={{flexDirection:"column"}} >
          {
            radioValue.map((element,index)=>(
              <div key={element.name}>
            <input
          type="radio"
          id={`radio${index+1}`}
          className='form-check-input'
          name="switchRadio"
          checked={ selectedRadio === element.value}
          onChange={() => handleRadioChange(element.value)}
          autoComplete="off"
        />
           <label htmlFor={`radio${index+1}`} style={{marginLeft:"0.5rem"}}>{element.name}</label>
              </div>         
        ))
          }
        
          </div>
          { (selectedRadio === radioValue[1].value && radiOption) && (<InputComponent
        title="Entrer l'adresse du lieu"
        inputValue={ handleInputValue}
        onInputChange={handleInput}
      />)}
    </div>
  );
};

export default RadioSwitch;
