import React, { useState } from "react";
import ReactFlagsSelect from "react-flags-select";
const SelectComponent = (props) => {
  const { defaultValue, onSelectChange } = props;
  const [selected, setSelected] = useState(defaultValue);
  return (
    <div>
      <ReactFlagsSelect
        placeholder="Choisir un pays"
        searchable
        selected={selected}
        onSelect={(code) => {
          setSelected(code);
          onSelectChange(code);
        }}
      />
    </div>
  );
};

export default SelectComponent;
