import { useState } from "react";
import { getLoggedinUser } from "../helpers/api_helper";
import api from "../helpers/api";
import { Server } from ".//utils/config";
import { useEffect, useReducer } from "react";


const useProfile = () => {
  const userProfileSession = getLoggedinUser();
  const [loading] = useState(userProfileSession ? false : true);
  const [userProfile] = useState(
    userProfileSession ? userProfileSession : null
  );
  return { userProfile, loading };
  
};

export { useProfile };

export const FetchState = {
  FETCH_INIT: 0,
  FETCH_SUCCESS: 1,
  FETCH_FAILURE: 2,
}; 

export const useContrats = (stale) => {
  const reducer = (state, action) => {
    switch (action.type) {
      case FetchState.FETCH_INIT:
        return { ...state, isLoading: true, isError: false };
      case FetchState.FETCH_SUCCESS:
        return {
          ...state,
          isLoading: false,
          isError: false,
          contrats: action.payload,
        };
      case FetchState.FETCH_FAILURE:
        return { ...state, isLoading: false, isError: true };
      default:
        throw new Error();
    }
  };

  const [state, dispatch] = useReducer(reducer, {
    isLoading: false,
    isError: false,
    contrats: [],
  });

  useEffect(() => {
    let didCancel = false;
    const getContrats = async () => {
      dispatch({ type: FetchState.FETCH_INIT });
      try {
        const data = await api.listDocuments(Server.databaseID, Server.collectionID);
        console.log(data);
        if (!didCancel) {
          console.log(data);
          dispatch({ type: FetchState.FETCH_SUCCESS, payload: data.documents });
        }
      } catch (e) {
        if (!didCancel) {
          dispatch({ type: FetchState.FETCH_FAILURE });
        }
      }
    };
    getContrats();
    return () => (didCancel = true);
  }, [stale]);

  return [state];
};
