import React from "react";
import UpdateContrat from "../../../common-component/update-service";
import "../../../../assets/css/flags.css";

const DDComponent = (props) => {
  const { data, onHandleDdComponentData } = props;

  const handleChangeData = (result, parentKey, keyElement) => {
    const getResult = typeof result === "string" ? result.trim() : result;
    if (keyElement) data[parentKey][keyElement] = getResult;
    else data[parentKey] = getResult;
    const {
      $collectionId,
      $createdAt,
      $databaseId,
      $permissions,
      $updatedAt,
      $id,
      ...rest
    } = data;
    onHandleDdComponentData($id, rest);
  };
  return (
    <>
      {data.contrat_type !== "Modèle de Règlement Intérieur" &&
        data.contrat_type !== "Politique interne de protection des données" &&
        data.contrat_type !==
          "Information et procédure d'exercice des droits des personnes concernées" &&
        data.contrat_type !==
          "Notification des violations de données à caractère personnel" && (
          <dd className="col-sm-6">
            <ul className="verti-timeline list-unstyled mt-8">
              {data.contrat_type !==
                "Note d'information à la destination des salariés" && (
                <>
                  <li className="event-list">
                    <div>
                      <span
                        style={{
                          fontSize: "18px",
                          margin: "0 0 7px 0",
                          textDecoration: "underline",
                          fontWeight: "normal",
                        }}
                      >
                        Numéro RCS (Siren)
                      </span>
                      <span
                        className="verti-timeline list-unstyled"
                        style={{ border: "none" }}
                      >
                        <div
                          style={{
                            fontSize: "14px",
                            fontWeight: "normal",
                          }}
                          className="event-list"
                        >
                          <div
                            style={{
                              backgroundColor: "#e6e2e2a1",
                              padding: "16px",
                              borderRadius: "12px",
                              marginTop: "5px",
                              justifyContent: "space-between",
                            }}
                            className="d-flex"
                          >
                            <span>
                              {data.company_id === null ||
                              data.company_id === undefined
                                ? null
                                : data.company_id.company_rcs_number}
                            </span>
                            <UpdateContrat
                              typeInput="default"
                              title="Numéro RCS (Siren)"
                              InputValue={data.company_id.company_rcs_number}
                              parentKey="company_id"
                              keyElement="company_rcs_number"
                              OnUpdateValue={handleChangeData}
                            />
                          </div>
                        </div>
                      </span>
                    </div>
                  </li>
                  <li className="event-list">
                    <div>
                      <span
                        style={{
                          fontSize: "18px",
                          margin: "0 0 7px 0",
                          textDecoration: "underline",
                          fontWeight: "normal",
                        }}
                      >
                        Ville d'immatriculation au RCS
                      </span>
                      <span
                        className="verti-timeline list-unstyled"
                        style={{ border: "none" }}
                      >
                        <div
                          style={{
                            fontSize: "14px",
                            fontWeight: "normal",
                          }}
                          className="event-list"
                        >
                          <div
                            style={{
                              backgroundColor: "#e6e2e2a1",
                              padding: "16px",
                              borderRadius: "12px",
                              marginTop: "5px",
                              justifyContent: "space-between",
                            }}
                            className="d-flex"
                          >
                            <sapn>
                              {data.company_id === null ||
                              data.company_id === undefined
                                ? null
                                : data.company_id.company_rcs_city}
                            </sapn>
                            <UpdateContrat
                              typeInput="default"
                              title="Ville d'immatriculation au RCS"
                              InputValue={data.company_id.company_rcs_city}
                              parentKey="company_id"
                              keyElement="company_rcs_city"
                              OnUpdateValue={handleChangeData}
                            />
                          </div>
                        </div>
                      </span>
                    </div>
                  </li>
                  <li className="event-list">
                    <div>
                      <span
                        style={{
                          fontSize: "18px",
                          margin: "0 0 7px 0",
                          textDecoration: "underline",
                          fontWeight: "normal",
                        }}
                      >
                        Pays
                      </span>
                      <span
                        className="verti-timeline list-unstyled"
                        style={{ border: "none" }}
                      >
                        <div
                          style={{
                            fontSize: "14px",
                            fontWeight: "normal",
                          }}
                          className="event-list"
                        >
                          <div
                            style={{
                              backgroundColor: "#e6e2e2a1",
                              padding: "16px",
                              borderRadius: "12px",
                              marginTop: "5px",
                              justifyContent: "space-between",
                            }}
                            className="d-flex"
                          >
                            <span
                              className={`flag ${data.company_id.company_country.toLowerCase()}`}
                            ></span>

                            <UpdateContrat
                              typeInput="select"
                              title="Pays"
                              InputValue={data.company_id.company_country}
                              parentKey="company_id"
                              keyElement="company_country"
                              OnUpdateValue={handleChangeData}
                            />
                          </div>
                        </div>
                      </span>
                    </div>
                  </li>
                </>
              )}

              <li className="event-list">
                <div>
                  <span
                    style={{
                      fontSize: "18px",
                      margin: "0 0 7px 0",
                      textDecoration: "underline",
                      fontWeight: "normal",
                    }}
                  >
                    {data.contrat_type !==
                    "Note d'information à la destination des salariés"
                      ? "Nom et prénom du représentant"
                      : "Nom responsable de traitement"}
                  </span>
                  <span
                    className="verti-timeline list-unstyled"
                    style={{ border: "none" }}
                  >
                    <div
                      style={{
                        fontSize: "14px",
                        fontWeight: "normal",
                      }}
                      className="event-list"
                    >
                      <div
                        style={{
                          backgroundColor: "#e6e2e2a1",
                          padding: "16px",
                          borderRadius: "12px",
                          marginTop: "5px",
                          justifyContent: "space-between",
                        }}
                        className="d-flex"
                      >
                        <span>
                          {data.company_id === null ||
                          data.company_id === undefined
                            ? null
                            : data.company_id.company_dg}
                        </span>
                        <UpdateContrat
                          typeInput="default"
                          title={
                            data.contrat_type !==
                            "Note d'information à la destination des salariés"
                              ? "Nom et prénom du représentant"
                              : "Nom responsable de traitement"
                          }
                          InputValue={data.company_id.company_dg}
                          parentKey="company_id"
                          keyElement="company_dg"
                          OnUpdateValue={handleChangeData}
                        />
                      </div>
                    </div>
                  </span>
                </div>
              </li>
              {data.contrat_type !==
                "Note d'information à la destination des salariés" && (
                <li className="event-list">
                  <div>
                    <span
                      style={{
                        fontSize: "18px",
                        margin: "0 0 7px 0",
                        textDecoration: "underline",
                        fontWeight: "normal",
                      }}
                    >
                      Qualité du représentant
                    </span>
                    <span
                      className="verti-timeline list-unstyled"
                      style={{ border: "none" }}
                    >
                      <div
                        style={{
                          fontSize: "14px",
                          fontWeight: "normal",
                        }}
                        className="event-list"
                      >
                        <div
                          style={{
                            backgroundColor: "#e6e2e2a1",
                            padding: "16px",
                            borderRadius: "12px",
                            marginTop: "5px",
                            justifyContent: "space-between",
                          }}
                          className="d-flex"
                        >
                          <span>
                            {data.company_id === null ||
                            data.company_id === undefined
                              ? null
                              : data.company_id.company_dg_quality}
                          </span>
                          <UpdateContrat
                            typeInput="default"
                            title="Qualité du représentant"
                            InputValue={data.company_id.company_dg_quality}
                            parentKey="company_id"
                            keyElement="company_dg_quality"
                            OnUpdateValue={handleChangeData}
                          />
                        </div>
                      </div>
                    </span>
                  </div>
                </li>
              )}
            </ul>
          </dd>
        )}
    </>
  );
};

export default DDComponent;
