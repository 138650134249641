import React, { useState } from "react";
import UpdateCompany from "../../../common-component/update-service";
import "../../../../assets/css/flags.css";

const DtComponent = (props) => {
  const { data, parentId, allData, onHandleDtComponentData } = props;
  const [isHovered, setIsHovered] = useState({
    hoverState1: false,
    hoverState2: false,
    hoverState3: false,
    hoverState4: false,
    hoverState5: false,
    hoverState6: false,
    hoverState7: false,
  });
  const handleChangeData = (result, parentKey) => {
    const getResult = typeof result === "string" ? result.trim() : result;
    if (parentKey) data[parentKey] = getResult;
    allData[parentId] = JSON.stringify(data);
    const {
      $collectionId,
      $createdAt,
      $databaseId,
      $permissions,
      $updatedAt,
      $id,
      ...rest
    } = allData;
    console.log({ rest });

    onHandleDtComponentData($id, rest);
  };
  return (
    <dt className="col-sm-6">
      <ul className="verti-timeline list-unstyled">
        <li className="event-list">
          <div>
            <span
              style={{
                fontSize: "18px",
                margin: "0 0 7px 0",
                textDecoration: "underline",
                fontWeight: "normal",
              }}
            >
              Téléphone
            </span>
            <span
              className="verti-timeline list-unstyled"
              style={{ border: "none" }}
              onMouseEnter={() =>
                setIsHovered((prev) => ({ ...prev, hoverState1: true }))
              }
              onMouseLeave={() =>
                setIsHovered((prev) => ({ ...prev, hoverState1: false }))
              }
            >
              <div
                style={{
                  fontSize: "14px",
                  fontWeight: "normal",
                }}
                className="event-list"
              >
                <div
                  style={{
                    backgroundColor: "#e6e2e2a1",
                    padding: "16px",
                    borderRadius: "12px",
                    marginTop: "5px",
                    justifyContent: "space-between",
                  }}
                  className="d-flex"
                >
                  <span>{data.phone}</span>
                  {isHovered.hoverState1 && (
                    <UpdateCompany
                      typeInput="default"
                      title="Téléphone"
                      InputValue={data.phone}
                      parentKey="phone"
                      OnUpdateValue={handleChangeData}
                    />
                  )}
                </div>
              </div>
            </span>
          </div>
        </li>
        <li className="event-list">
          <div>
            <span
              style={{
                fontSize: "18px",
                margin: "0 0 7px 0",
                textDecoration: "underline",
                fontWeight: "normal",
              }}
            >
              Adresse 1
            </span>
            <span
              className="verti-timeline list-unstyled"
              style={{ border: "none" }}
              onMouseEnter={() =>
                setIsHovered((prev) => ({ ...prev, hoverState2: true }))
              }
              onMouseLeave={() =>
                setIsHovered((prev) => ({ ...prev, hoverState2: false }))
              }
            >
              <div
                style={{
                  fontSize: "14px",
                  fontWeight: "normal",
                }}
                className="event-list"
              >
                <div
                  style={{
                    backgroundColor: "#e6e2e2a1",
                    padding: "16px",
                    borderRadius: "12px",
                    marginTop: "5px",
                    justifyContent: "space-between",
                  }}
                  className="d-flex"
                >
                  <span>{data.adress}</span>
                  {isHovered.hoverState2 && (
                    <UpdateCompany
                      typeInput="default"
                      title="Adresse 1"
                      InputValue={data.adress}
                      parentKey="adress"
                      OnUpdateValue={handleChangeData}
                    />
                  )}
                </div>
              </div>
            </span>
          </div>
        </li>

        <li className="event-list">
          <div>
            <span
              style={{
                fontSize: "18px",
                margin: "0 0 7px 0",
                textDecoration: "underline",
                fontWeight: "normal",
              }}
            >
              Adresse 2
            </span>
            <span
              className="verti-timeline list-unstyled"
              style={{ border: "none" }}
              onMouseEnter={() =>
                setIsHovered((prev) => ({ ...prev, hoverState3: true }))
              }
              onMouseLeave={() =>
                setIsHovered((prev) => ({ ...prev, hoverState3: false }))
              }
            >
              <div
                style={{
                  fontSize: "14px",
                  fontWeight: "normal",
                }}
                className="event-list"
              >
                <div
                  style={{
                    backgroundColor: "#e6e2e2a1",
                    padding: "16px",
                    borderRadius: "12px",
                    marginTop: "5px",
                    justifyContent: "space-between",
                  }}
                  className="d-flex"
                >
                  <span>{data.adress2}</span>
                  {isHovered.hoverState3 && (
                    <UpdateCompany
                      typeInput="default"
                      title="Adresse 2"
                      InputValue={data.adress2}
                      parentKey="adress2"
                      OnUpdateValue={handleChangeData}
                    />
                  )}
                </div>
              </div>
            </span>
          </div>
        </li>

        <li className="event-list">
          <div>
            <span
              style={{
                fontSize: "18px",
                margin: "0 0 7px 0",
                textDecoration: "underline",
                fontWeight: "normal",
              }}
            >
              Numéro de rue
            </span>
            <span
              className="verti-timeline list-unstyled"
              style={{ border: "none" }}
              onMouseEnter={() =>
                setIsHovered((prev) => ({ ...prev, hoverState4: true }))
              }
              onMouseLeave={() =>
                setIsHovered((prev) => ({ ...prev, hoverState4: false }))
              }
            >
              <div
                style={{
                  fontSize: "14px",
                  fontWeight: "normal",
                }}
                className="event-list"
              >
                <div
                  style={{
                    backgroundColor: "#e6e2e2a1",
                    padding: "16px",
                    borderRadius: "12px",
                    marginTop: "5px",
                    justifyContent: "space-between",
                  }}
                  className="d-flex"
                >
                  <span>{data.streetNum}</span>
                  {isHovered.hoverState4 && (
                    <UpdateCompany
                      typeInput="default"
                      title="Numéro de rue"
                      InputValue={data.streetNum}
                      parentKey="streetNum"
                      OnUpdateValue={handleChangeData}
                    />
                  )}
                </div>
              </div>
            </span>
          </div>
        </li>

        <li className="event-list">
          <div>
            <span
              style={{
                fontSize: "18px",
                margin: "0 0 7px 0",
                textDecoration: "underline",
                fontWeight: "normal",
              }}
            >
              Nom de rue
            </span>
            <span
              className="verti-timeline list-unstyled"
              style={{ border: "none" }}
              onMouseEnter={() =>
                setIsHovered((prev) => ({ ...prev, hoverState5: true }))
              }
              onMouseLeave={() =>
                setIsHovered((prev) => ({ ...prev, hoverState5: false }))
              }
            >
              <div
                style={{
                  fontSize: "14px",
                  fontWeight: "normal",
                }}
                className="event-list"
              >
                <div
                  style={{
                    backgroundColor: "#e6e2e2a1",
                    padding: "16px",
                    borderRadius: "12px",
                    marginTop: "5px",
                    justifyContent: "space-between",
                  }}
                  className="d-flex"
                >
                  <span>{data.streetName}</span>
                  {isHovered.hoverState5 && (
                    <UpdateCompany
                      typeInput="default"
                      title="Nom de rue"
                      InputValue={data.streetName}
                      parentKey="streetName"
                      OnUpdateValue={handleChangeData}
                    />
                  )}
                </div>
              </div>
            </span>
          </div>
        </li>
        <li className="event-list">
          <div>
            <span
              style={{
                fontSize: "18px",
                margin: "0 0 7px 0",
                textDecoration: "underline",
                fontWeight: "normal",
              }}
            >
              Ville
            </span>
            <span
              className="verti-timeline list-unstyled"
              style={{ border: "none" }}
              onMouseEnter={() =>
                setIsHovered((prev) => ({ ...prev, hoverState6: true }))
              }
              onMouseLeave={() =>
                setIsHovered((prev) => ({ ...prev, hoverState6: false }))
              }
            >
              <div
                style={{
                  fontSize: "14px",
                  fontWeight: "normal",
                }}
                className="event-list"
              >
                <div
                  style={{
                    backgroundColor: "#e6e2e2a1",
                    padding: "16px",
                    borderRadius: "12px",
                    marginTop: "5px",
                    justifyContent: "space-between",
                  }}
                  className="d-flex"
                >
                  <span>{data.city}</span>
                  {isHovered.hoverState6 && (
                    <UpdateCompany
                      typeInput="default"
                      title="Ville"
                      InputValue={data.city}
                      parentKey="city"
                      OnUpdateValue={handleChangeData}
                    />
                  )}
                </div>
              </div>
            </span>
          </div>
        </li>

        <li className="event-list">
          <div>
            <span
              style={{
                fontSize: "18px",
                margin: "0 0 7px 0",
                textDecoration: "underline",
                fontWeight: "normal",
              }}
            >
              Pays
            </span>
            <span
              className="verti-timeline list-unstyled"
              style={{ border: "none" }}
              onMouseEnter={() =>
                setIsHovered((prev) => ({ ...prev, hoverState7: true }))
              }
              onMouseLeave={() =>
                setIsHovered((prev) => ({ ...prev, hoverState7: false }))
              }
            >
              <div
                style={{
                  fontSize: "14px",
                  fontWeight: "normal",
                }}
                className="event-list"
              >
                <div
                  style={{
                    backgroundColor: "#e6e2e2a1",
                    padding: "16px",
                    borderRadius: "12px",
                    marginTop: "5px",
                    justifyContent: "space-between",
                  }}
                  className="d-flex"
                >
                  <span
                    className={`flag ${data.countryCode.toLowerCase()}`}
                  ></span>
                  {isHovered.hoverState7 && (
                    <UpdateCompany
                      typeInput="select"
                      title="Pays"
                      InputValue={data.countryCode}
                      parentKey="countryCode"
                      OnUpdateValue={handleChangeData}
                    />
                  )}
                </div>
              </div>
            </span>
          </div>
        </li>
      </ul>
    </dt>
  );
};

export default DtComponent;
