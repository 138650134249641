import React from "react";
import convertDate from "../../../Utility/hooks/dateConvert";
import UpdateContrat from "../../../common-component/update-service";
import StaticData from "../../../Utility/hooks/static";

const DtComponent = (props) => {
  const { data, onHandleDtComponentData } = props;

  const handleChangeData = async (result, parentKey, keyElement) => {
    const getResult = typeof result === "string" ? result.trim() : result;
    if (keyElement) data[parentKey][keyElement] = getResult;
    else data[parentKey] = getResult;
    const {
      $collectionId,
      $createdAt,
      $databaseId,
      $permissions,
      $updatedAt,
      $id,
      ...rest
    } = data;
    rest.revision_date = rest.revision_date.toString();
    onHandleDtComponentData($id, rest);
  };
  return (
    <>
      {data.contrat_type !== "Politique de gestion des cookies" &&
        data.contrat_type !== "Politique interne de protection des données" && (
          <dt className="col-sm-6">
            <ul className="verti-timeline list-unstyled">
              {data.contrat_type !== "Politique de confidentialité" &&
                data.contrat_type !==
                  "Note d'information à la destination des salariés" && (
                  <>
                    <li className="event-list">
                      <div>
                        <span
                          style={{
                            fontSize: "18px",
                            margin: "0 0 7px 0",
                            textDecoration: "underline",
                            fontWeight: "normal",
                          }}
                        >
                          Nature de la lettre
                        </span>
                        <span
                          className="verti-timeline list-unstyled"
                          style={{ border: "none" }}
                        >
                          <p
                            style={{
                              fontSize: "14px",
                              fontWeight: "normal",
                            }}
                            className="event-list"
                          >
                            <div
                              style={{
                                backgroundColor: "#e6e2e2a1",
                                padding: "16px",
                                borderRadius: "12px",
                                marginTop: "5px",
                              }}
                            >
                              {data.company_id === null ||
                              data.company_id === undefined
                                ? null
                                : data.company_id.contrat_letter_type}
                            </div>
                          </p>
                        </span>
                      </div>
                    </li>
                    <li className="event-list">
                      <div>
                        <span
                          style={{
                            fontSize: "18px",
                            margin: "0 0 7px 0",
                            textDecoration: "underline",
                            fontWeight: "normal",
                          }}
                        >
                          Nom du responsable de traitement
                        </span>
                        <span
                          className="verti-timeline list-unstyled"
                          style={{ border: "none" }}
                        >
                          <p
                            style={{
                              fontSize: "14px",
                              fontWeight: "normal",
                            }}
                            className="event-list"
                          >
                            <div
                              style={{
                                backgroundColor: "#e6e2e2a1",
                                padding: "16px",
                                borderRadius: "12px",
                                marginTop: "5px",
                              }}
                            >
                              {data.company_id === null ||
                              data.company_id === undefined
                                ? null
                                : data.company_id.company_dpo}
                            </div>
                          </p>
                        </span>
                      </div>
                    </li>
                  </>
                )}
              {data.company_id.company_dpo_email === "" &&
                data.company_id.company_email !== null && (
                  <li className="event-list">
                    <div>
                      <span
                        style={{
                          fontSize: "18px",
                          margin: "0 0 7px 0",
                          textDecoration: "underline",
                          fontWeight: "normal",
                        }}
                      >
                        Adresse mail de l'entreprise
                      </span>
                      <span
                        className="verti-timeline list-unstyled"
                        style={{ border: "none" }}
                      >
                        <div
                          style={{
                            fontSize: "14px",
                            fontWeight: "normal",
                          }}
                          className="event-list"
                        >
                          <div
                            style={{
                              backgroundColor: "#e6e2e2a1",
                              padding: "16px",
                              borderRadius: "12px",
                              marginTop: "5px",
                              justifyContent: "space-between",
                            }}
                            className="d-flex"
                          >
                            <span>
                              {data.company_id === null ||
                              data.company_id === undefined
                                ? null
                                : data.company_id.company_email}
                            </span>
                            <UpdateContrat
                              title="Adresse mail de l'entreprise"
                              InputValue={data.company_id.company_email}
                              parentKey="company_id"
                              keyElement="company_email"
                              typeInput="default"
                              OnUpdateValue={handleChangeData}
                            />
                          </div>
                        </div>
                      </span>
                    </div>
                  </li>
                )}

              {data.contrat_type !== "Politique de confidentialité" && (
                <li className="event-list">
                  <div>
                    <span
                      style={{
                        fontSize: "18px",
                        margin: "0 0 7px 0",
                        textDecoration: "underline",
                        fontWeight: "normal",
                      }}
                    >
                      Adresse du site de l'entreprise
                    </span>
                    <span
                      className="verti-timeline list-unstyled"
                      style={{ border: "none" }}
                    >
                      <p
                        style={{
                          fontSize: "14px",
                          fontWeight: "normal",
                        }}
                        className="event-list"
                      >
                        <div
                          style={{
                            backgroundColor: "#e6e2e2a1",
                            padding: "16px",
                            borderRadius: "12px",
                            marginTop: "5px",
                            justifyContent: "space-between",
                          }}
                          className="d-flex"
                        >
                          <span>
                            {data.company_id === null ||
                            data.company_id === undefined
                              ? null
                              : data.company_id.company_website}
                          </span>
                          <UpdateContrat
                            title="Adresse du site de l'entreprise"
                            InputValue={data.company_id.company_website}
                            parentKey="company_id"
                            keyElement="company_website"
                            typeInput="default"
                            OnUpdateValue={handleChangeData}
                          />
                        </div>
                      </p>
                    </span>
                  </div>
                </li>
              )}

              <li className="event-list">
                <div>
                  <div
                    style={{
                      fontSize: "18px",
                      margin: "0 0 7px 0",
                      textDecoration: "underline",
                      fontWeight: "normal",
                      justifyContent: "space-between",
                    }}
                    className="d-flex"
                  >
                    <span>Liste de finalités de l'entreprise</span>
                    <UpdateContrat
                      title="Liste de finalités de l'entreprise"
                      InputValue={data.company_id.company_infos_goals}
                      parentKey="company_id"
                      keyElement="company_infos_goals"
                      OnUpdateValue={handleChangeData}
                      typeInput="checkbox"
                      typeValue={data.company_id.company_infos_goals.filter(
                        (element) =>
                          !StaticData.CheckBox.BusinesPpurposes.includes(element)
                      )
                        ? StaticData.CheckBox.BusinesPpurposes.concat(
                            data.company_id.company_infos_goals.filter(
                              (element) =>
                                !StaticData.CheckBox.BusinesPpurposes.includes(
                                  element
                                )
                            )
                          )
                        : StaticData.CheckBox.BusinesPpurposes}
                    />
                  </div>

                  {data.company_id === null ||
                  data.company_id === undefined ? null : data.company_id
                      .company_infos_goals.length === 0 ? null : (
                    <dl className="row mb-0">
                      {data.company_id.company_infos_goals.map((val, index) => (
                        <React.Fragment key={index}>
                          {index < data.company_id.company_infos_goals / 2 ? (
                            <dd className="col-sm-6">
                              <span
                                className="verti-timeline list-unstyled"
                                style={{ border: "none" }}
                              >
                                <div
                                  style={{
                                    fontSize: "12px",
                                    fontWeight: "normal",
                                  }}
                                  className="event-list"
                                >
                                  <p
                                    style={{
                                      backgroundColor: "#e6e2e2a1",
                                      padding: "16px",
                                      borderRadius: "12px",
                                      marginTop: "5px",
                                      fontSize: "14px",
                                    }}
                                  >
                                    - {val}
                                  </p>
                                </div>
                              </span>
                            </dd>
                          ) : (
                            <dt className="col-sm-6">
                              <span
                                className="verti-timeline list-unstyled"
                                style={{ border: "none" }}
                              >
                                <p
                                  style={{
                                    fontSize: "12px",
                                    fontWeight: "normal",
                                  }}
                                  className="event-list"
                                >
                                  <div
                                    style={{
                                      backgroundColor: "#e6e2e2a1",
                                      padding: "16px",
                                      borderRadius: "12px",
                                      marginTop: "5px",
                                      fontSize: "14px",
                                    }}
                                  >
                                    - {val}
                                  </div>
                                </p>
                              </span>
                            </dt>
                          )}
                        </React.Fragment>
                      ))}
                    </dl>
                  )}
                </div>
              </li>
              {data.contrat_type !==
                "Note d'information à la destination des salariés" && (
                <>
                  <li className="event-list">
                    <div>
                      <span
                        style={{
                          fontSize: "18px",
                          margin: "0 0 7px 0",
                          textDecoration: "underline",
                          fontWeight: "normal",
                        }}
                      >
                        Date de révision
                      </span>
                      <span
                        className="verti-timeline list-unstyled"
                        style={{ border: "none" }}
                      >
                        <div
                          style={{
                            fontSize: "14px",
                            fontWeight: "normal",
                          }}
                          className="event-list"
                        >
                          <div
                            style={{
                              backgroundColor: "#e6e2e2a1",
                              padding: "16px",
                              borderRadius: "12px",
                              marginTop: "5px",
                              justifyContent: "space-between",
                            }}
                            className="d-flex"
                          >
                            <span>
                              {data.company_id === null ||
                              data.company_id === undefined
                                ? null
                                : convertDate.convertMillisecondToDate(
                                    data.revision_date
                                  )}
                            </span>
                            <UpdateContrat
                              title="Date de révision"
                              InputValue={data.revision_date}
                              parentKey="revision_date"
                              OnUpdateValue={handleChangeData}
                              typeInput="date"
                            />
                          </div>
                        </div>
                      </span>
                    </div>
                  </li>
                  <li className="event-list">
                    <div>
                      <div
                        style={{
                          fontSize: "18px",
                          margin: "0 0 7px 0",
                          textDecoration: "underline",
                          fontWeight: "normal",
                          justifyContent: "space-between",
                        }}
                        className="d-flex"
                      >
                        <span>Informations recuillies</span>
                        <UpdateContrat
                          title="Informations recuillies"
                          InputValue={data.company_id.company_website_infos}
                          parentKey="company_id"
                          keyElement="company_website_infos"
                          OnUpdateValue={handleChangeData}
                          typeInput="checkbox"
                          typeValue={StaticData.CheckBox.DataCollect}
                        />
                      </div>
                      {data.company_id === null ||
                      data.company_id === undefined ? null : data.company_id
                          .company_website_infos.length === 0 ? null : (
                        <dl className="row mb-0">
                          {data.company_id.company_website_infos.map(
                            (val, index) => (
                              <React.Fragment key={index}>
                                {index <
                                data.company_id.company_website_infos ? (
                                  <dd className="col-sm-6">
                                    <span
                                      className="verti-timeline list-unstyled"
                                      style={{ border: "none" }}
                                    >
                                      <div
                                        style={{
                                          fontSize: "12px",
                                          fontWeight: "normal",
                                        }}
                                        className="event-list"
                                      >
                                        <div
                                          style={{
                                            backgroundColor: "#e6e2e2a1",
                                            padding: "16px",
                                            borderRadius: "12px",
                                            marginTop: "5px",
                                            fontSize: "14px",
                                          }}
                                        >
                                          - {val}
                                        </div>
                                      </div>
                                    </span>
                                  </dd>
                                ) : (
                                  <dt className="col-sm-6">
                                    <span
                                      className="verti-timeline list-unstyled"
                                      style={{ border: "none" }}
                                    >
                                      <div
                                        style={{
                                          fontSize: "12px",
                                          fontWeight: "normal",
                                        }}
                                        className="event-list"
                                      >
                                        <div
                                          style={{
                                            backgroundColor: "#e6e2e2a1",
                                            padding: "16px",
                                            borderRadius: "12px",
                                            marginTop: "5px",
                                            fontSize: "14px",
                                          }}
                                        >
                                          - {val}
                                        </div>
                                      </div>
                                    </span>
                                  </dt>
                                )}
                              </React.Fragment>
                            )
                          )}
                        </dl>
                      )}
                    </div>
                  </li>

                  <li className="event-list">
                    <div>
                      <span
                        style={{
                          fontSize: "18px",
                          margin: "0 0 7px 0",
                          textDecoration: "underline",
                          fontWeight: "normal",
                        }}
                      >
                        Lieu de stockage des informations recuillies
                      </span>
                      <span
                        className="verti-timeline list-unstyled"
                        style={{ border: "none" }}
                      >
                        <div
                          style={{
                            fontSize: "14px",
                            fontWeight: "normal",
                          }}
                          className="event-list"
                        >
                          <div
                            style={{
                              backgroundColor: "#e6e2e2a1",
                              padding: "16px",
                              borderRadius: "12px",
                              marginTop: "5px",
                              justifyContent: "space-between",
                            }}
                            className="d-flex"
                          >
                            <span>
                              {data.company_id === null ||
                              data.company_id === undefined
                                ? null
                                : data.company_id.company_infos_stock_place}
                            </span>
                            <UpdateContrat
                              title="Lieu de stockage des informations recuillies"
                              InputValue={
                                data.company_id.company_infos_stock_place
                              }
                              parentKey="company_id"
                              keyElement="company_infos_stock_place"
                              OnUpdateValue={handleChangeData}
                              typeInput="default"
                            />
                          </div>
                        </div>
                      </span>
                    </div>
                  </li>
                  <li className="event-list">
                    <div>
                      <span
                        style={{
                          fontSize: "18px",
                          margin: "0 0 7px 0",
                          textDecoration: "underline",
                          fontWeight: "normal",
                        }}
                      >
                        Traitement aux mineurs
                      </span>
                      <span
                        className="verti-timeline list-unstyled"
                        style={{ border: "none" }}
                      >
                        <div
                          style={{
                            fontSize: "14px",
                            fontWeight: "normal",
                          }}
                          className="event-list"
                        >
                          <div
                            style={{
                              backgroundColor: "#e6e2e2a1",
                              padding: "16px",
                              borderRadius: "12px",
                              marginTop: "5px",
                              justifyContent: "space-between",
                            }}
                            className="d-flex"
                          >
                            <span>
                              {data.company_id === null ||
                              data.company_id === undefined
                                ? null
                                : data.teenagers_rights
                                ? StaticData.Radio.TeenagersRights.at(0).name
                                : StaticData.Radio.TeenagersRights.at(1).name}
                            </span>
                            <UpdateContrat
                              title="Traitement aux mineurs"
                              InputValue={data.teenagers_rights}
                              parentKey="teenagers_rights"
                              OnUpdateValue={handleChangeData}
                              typeInput="radio"
                              typeValue={StaticData.Radio.TeenagersRights}
                            />
                          </div>
                        </div>
                      </span>
                    </div>
                  </li>
                </>
              )}
            </ul>
          </dt>
        )}
    </>
  );
};

export default DtComponent;
