import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import {
  getContrats,
  deleteContract,
  searchUsers,
  searchContrat,
} from "../../helpers/api_helper";
import logoLegi from "../../assets/images/logo_legihub_test.png";
import { Toast, ToastHeader, ToastBody, Spinner } from "reactstrap";
import convertDate from "../Utility/hooks/dateConvert";
import Icon from "../Utility/hooks/component";

const Contrat = (props) => {
  const { data } = props;
  const navigate = useNavigate();

  const [contracts, setContracts] = useState([]);
  const [loadingIcon, setloadingIcon] = useState({
    loadCDDDdata: true,
    showDeleteIcon: false,
    loadCDDdataRemove: false,
    error: false,
  });
  const [returnID, setReturnID] = useState([]);
  const [toggledClearRows, setToggleClearRows] = useState(false);
  const [toast, settoast] = useState(false);
  const [deleteCount, setDeleteCount] = useState({
    countElement: "",
    totalElement: "",
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [totalRowsCustom, setTotalRowsCustom] = useState(0);
  const [selectedValue, setSelectedValue] = useState("user_id");
  const [input, setInput] = useState("");
  const [users, setUsers] = useState([]);

  useEffect(() => {
    fetchContrats(1);
  }, []);

  const IconFromStatus = {
    Confirm: "primary",
    Cancel: "danger",
    Pending: "warning",
  };
  const handleStateRender = {
    Pending: "En attente",
    Accepted: "Accepté",
    Rejected: "Rejecté",
  };
  const column = [
    {
      name: <span className="font-weight-bold fs-13">Entreprise</span>,
      selector: (row) => (
        <td onClick={() => handleEditClick(row.$id)}>
          {row.company_id.company_name}
        </td>
      ),
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Représentant</span>,
      selector: (row) => (
        <td onClick={() => handleEditClick(row.$id)}>
          {row.company_id.company_dg}
        </td>
      ),
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Siren</span>,
      selector: (row) => (
        <td onClick={() => handleEditClick(row.$id)}>
          {row.company_id.company_rcs_number}
        </td>
      ),
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Email</span>,
      selector: (row) => (
        <td onClick={() => handleEditClick(row.$id)}>
          {row.company_id.company_email}
        </td>
      ),
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Date de demande</span>,
      selector: (row) => (
        <td onClick={() => handleEditClick(row.$id)}>
          {convertDate.convertHourlyToDate(row.$createdAt)}
        </td>
      ),

      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Nom du client</span>,
      selector: (row) => (
        <td onClick={() => handleEditClick(row.$id)}>
          {`${
            users &&
            users.length > 0 &&
            users.find((el) => el.id === row.user_id)
              ? users.find((el) => el.id === row.user_id).name
              : "-"
          }`}
        </td>
      ),

      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Email du client</span>,
      selector: (row) => (
        <td onClick={() => handleEditClick(row.$id)}>
          {`${
            users &&
            users.length > 0 &&
            users.find((el) => el.id === row.user_id)
              ? users.find((el) => el.id === row.user_id).email
              : "-"
          }`}
        </td>
      ),

      sortable: true,
    },

    {
      name: <span className="font-weight-bold fs-13">Statut</span>,
      selector: (row) => (
        <td onClick={() => handleEditClick(row.$id)}>
          <span
            className={"badge badge-soft-" + IconFromStatus[row.contrat_status]}
          >
            {handleStateRender[row.contrat_status]}
          </span>
        </td>
      ),

      sortable: true,
    },

    {
      name: <span className="font-weight-bold fs-13">Actions</span>,
      selector: (row) => (
        <td>
          <button
            type="button"
            className="btn btn-outline-success btn-sm me-1"
            onClick={() => handleEditClick(row.$id)}
          >
            <i className="fas fa-eye font-size-15"></i>
          </button>
        </td>
      ),
      sortable: true,
    },
  ];

  const handleColumn = (name) => {
    const subCol = [
      column.at(4),
      column.at(5),
      column.at(6),
      column.at(7),
      column.at(8),
    ];
    const colomn1 = [column.at(0), ...subCol];
    const colomn2 = [column.at(1), column.at(3), ...subCol];
    const colomn3 = [column.at(0), column.at(1), column.at(2), ...subCol];
    const commonColumn1 = [
      "Politique de gestion des cookies",
      "Modèle de Règlement Intérieur",
      "Politique de confidentialité",
    ];
    const commonColumn2 = [
      "Politique interne de protection des données",
      "Information et procédure d'exercice des droits des personnes concernées",
      "Notification des violations de données à caractère personnel",
    ];
    if (commonColumn1.includes(name)) {
      if (name === commonColumn1.at(2)) {
        subCol.unshift(column.at(3));
      }
      return {
        col: subCol,
        searchBy: [{ label: "E-mail client", value: "user_id" }],
      };
    } else if (commonColumn2.includes(name)) {
      return {
        col: colomn1,
        searchBy: [{ label: "E-mail client", value: "user_id" }],
      };
    } else if (name === "Note d'information à la destination des salariés") {
      return {
        col: colomn2,
        searchBy: [{ label: "E-mail client", value: "user_id" }],
      };
    } else {
      return {
        col: colomn3,
        searchBy: [{ label: "E-mail client", value: "user_id" }],
      };
    }
  };

  const handleEditClick = (selectedContratId) => {
    navigate("/contrat-detail", {
      replace: false,
      state: selectedContratId,
    });
  };

  const fetchContrats = async (page, limit) => {
    console.log("HEEELLOO BRRROOO");
    console.log({ data });

    try {
      setloadingIcon((prev) => ({ ...prev, loadCDDDdata: true }));

      const cursorBefore = page < currentPage;
      const cursor =
        contracts.length > 0
          ? cursorBefore
            ? contracts[0].$id
            : contracts[contracts.length - 1].$id
          : undefined;
      const response = await getContrats(
        data,
        limit ?? perPage,
        cursor,
        cursorBefore
      );

      setContracts(response.documents);
      setCurrentPage(page);
      setTotalRowsCustom(response.total);
      fecthUser(response.documents);
      setloadingIcon((prev) => ({ ...prev, loadCDDDdata: false }));
    } catch (error) {
      console.log({ error });

      setloadingIcon((prev) => ({ ...prev, loadCDDDdata: false }));
      setloadingIcon((prev) => ({ ...prev, error: true }));
    }
  };
  const searchContrats = async (page, field, search, limit) => {
    try {
      setloadingIcon((prev) => ({ ...prev, loadCDDDdata: true }));
      let theUserId;
      if (field === "user_id") {
        const getUserId = await searchUsers(search);
        theUserId =
          getUserId.users && getUserId.users.length > 0
            ? getUserId.users[0].$id
            : undefined;
        console.log({ getUserId });
        console.log({ theUserId });
      }

      const cursorBefore = page < currentPage;
      const cursor =
        search && page === 1
          ? undefined
          : contracts.length > 0
          ? cursorBefore
            ? contracts[0].$id
            : contracts[contracts.length - 1].$id
          : undefined;
      const response = await searchContrat(
        data,
        limit ?? perPage,
        cursor,
        cursorBefore,
        field === "user_id" ? theUserId : search,
        field
      );

      setContracts(response.documents);
      setTotalRowsCustom(response.total);
      setCurrentPage(page);

      setloadingIcon((prev) => ({ ...prev, loadCDDDdata: false }));
    } catch (error) {
      console.log({ error });

      setloadingIcon((prev) => ({ ...prev, loadCDDDdata: false }));
      setloadingIcon((prev) => ({ ...prev, error: true }));
    }
  };
  const handlePageChange = (page) => {
    fetchContrats(page);
    console.log("testHANDLEPAGEchange page", page);
  };
  const handleSelectElement = async ({ selectedRows }) => {
    setloadingIcon((prev) => ({ ...prev, showDeleteIcon: false }));

    if (selectedRows.length > 0) {
      setloadingIcon((prev) => ({ ...prev, showDeleteIcon: true }));
      const id = selectedRows.map((map) => map.$id);

      setReturnID(id);
    }
  };
  const handleSearch = async (searchValue) => {
    searchContrats(1, selectedValue, searchValue);
  };
  const removeMultipleContract = async () => {
    setloadingIcon((prev) => ({ ...prev, loadCDDdataRemove: true }));
    setDeleteCount((prev) => ({ ...prev, totalElement: returnID.length }));
    toggleToast();
    for (let index = 0; index < returnID.length; index++) {
      const element = returnID[index];
      const val = await deleteContract(element);
      if (val) {
        setDeleteCount((prev) => ({ ...prev, countElement: index + 1 }));
      }
    }
    const contrats = await getContrats(data);
    setContracts(contrats);
    setloadingIcon((prev) => ({
      ...prev,
      loadCDDdataRemove: false,
      showDeleteIcon: false,
    }));
    handleClearRows();
  };
  const handleClearRows = () => {
    setToggleClearRows(!toggledClearRows);
  };
  const toggleToast = () => {
    settoast(!toast);
  };
  const handleSelect = (optionSelected) => {
    setSelectedValue(optionSelected);
  };
  const handleSearchInput = async (event) => {
    const val = event.target.value;
    setInput(event.target.value);
    if (val.length === 0) {
      const response = await getContrats(data, perPage, "", false);
      setContracts(response.documents);
      setTotalRowsCustom(response.total);
    }
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      if (input.length > 0) {
        handleSearch(input);
      }
    }
  };
  const handleClick = () => {
    if (input.length > 0) {
      handleSearch(input);
    }
  };
  const memoize = (fn) => {
    const cache = new Map();
    return (...args) => {
      const key = args.join("-");
      if (cache.has(key)) return cache.get(key);
      const result = fn(...args);
      cache.set(key, result);
      // you can add a console.log(cache) here to see how cache is being filled.
      return result;
    };
  };
  const userInfo = async (userId) => {
    try {
      const userList = await searchUsers(userId);
      return userList.users[0];
    } catch (error) {
      console.error("Erreur lors du comptage des clients", error);
      return [];
    }
  };
  const fecthUser = memoize(async (contrats) => {
    const promises = contrats.map(async (contrat) => {
      const user = await userInfo(contrat.user_id);
      return user
        ? {
            id: user.$id,
            email: user.email,
            name: `${
              user.prefs && user.prefs.first_name && user.prefs.last_name
                ? user.prefs.first_name + " " + user.prefs.last_name
                : "--"
            } `,
          }
        : null;
    });
    // name is not attribute to client
    const usersList = await Promise.all(promises);
    const filteredUsersList = usersList.filter((item) => item !== null);
    setUsers(filteredUsersList);
  });

  const renderContratsList = (contratsList) => {
    if (loadingIcon.error) {
      return (
        <div style={{ display: "grid", placeItems: "center", height: "50vh" }}>
          Échec de chargement
        </div>
      );
    }
    if (contratsList) {
      return (
        <div className="card">
          <div className="card-body">
            <div className="d-flex justify-content-between">
              <h4 className="card-title mb-4">{data}</h4>
              
        <div className="d-flex flex-wrap gap-3">
          <span className="" style={{color:"#74788d",fontWeight:500,fontSize:14,paddingTop:"5px"}}>Recherche par</span>
          <div className="app-search d-flex flex-wrap gap-2">
                
                <div className="position-relative bottom-50"  style={{
                    borderRadius: "5px",
                    backgroundColor: "#f1f5f7",
                    border: "none",
                    padding: "0.5rem",
                  }} >
                  
                  <select
                    style={{
                      borderRadius: "5px",
                      backgroundColor: "#f1f5f7",
                      border: "none",
                      
                    }} 
                    value={selectedValue}
                    onChange={(e) => handleSelect(e.target.value)}
                  >
                    
                    {handleColumn(
                      contratsList.length && contratsList.at(0).company_id
                        ? contratsList.length && contratsList.at(0)
                          ? contratsList.at(0).contrat_type
                          : ""
                        : "Politique de gestion des cookies"
                    ).searchBy.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </div>
                <div className=" position-relative bottom-50">
                  <input
                    type="text"
                    className="form-control"
                    value={input}
                    placeholder="Recherche..."
                    onChange={handleSearchInput}
                    onKeyDown={handleKeyDown}
                  />
                  <span
                    className="ri-search-line"
                    style={{ cursor: "pointer" }}
                    onClick={handleClick}
                  ></span>
                </div>
              </div>
        </div>
              
            </div>
            <div className="d-flex justify-content-end">
              {loadingIcon.showDeleteIcon && !loadingIcon.loadCDDdataRemove && (
                <i
                  className="fas fa-trash-alt font-size-24"
                  style={{ cursor: "pointer", color: "#fd3b5e" }}
                  onClick={removeMultipleContract}
                ></i>
              )}
              {loadingIcon.loadCDDdataRemove && (
                <Spinner color="primary" size="md">
                  Loading...
                </Spinner>
              )}
            </div>
            <div className="table-responsive">
              <DataTable
                columns={
                  handleColumn(
                    contratsList.length && contratsList.at(0).company_id
                      ? contratsList.length && contratsList.at(0)
                        ? contratsList.at(0).contrat_type
                        : ""
                      : "Politique de gestion des cookies"
                  ).col
                }
                highlightOnHover={true}
                pointerOnHover={true}
                onRowClicked={(e) => handleEditClick(e.$id)}
                data={contratsList}
                selectableRows
                onSelectedRowsChange={handleSelectElement}
                pagination
                paginationServer
                progressPending={loadingIcon.loadCDDDdata}
                progressComponent={Icon.watch}
                paginationTotalRows={totalRowsCustom}
                noDataComponent="Il n'y a aucun enregistrement à afficher pour le moment"
                clearSelectedRows={toggledClearRows}
                onChangePage={handlePageChange}
                paginationComponentOptions={{
                  noRowsPerPage: true,
                  rangeSeparatorText: "sur",
                  rowsPerPageText: "Nbre par page",
                }}
              />
            </div>
          </div>
        </div>
      );
    }
  };

  return (
    <div className="page-content">
      <div>
        <div>
          <div>
            <h2>Contrats</h2>
            <div>{renderContratsList(contracts)}</div>
            <div
              className="position-fixed bottom-0 end-0 p-3"
              style={{ zIndex: "1005" }}
            >
              <Toast isOpen={toast}>
                <ToastHeader toggle={toggleToast}>
                  <img src={logoLegi} alt="" className="me-2" height="18" />
                </ToastHeader>
                <ToastBody color="primary">
                  {`${deleteCount.countElement} supprimé${
                    deleteCount.countElement > 1 ? "s" : ""
                  } sur ${deleteCount.totalElement}`}
                </ToastBody>
              </Toast>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contrat;
