import React, { Component } from 'react';
import Contrat from "../Contrat"; 
 
const ModelRPE  = () => {
    return (
      <div>
         <Contrat data = "Rupture Période d'Essai"/>
      </div>
    );
  };

 
 
export default ModelRPE;
