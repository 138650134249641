import React, { Component } from 'react';
import Contrat from "../Contrat"; 
 
const NoteInfo  = () => {
    return (
      <div>
         <Contrat data = "Note d'information à la destination des salariés"/>
      </div>
    );
  };

 
 
export default NoteInfo;


 