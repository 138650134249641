 
import React from 'react';
import Contrat from "../Contrat"; 
 
const ProcedureExerciceDroit  = () => {
    return (
      <div>
         <Contrat data = "Information et procédure d'exercice des droits des personnes concernées"/>
      </div>
    );
  };
 
export default ProcedureExerciceDroit;

 
 