import React, { useState } from "react";
import UpdateCompany from "../../../common-component/update-service";
import StaticData from "../../../Utility/hooks/static";

const DdComponent = (props) => {
  const { data, parentId, allData, onHandleDdComponentData } = props;
  const [isHovered, setIsHovered] = useState({
    hoverState1: false,
    hoverState2: false,
    hoverState3: false,
    hoverState4: false,
    hoverState5: false,
    hoverState6: false,
    hoverState7: false,
  });
  const handleChangeData = (result, parentKey) => {
    const getResult = typeof result === "string" ? result.trim() : result;
    if (parentKey) data[parentKey] = getResult;
    allData[parentId] = JSON.stringify(data);
    const {
      $collectionId,
      $createdAt,
      $databaseId,
      $permissions,
      $updatedAt,
      $id,
      ...rest
    } = allData;
    console.log({ rest });

    onHandleDdComponentData($id, rest);
  };
  return (
    <dd className="col-sm-6">
      <ul className="verti-timeline list-unstyled">
        <li className="event-list">
          <div>
            <span
              style={{
                fontSize: "18px",
                margin: "0 0 7px 0",
                textDecoration: "underline",
                fontWeight: "normal",
              }}
            >
              Civilité
            </span>

            <span
              onMouseEnter={() =>
                setIsHovered((prev) => ({ ...prev, hoverState1: true }))
              }
              onMouseLeave={() =>
                setIsHovered((prev) => ({ ...prev, hoverState1: false }))
              }
              className="verti-timeline list-unstyled"
              style={{ border: "none" }}
            >
              <div
                style={{
                  fontSize: "14px",
                  fontWeight: "normal",
                }}
                className="event-list"
              >
                <div
                  style={{
                    backgroundColor: "#e6e2e2a1",
                    padding: "16px",
                    borderRadius: "12px",
                    marginTop: "5px",
                    justifyContent: "space-between",
                  }}
                  className="d-flex"
                >
                  <span>{data.civility ? "M." : "Mme"}</span>
                  {isHovered.hoverState1 && (
                    <UpdateCompany
                      title="Civilité"
                      InputValue={data.civility}
                      parentKey="civility"
                      OnUpdateValue={handleChangeData}
                      typeInput="radio"
                      typeValue={StaticData.Radio.Civility}
                    />
                  )}
                </div>
              </div>
            </span>
          </div>
        </li>
        <li className="event-list">
          <div>
            <span
              style={{
                fontSize: "18px",
                margin: "0 0 7px 0",
                textDecoration: "underline",
                fontWeight: "normal",
              }}
            >
              Nom
            </span>
            <span
              className="verti-timeline list-unstyled"
              style={{ border: "none" }}
              onMouseEnter={() =>
                setIsHovered((prev) => ({ ...prev, hoverState2: true }))
              }
              onMouseLeave={() =>
                setIsHovered((prev) => ({ ...prev, hoverState2: false }))
              }
            >
              <div
                style={{
                  fontSize: "14px",
                  fontWeight: "normal",
                }}
                className="event-list"
              >
                <div
                  style={{
                    backgroundColor: "#e6e2e2a1",
                    padding: "16px",
                    borderRadius: "12px",
                    marginTop: "5px",
                    justifyContent: "space-between",
                  }}
                  className="d-flex"
                >
                  <span>{data.name}</span>
                  {isHovered.hoverState2 && (
                    <UpdateCompany
                      typeInput="default"
                      title="Nom"
                      InputValue={data.name}
                      parentKey="name"
                      OnUpdateValue={handleChangeData}
                    />
                  )}
                </div>
              </div>
            </span>
          </div>
        </li>

        <li className="event-list">
          <div>
            <span
              style={{
                fontSize: "18px",
                margin: "0 0 7px 0",
                textDecoration: "underline",
                fontWeight: "normal",
              }}
            >
              Prénom
            </span>
            <span
              className="verti-timeline list-unstyled"
              style={{ border: "none" }}
              onMouseEnter={() =>
                setIsHovered((prev) => ({ ...prev, hoverState3: true }))
              }
              onMouseLeave={() =>
                setIsHovered((prev) => ({ ...prev, hoverState3: false }))
              }
            >
              <div
                style={{
                  fontSize: "14px",
                  fontWeight: "normal",
                }}
                className="event-list"
              >
                <div
                  style={{
                    backgroundColor: "#e6e2e2a1",
                    padding: "16px",
                    borderRadius: "12px",
                    marginTop: "5px",
                    justifyContent: "space-between",
                  }}
                  className="d-flex"
                >
                  <span style={{ fontSize: "14px" }}>{data.surname}</span>
                  {isHovered.hoverState3 && (
                    <UpdateCompany
                      typeInput="default"
                      title="Prénom"
                      InputValue={data.surname}
                      parentKey="surname"
                      OnUpdateValue={handleChangeData}
                    />
                  )}
                </div>
              </div>
            </span>
          </div>
        </li>
        {!data.civility && (
          <li className="event-list">
            <div>
              <span
                style={{
                  fontSize: "18px",
                  margin: "0 0 7px 0",
                  textDecoration: "underline",
                  fontWeight: "normal",
                }}
              >
                Nom d'usage
              </span>
              <span
                className="verti-timeline list-unstyled"
                style={{ border: "none" }}
                onMouseEnter={() =>
                  setIsHovered((prev) => ({ ...prev, hoverState4: true }))
                }
                onMouseLeave={() =>
                  setIsHovered((prev) => ({ ...prev, hoverState4: false }))
                }
              >
                <div
                  style={{
                    fontSize: "14px",
                    fontWeight: "normal",
                  }}
                  className="event-list"
                >
                  <div
                    style={{
                      backgroundColor: "#e6e2e2a1",
                      padding: "16px",
                      borderRadius: "12px",
                      marginTop: "5px",
                      justifyContent: "space-between",
                    }}
                    className="d-flex"
                  >
                    <span>{data.username}</span>
                    {isHovered.hoverState4 && (
                      <UpdateCompany
                        typeInput="default"
                        title="Nom d'usage"
                        InputValue={data.username}
                        parentKey="username"
                        OnUpdateValue={handleChangeData}
                      />
                    )}
                  </div>
                </div>
              </span>
            </div>
          </li>
        )}

        <li className="event-list">
          <div>
            <span
              style={{
                fontSize: "18px",
                margin: "0 0 7px 0",
                textDecoration: "underline",
                fontWeight: "normal",
              }}
            >
              Nom et prénom du père
            </span>
            <span
              className="verti-timeline list-unstyled"
              style={{ border: "none" }}
              onMouseEnter={() =>
                setIsHovered((prev) => ({ ...prev, hoverState5: true }))
              }
              onMouseLeave={() =>
                setIsHovered((prev) => ({ ...prev, hoverState5: false }))
              }
            >
              <div
                style={{
                  fontSize: "14px",
                  fontWeight: "normal",
                }}
                className="event-list"
              >
                <div
                  style={{
                    backgroundColor: "#e6e2e2a1",
                    padding: "16px",
                    borderRadius: "12px",
                    marginTop: "5px",
                    justifyContent: "space-between",
                  }}
                  className="d-flex"
                >
                  <span>{data.fatherName}</span>
                  {isHovered.hoverState5 && (
                    <UpdateCompany
                      typeInput="default"
                      title="Nom et prénom du père"
                      InputValue={data.fatherName}
                      parentKey="fatherName"
                      OnUpdateValue={handleChangeData}
                    />
                  )}
                </div>
              </div>
            </span>
          </div>
        </li>

        <li className="event-list">
          <div>
            <span
              style={{
                fontSize: "18px",
                margin: "0 0 7px 0",
                textDecoration: "underline",
                fontWeight: "normal",
              }}
            >
              Nom et prénom de la mère
            </span>
            <span
              className="verti-timeline list-unstyled"
              style={{ border: "none" }}
              onMouseEnter={() =>
                setIsHovered((prev) => ({ ...prev, hoverState6: true }))
              }
              onMouseLeave={() =>
                setIsHovered((prev) => ({ ...prev, hoverState6: false }))
              }
            >
              <div
                style={{
                  fontSize: "14px",
                  fontWeight: "normal",
                }}
                className="event-list"
              >
                <div
                  style={{
                    backgroundColor: "#e6e2e2a1",
                    padding: "16px",
                    borderRadius: "12px",
                    marginTop: "5px",
                    justifyContent: "space-between",
                  }}
                  className="d-flex"
                >
                  <span>{data.motherName}</span>
                  {isHovered.hoverState6 && (
                    <UpdateCompany
                      typeInput="default"
                      title="Nom et prénom de la mère"
                      InputValue={data.motherName}
                      parentKey="motherName"
                      OnUpdateValue={handleChangeData}
                    />
                  )}
                </div>
              </div>
            </span>
          </div>
        </li>

        <li className="event-list">
          <div>
            <span
              style={{
                fontSize: "18px",
                margin: "0 0 7px 0",
                textDecoration: "underline",
                fontWeight: "normal",
              }}
            >
              Code Zip
            </span>
            <span
              className="verti-timeline list-unstyled"
              style={{ border: "none" }}
              onMouseEnter={() => setIsHovered(prev => ({...prev,hoverState7:true}))}
            onMouseLeave={() => setIsHovered(prev => ({...prev,hoverState7:false}))}
            >
              <div
                style={{
                  fontSize: "14px",
                  fontWeight: "normal",
                  
                }}
                className="event-list"
              >
                <div
                  style={{
                    backgroundColor: "#e6e2e2a1",
                    padding: "16px",
                    borderRadius: "12px",
                    marginTop: "5px",
                    justifyContent: "space-between",
                  }}
                  className="d-flex"
                >
                  <span>{data.zipCode}</span>
                  {isHovered.hoverState7 && (<UpdateCompany
                    typeInput="default"
                    title="Code Zip"
                    InputValue={data.zipCode}
                    parentKey="zipCode"
                    keyElement="director_zipcode"
                    OnUpdateValue={handleChangeData}
                  />)}
                  
                </div>
              </div>
            </span>
          </div>
        </li>
      </ul>
    </dd>
  );
};

export default DdComponent;
