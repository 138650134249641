  

let api = {
    sdk: null,
    listDocuments: (databaseId, collectionId) => {
        return api.provider().database.listDocuments(databaseId, collectionId);
      },
}   

export default api;

/*const client = new Client()
    .setEndpoint('https://api.shopybase.com/v1') // Your API Endpoint
    .setProject('legihub-dev');               // Your project ID

const account = new Account(client);
 

const promise = databases.listDocuments('legihub-dev', 'company').then(response => {
    console.log(response);
}, error => {
    console.log(error);
});*/
