import React, { useState } from "react";
import { Row, Card, CardBody } from "reactstrap";
import UpdateCompany from "../../common-component/update-service";
import StaticData from "../../Utility/hooks/static";
const CompanyState = (props) => {
  const { title, companyStateData, onUpdateCompanyState } = props;
  const [isHovered, setIsHovered] = useState(false);
  const handleChangeData = (result, parentKey, keyElement) => {
    const getResult = typeof result === "string" ? result.trim() : result;
    if (keyElement) companyStateData[parentKey][keyElement] = getResult;
    else companyStateData[parentKey] = getResult;
    const {
      $collectionId,
      $createdAt,
      $databaseId,
      $permissions,
      $updatedAt,
      $id,
      ...rest
    } = companyStateData;
    console.log({ rest });
    onUpdateCompanyState($id, rest);
  };
  return (
    <>
      <Row>
        <Card>
          <CardBody>
            <h4 className="card-title mb-5 text-primary">{title}</h4>

            <ul className="verti-timeline list-unstyled">
              <li className="event-list">
                <div>
                  <span
                    style={{
                      fontSize: "15px",
                      margin: "0 0 7px 0",
                      textDecoration: "underline",
                      fontWeight: "normal",
                    }}
                  >
                    Etat de la demande
                  </span>
                  <span
                    className="verti-timeline list-unstyled"
                    style={{ border: "none" }}
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                  >
                    <div
                      style={{
                        fontSize: "12px",
                        fontWeight: "normal",
                        width: "45%",
                      }}
                      className="event-list"
                    >
                      <div
                        style={{
                          backgroundColor: "#e6e2e2a1",
                          padding: "16px",
                          borderRadius: "12px",
                          marginTop: "5px",
                          justifyContent: "space-between",
                        }}
                        className="d-flex"
                      >
                        <span style={{ fontSize: "14px" }}>
                          {companyStateData.state
                            ? StaticData.Radio.CompanyState.find(
                                (el) => el.value === companyStateData.state
                              ).name
                            : StaticData.Radio.CompanyState[0].name}
                        </span>
                        {isHovered && (
                          <UpdateCompany
                            typeInput="radio"
                            title="Etat de la demande"
                            InputValue={
                              companyStateData.state
                                ? companyStateData.state
                                : StaticData.Radio.CompanyState[0].value
                            }
                            parentKey="state"
                            OnUpdateValue={handleChangeData}
                            typeValue={StaticData.Radio.CompanyState}
                          />
                        )}
                      </div>
                    </div>
                  </span>
                </div>
              </li>
            </ul>
          </CardBody>
        </Card>
      </Row>
    </>
  );
};

export default CompanyState;
