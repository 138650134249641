import React from 'react'
import DDComponent from './ddComponent'
import DTComponent from './dtComponent'
import {
  Row,
  Card,
  CardBody,
} from "reactstrap";

const Index  = (props) => {
  const {title,presentationCompanyData,onUpdatePresentationCompany} = props
  return (
    <Row>
    <Card>
      <CardBody>
        <h4 className="card-title mb-5 text-primary">
          {title}
        </h4>
        <dl className="row mb-0">
         <DTComponent data = {presentationCompanyData} onHandleDtComponentData = {onUpdatePresentationCompany}/>
         <DDComponent data = {presentationCompanyData} onHandleDdComponentData = {onUpdatePresentationCompany}/>
        </dl>
      </CardBody>
    </Card>
  </Row>
  )
}

export default Index