import React, { useState } from 'react';

const CheckBoxSwitch = (props) => {
  const { checkBoxValues, defaultValue, onCheckBoxChange } = props;
  const [selectedCheckboxes, setSelectedCheckboxes] = useState(defaultValue);
  const [selectAll, setSelectAll] = useState(false);

  const handleCheckboxChange = (checkboxValue) => { 
    const updatedCheckboxes = selectedCheckboxes.includes(checkboxValue)
      ? selectedCheckboxes.filter((value) => value !== checkboxValue)
      : [...selectedCheckboxes, checkboxValue];

    setSelectedCheckboxes(updatedCheckboxes);
    setSelectAll(false);
    onCheckBoxChange(updatedCheckboxes); 
  };

  const handleSelectAllChange = () => {
    setSelectAll(!selectAll);
    const allValues = !selectAll ? checkBoxValues : [];
    setSelectedCheckboxes(allValues);
    onCheckBoxChange(allValues); 
  };

  return (
    <div>
      <div className='d-flex' style={{ flexDirection: 'column' }}>
        <div>
          <input
            type="checkbox"
            id="selectAllCheckbox"
            className='form-check-input'
            checked={selectAll}
            onChange={handleSelectAllChange}
          />
          <label htmlFor="selectAllCheckbox" style={{ marginLeft: '0.5rem' }}>
            Tout Sélectionner 
          </label>
        </div>
        {checkBoxValues.map((element, index) => (
          <div key={element}>
            <input
              type="checkbox"
              id={`checkbox${index + 1}`}
              className='form-check-input'
              name={`checkbox${index + 1}`}
              checked={selectedCheckboxes.includes(element)}
              onChange={() => handleCheckboxChange(element)}
            />
            <label htmlFor={`checkbox${index + 1}`} style={{ marginLeft: '0.5rem' }}>
              {element}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CheckBoxSwitch;
