 
import React, { Component } from 'react';
import Contrat from "../Contrat"; 
 
const PolitiqueConfidential  = () => {
    return (
      <div>
         <Contrat data = "Politique de confidentialité"/>
      </div>
    );
  };
 
export default PolitiqueConfidential;

 