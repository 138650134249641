import React from "react";
import { Row, Card, CardBody } from "reactstrap";
import DtComponent from "./common-component/dtComponent";
import DdComponent from "./common-component/ddComponent";
const Partner = (props) => {
  const { title, partnerData,onUpdatePatner } = props;
  const result =
  partnerData.associe !== null
    ? JSON.parse(partnerData.associe)
    : null;
  return (
    <>
      {  result && Object.keys(result).length && (
        <Row>
          <Card>
            <CardBody>
              <h4 className="card-title mb-5 text-primary">{title}</h4>
              <dl className="row mb-0">
                <DdComponent data={result} allData = {partnerData}  parentId="associe" onHandleDdComponentData={onUpdatePatner}  />
                <DtComponent data={result} allData = {partnerData}  parentId="associe" onHandleDtComponentData={onUpdatePatner} />
              </dl>
            </CardBody>
          </Card>
        </Row>
      )}
    </>
  );
};

export default Partner;
