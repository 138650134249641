import React from "react";
import UpdateContrat from "../../../common-component/update-service";

const DtComponent = (props) => {
  const { data, onHandleDtComponentData } = props;
  const handleChangeData = async (result, parentKey, keyElement) => {
    const getResult = typeof result === "string" ? result.trim() : result;
    if (keyElement) data[parentKey][keyElement] = getResult;
    else data[parentKey] = getResult;
    const {
      $collectionId,
      $createdAt,
      $databaseId,
      $permissions,
      $updatedAt,
      $id,
      ...rest
    } = data;
    onHandleDtComponentData($id, rest);
  };
  return (
    <>
      <dt className="col-sm-6">
        <ul className="verti-timeline list-unstyled">
          {data.contrat_type !== "Promesse d'embauche" &&
            data.contrat_type !== "Renouvellement Période d'Essai" &&
            data.contrat_type !== "Rupture Période d'Essai" && (
              <li className="event-list">
                <div>
                  <span
                    style={{
                      fontSize: "18px",
                      margin: "0 0 7px 0",
                      textDecoration: "underline",
                      fontWeight: "normal",
                    }}
                  >
                    Matériel mise à la disposition du salarié
                  </span>
                  <span
                    className="verti-timeline list-unstyled"
                    style={{ border: "none" }}
                  >
                    <div
                      style={{
                        fontSize: "14px",
                        fontWeight: "normal",
                      }}
                      className="event-list"
                    >
                      <div
                        style={{
                          backgroundColor: "#e6e2e2a1",
                          padding: "16px",
                          borderRadius: "12px",
                          marginTop: "5px",
                          justifyContent: "space-between",
                        }}
                        className="d-flex"
                      >
                        <span>
                          {data.employee_id === null ||
                          data.employee_id === undefined
                            ? null
                            : data.employee_id.employee_equipment}
                        </span>
                        <UpdateContrat
                          typeInput="default"
                          title="Matériel mise à la disposition du salarié"
                          InputValue={data.employee_id.employee_equipment}
                          parentKey="employee_id"
                          keyElement="employee_equipment"
                          OnUpdateValue={handleChangeData}
                        />
                      </div>
                    </div>
                  </span>
                </div>
              </li>
            )}
          {data.contrat_type !== "Renouvellement Période d'Essai" &&
            data.contrat_type !== "Rupture Période d'Essai" &&
            data.contrat_type !== "CDI" && (
              <>
                {data.employee_id.employee_working_time === "" && (
                  <li className="event-list">
                    <div>
                      <span
                        style={{
                          fontSize: "18px",
                          margin: "0 0 7px 0",
                          textDecoration: "underline",
                          fontWeight: "normal",
                        }}
                      >
                        Nombre d'heures hebdomadaire
                      </span>
                      <span
                        className="verti-timeline list-unstyled"
                        style={{ border: "none" }}
                      >
                        <div
                          style={{
                            fontSize: "14px",
                            fontWeight: "normal",
                          }}
                          className="event-list"
                        >
                          <div
                            style={{
                              backgroundColor: "#e6e2e2a1",
                              padding: "16px",
                              borderRadius: "12px",
                              marginTop: "5px",
                              justifyContent: "space-between",
                            }}
                            className="d-flex"
                          >
                            <span>
                              {data.employee_id === null ||
                              data.employee_id === undefined
                                ? null
                                : data.employee_id.week_worktime_hours}
                            </span>
                            <UpdateContrat
                              typeInput="default"
                              title="Nombre d'heures hebdomadaire"
                              InputValue={data.employee_id.week_worktime_hours}
                              parentKey="employee_id"
                              keyElement="week_worktime_hours"
                              OnUpdateValue={handleChangeData}
                            />
                          </div>
                        </div>
                      </span>
                    </div>
                  </li>
                )}

                {data.employee_id.employee_working_time !== "" && (
                  <li className="event-list">
                    <div>
                      <span
                        style={{
                          fontSize: "18px",
                          margin: "0 0 7px 0",
                          textDecoration: "underline",
                          fontWeight: "normal",
                        }}
                      >
                        Nombre d'heures mensuelle
                      </span>
                      <span
                        className="verti-timeline list-unstyled"
                        style={{ border: "none" }}
                      >
                        <div
                          style={{
                            fontSize: "14px",
                            fontWeight: "normal",
                          }}
                          className="event-list"
                        >
                          <div
                            style={{
                              backgroundColor: "#e6e2e2a1",
                              padding: "16px",
                              borderRadius: "12px",
                              marginTop: "5px",
                              justifyContent: "space-between",
                            }}
                            className="d-flex"
                          >
                            <span>
                              {data.employee_id === null ||
                              data.employee_id === undefined
                                ? null
                                : data.employee_id.employee_working_time}
                            </span>
                            <UpdateContrat
                              typeInput="default"
                              title="Nombre d'heures mensuelle"
                              InputValue={
                                data.employee_id.employee_working_time
                              }
                              parentKey="employee_id"
                              keyElement="employee_working_time"
                              OnUpdateValue={handleChangeData}
                            />
                          </div>
                        </div>
                      </span>
                    </div>
                  </li>
                )}
              </>
            )}

          {/* gestion d'affichage d'heure mensuelle et hebdo */}

          {data.contrat_type !== "Promesse d'embauche" &&
            data.employee_id.employee_trial_period !== "" && (
              <li className="event-list">
                <div>
                  <span
                    style={{
                      fontSize: "18px",
                      margin: "0 0 7px 0",
                      textDecoration: "underline",
                      fontWeight: "normal",
                    }}
                  >
                    Période d'essai
                  </span>
                  <span
                    className="verti-timeline list-unstyled"
                    style={{ border: "none" }}
                  >
                    <div
                      style={{
                        fontSize: "14px",
                        fontWeight: "normal",
                      }}
                      className="event-list"
                    >
                      <div
                        style={{
                          backgroundColor: "#e6e2e2a1",
                          padding: "16px",
                          borderRadius: "12px",
                          marginTop: "5px",
                          justifyContent: "space-between",
                        }}
                        className="d-flex"
                      >
                        <span>
                          {data.employee_id === null ||
                          data.employee_id === undefined
                            ? null
                            : data.employee_id.employee_trial_period}
                        </span>
                        <UpdateContrat
                          typeInput="default"
                          title="Période d'essai"
                          InputValue={data.employee_id.employee_trial_period}
                          parentKey="employee_id"
                          keyElement="employee_trial_period"
                          OnUpdateValue={handleChangeData}
                        />
                      </div>
                    </div>
                  </span>
                </div>
              </li>
            )}

          {data.contrat_type !== "CDD" &&
            data.contrat_type !== "Promesse d'embauche" &&
            data.employee_id.employee_trial_renewal !== "" && (
              <li className="event-list">
                <div>
                  <span
                    style={{
                      fontSize: "18px",
                      margin: "0 0 7px 0",
                      textDecoration: "underline",
                      fontWeight: "normal",
                    }}
                  >
                    Renouvellement période d'essai
                  </span>
                  <span
                    className="verti-timeline list-unstyled"
                    style={{ border: "none" }}
                  >
                    <div
                      style={{
                        fontSize: "14px",
                        fontWeight: "normal",
                      }}
                      className="event-list"
                    >
                      <div
                        style={{
                          backgroundColor: "#e6e2e2a1",
                          padding: "16px",
                          borderRadius: "12px",
                          marginTop: "5px",
                          justifyContent: "space-between",
                        }}
                        className="d-flex"
                      >
                        <sapn>
                          {data.employee_id === null ||
                          data.employee_id === undefined
                            ? null
                            : data.employee_id.employee_trial_renewal}
                        </sapn>
                        <UpdateContrat
                          title="Renouvellement période d'essai"
                          InputValue={data.employee_id.employee_trial_renewal}
                          parentKey="employee_id"
                          keyElement="employee_trial_renewal"
                          OnUpdateValue={handleChangeData}
                          typeInput="default"
                        />
                      </div>
                    </div>
                  </span>
                </div>
              </li>
            )}

          {data.contrat_type !== "CDD" &&
            data.contrat_type !== "Promesse d'embauche" &&
            data.contrat_type !== "Renouvellement Période d'Essai" &&
            data.contrat_type !== "Rupture Période d'Essai" && (
              <>
                <li className="event-list">
                  <div>
                    <span
                      style={{
                        fontSize: "18px",
                        margin: "0 0 7px 0",
                        textDecoration: "underline",
                        fontWeight: "normal",
                      }}
                    >
                      Modalités de calcul de la rémunération variable
                    </span>
                    <span
                      className="verti-timeline list-unstyled"
                      style={{ border: "none" }}
                    >
                      <div
                        style={{
                          fontSize: "14px",
                          fontWeight: "normal",
                        }}
                        className="event-list"
                      >
                        <div
                          style={{
                            backgroundColor: "#e6e2e2a1",
                            padding: "16px",
                            borderRadius: "12px",
                            marginTop: "5px",
                            justifyContent: "space-between",
                          }}
                          className="d-flex"
                        >
                          <span>
                            {data.employee_id === null ||
                            data.employee_id === undefined
                              ? null
                              : data.employee_id.employee_pay_calcul_methods}
                          </span>
                          <UpdateContrat
                            title="Modalités de calcul de la rémunération variable"
                            InputValue={
                              data.employee_id.employee_pay_calcul_methods
                            }
                            parentKey="employee_id"
                            keyElement="employee_pay_calcul_methods"
                            OnUpdateValue={handleChangeData}
                            typeInput="default"
                          />
                        </div>
                      </div>
                    </span>
                  </div>
                </li>

                <li className="event-list">
                  <div>
                    <span
                      style={{
                        fontSize: "18px",
                        margin: "0 0 7px 0",
                        textDecoration: "underline",
                        fontWeight: "normal",
                      }}
                    >
                      Montant fixe ou en pourcentage du salaire
                    </span>
                    <span
                      className="verti-timeline list-unstyled"
                      style={{ border: "none" }}
                    >
                      <div
                        style={{
                          fontSize: "14px",
                          fontWeight: "normal",
                        }}
                        className="event-list"
                      >
                        <div
                          style={{
                            backgroundColor: "#e6e2e2a1",
                            padding: "16px",
                            borderRadius: "12px",
                            marginTop: "5px",
                            justifyContent: "space-between",
                          }}
                          className="d-flex"
                        >
                          <span>
                            {data.employee_id === null ||
                            data.employee_id === undefined
                              ? null
                              : data.employee_id.employee_added_pay_details}
                          </span>
                          <UpdateContrat
                            title="Montant fixe ou en pourcentage du salaire"
                            InputValue={
                              data.employee_id.employee_added_pay_details
                            }
                            parentKey="employee_id"
                            keyElement="employee_added_pay_details"
                            OnUpdateValue={handleChangeData}
                            typeInput="default"
                          />
                        </div>
                      </div>
                    </span>
                  </div>
                </li>
                <li className="event-list">
                  <div>
                    <span
                      style={{
                        fontSize: "18px",
                        margin: "0 0 7px 0",
                        textDecoration: "underline",
                        fontWeight: "normal",
                      }}
                    >
                      Période de versement du salaire
                    </span>
                    <span
                      className="verti-timeline list-unstyled"
                      style={{ border: "none" }}
                    >
                      <div
                        style={{
                          fontSize: "14px",
                          fontWeight: "normal",
                        }}
                        className="event-list"
                      >
                        <div
                          style={{
                            backgroundColor: "#e6e2e2a1",
                            padding: "16px",
                            borderRadius: "12px",
                            marginTop: "5px",
                            justifyContent: "space-between",
                          }}
                          className="d-flex"
                        >
                          <span>
                            {data.employee_id === null ||
                            data.employee_id === undefined
                              ? null
                              : data.employee_id.employee_added_pay_period}
                          </span>
                          <UpdateContrat
                            title="Période de versement du salaire"
                            InputValue={
                              data.employee_id.employee_added_pay_period
                            }
                            parentKey="employee_id"
                            keyElement="employee_added_pay_period"
                            OnUpdateValue={handleChangeData}
                            typeInput="default"
                          />
                        </div>
                      </div>
                    </span>
                  </div>
                </li>
                {data.contrat_type === 'CDI' && (
                  <li className="event-list">
                  <div>
                    <span
                      style={{
                        fontSize: "18px",
                        margin: "0 0 7px 0",
                        textDecoration: "underline",
                        fontWeight: "normal",
                      }}
                    >
                      Durée de préavis
                    </span>
                    <span
                      className="verti-timeline list-unstyled"
                      style={{ border: "none" }}
                    >
                      <div
                        style={{
                          fontSize: "14px",
                          fontWeight: "normal",
                        }}
                        className="event-list"
                      >
                        <div
                          style={{
                            backgroundColor: "#e6e2e2a1",
                            padding: "16px",
                            borderRadius: "12px",
                            marginTop: "5px",
                            justifyContent: "space-between",
                          }}
                          className="d-flex"
                        >
                          <span>
                            {data.employee_id === null ||
                            data.employee_id === undefined
                              ? null
                              : data.employee_id.employee_notice_duration}
                          </span>
                          <UpdateContrat
                            title="Durée de préavis"
                            InputValue={
                              data.employee_id.employee_notice_duration
                            }
                            parentKey="employee_id"
                            keyElement="employee_notice_duration"
                            OnUpdateValue={handleChangeData}
                            typeInput="default"
                          />
                        </div>
                      </div>
                    </span>
                  </div>
                </li>
                )}
              </>
            )}

          {data.employee_id.employee_premiums && (
            <li className="event-list">
              <div>
                <span
                  style={{
                    fontSize: "18px",
                    margin: "0 0 7px 0",
                    textDecoration: "underline",
                    fontWeight: "normal",
                  }}
                >
                  Primes
                </span>
                <span
                  className="verti-timeline list-unstyled"
                  style={{ border: "none" }}
                >
                  <div
                    style={{
                      fontSize: "14px",
                      fontWeight: "normal",
                    }}
                    className="event-list"
                  >
                    <div
                      style={{
                        backgroundColor: "#e6e2e2a1",
                        padding: "16px",
                        borderRadius: "12px",
                        marginTop: "5px",
                        justifyContent: "space-between",
                      }}
                      className="d-flex"
                    >
                      <span>{data.employee_id.employee_premiums}</span>
                      <UpdateContrat
                        title="Primes"
                        InputValue={data.employee_id.employee_premiums}
                        parentKey="employee_id"
                        keyElement="employee_premiums"
                        OnUpdateValue={handleChangeData}
                        typeInput="default"
                      />
                    </div>
                  </div>
                </span>
              </div>
            </li>
          )}
        </ul>
      </dt>
    </>
  );
};

export default DtComponent;
