import React from "react";
import UpdateContrat from "../../../common-component/update-service";

const DtComponent = (props) => {
  const { data, onHandleDtComponentData } = props;

  const handleChangeData = (result, parentKey, keyElement) => {
    const getResult = typeof result === "string" ? result.trim() : result;
    if (keyElement) data[parentKey][keyElement] = getResult;
    else data[parentKey] = getResult;
    const {
      $collectionId,
      $createdAt,
      $databaseId,
      $permissions,
      $updatedAt,
      $id,
      ...rest
    } = data;
    onHandleDtComponentData($id, rest);
  };
  return (
    <>
      <dt className="col-sm-6">
        <ul className="verti-timeline list-unstyled">
          <li className="event-list">
            <div>
              <span
                style={{
                  fontSize: "18px",
                  margin: "0 0 7px 0",
                  textDecoration: "underline",
                  fontWeight: "normal",
                }}
              >
                Zones géographiques concernées par la clause
              </span>
              <span
                className="verti-timeline list-unstyled"
                style={{ border: "none" }}
              >
                <div
                  style={{
                    fontSize: "14px",
                    fontWeight: "normal",
                  }}
                  className="event-list"
                >
                  <div
                    style={{
                      backgroundColor: "#e6e2e2a1",
                      padding: "16px",
                      borderRadius: "12px",
                      marginTop: "5px",
                      justifyContent: "space-between",
                    }}
                    className="d-flex"
                  >
                    <span>
                      {data.company_id === null || data.company_id === undefined
                        ? null
                        : data.clause_geographical_area}
                    </span>
                    <UpdateContrat
                      title="Zones géographiques concernées par la clause"
                      InputValue={data.clause_geographical_area}
                      typeInput="default"
                      parentKey="clause_geographical_area"
                      OnUpdateValue={handleChangeData}
                    />
                  </div>
                </div>
              </span>
            </div>
          </li>
          <li className="event-list">
            <div>
              <span
                style={{
                  fontSize: "18px",
                  margin: "0 0 7px 0",
                  textDecoration: "underline",
                  fontWeight: "normal",
                }}
              >
                Activités concernées par la clause
              </span>
              <span
                className="verti-timeline list-unstyled"
                style={{ border: "none" }}
              >
                <div
                  style={{
                    fontSize: "14px",
                    fontWeight: "normal",
                  }}
                  className="event-list"
                >
                  <div
                    style={{
                      backgroundColor: "#e6e2e2a1",
                      padding: "16px",
                      borderRadius: "12px",
                      marginTop: "5px",
                      justifyContent: "space-between",
                    }}
                    className="d-flex"
                  >
                    <span>
                      {data.company_id === null || data.company_id === undefined
                        ? null
                        : data.clause_activities}
                    </span>
                    <UpdateContrat
                      title="Activités concernées par la clause"
                      InputValue={data.clause_activities}
                      typeInput="default"
                      parentKey="clause_activities"
                      OnUpdateValue={handleChangeData}
                    />
                  </div>
                </div>
              </span>
            </div>
          </li>
          {data.contrat_type !== "CDI" && (
            <li className="event-list">
              <div>
                <span
                  style={{
                    fontSize: "18px",
                    margin: "0 0 7px 0",
                    textDecoration: "underline",
                    fontWeight: "normal",
                  }}
                >
                  Durée d'application de la clause
                </span>
                <span
                  className="verti-timeline list-unstyled"
                  style={{ border: "none" }}
                >
                  <div
                    style={{
                      fontSize: "14px",
                      fontWeight: "normal",
                    }}
                    className="event-list"
                  >
                    <div
                      style={{
                        backgroundColor: "#e6e2e2a1",
                        padding: "16px",
                        borderRadius: "12px",
                        marginTop: "5px",
                        justifyContent: "space-between",
                      }}
                      className="d-flex"
                    >
                      <span>
                        {data.company_id === null ||
                        data.company_id === undefined
                          ? null
                          : data.clause_decision_deadline}
                      </span>
                      <UpdateContrat
                        title="Durée d'application de la clause"
                        InputValue={data.company_id.clause_decision_deadline}
                        typeInput="default"
                        parentKey="company_id"
                        keyElement="clause_decision_deadline"
                        OnUpdateValue={handleChangeData}
                      />
                    </div>
                  </div>
                </span>
              </div>
            </li>
          )}
        </ul>
      </dt>
    </>
  );
};

export default DtComponent;
