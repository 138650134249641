import React from "react";
import convertDate from "../../../Utility/hooks/dateConvert";
import UpdateContrat from "../../../common-component/update-service";
import StaticData from "../../../Utility/hooks/static";

const DtComponent = (props) => {
  const { data, onHandleDtComponentData } = props;

  const handleChangeData = async (result, parentKey, keyElement) => {
    const getResult = typeof result === "string" ? result.trim() : result;
    if (keyElement) data[parentKey][keyElement] = getResult;
    else data[parentKey] = getResult;
    const {
      $collectionId,
      $createdAt,
      $databaseId,
      $permissions,
      $updatedAt,
      $id,
      ...rest
    } = data;
    onHandleDtComponentData($id, rest);
  };
  return (
    <dt className="col-sm-6">
      <ul className="verti-timeline list-unstyled">
        <li className="event-list">
          <div>
            <span
              style={{
                fontSize: "18px",
                margin: "0 0 7px 0",
                textDecoration: "underline",
                fontWeight: "normal",
              }}
            >
              Civilité
            </span>
            <span
              className="verti-timeline list-unstyled"
              style={{ border: "none" }}
            >
              <div
                style={{
                  fontSize: "14px",
                  fontWeight: "normal",
                }}
                className="event-list"
              >
                <div
                  style={{
                    backgroundColor: "#e6e2e2a1",
                    padding: "16px",
                    borderRadius: "12px",
                    marginTop: "5px",
                    justifyContent: "space-between",
                  }}
                  className="d-flex"
                >
                  <span>
                    {data.employee_id === null || data.employee_id === undefined
                      ? null
                      : data.employee_id.employee_gender
                      ? StaticData.Radio.Civility.at(0).name
                      : StaticData.Radio.Civility.at(1).name}
                  </span>
                  <UpdateContrat
                    title="Civilité"
                    InputValue={data.employee_id.employee_gender}
                    parentKey="employee_id"
                    keyElement="employee_gender"
                    OnUpdateValue={handleChangeData}
                    typeInput="radio"
                    typeValue={StaticData.Radio.Civility}
                  />
                </div>
              </div>
            </span>
          </div>
        </li>
        {data.contrat_type !== "Rupture Période d'Essai" &&
          data.contrat_type !== "Renouvellement Période d'Essai" &&
          data.contrat_type !== "Promesse d'embauche" &&
          data.contrat_type !== "CDI" && (
            <li className="event-list">
              <div>
                <span
                  style={{
                    fontSize: "18px",
                    margin: "0 0 7px 0",
                    textDecoration: "underline",
                    fontWeight: "normal",
                  }}
                >
                  Pays
                </span>
                <span
                  className="verti-timeline list-unstyled"
                  style={{ border: "none" }}
                >
                  <div
                    style={{
                      fontSize: "14px",
                      fontWeight: "normal",
                    }}
                    className="event-list"
                  >
                    <div
                      style={{
                        backgroundColor: "#e6e2e2a1",
                        padding: "16px",
                        borderRadius: "12px",
                        marginTop: "5px",
                        justifyContent: "space-between",
                      }}
                      className="d-flex"
                    >
                      <span
                        className={`flag ${data.employee_id.employee_country_code.toLowerCase()}`}
                      ></span>
                      <UpdateContrat
                        typeInput="select"
                        title="Pays"
                        InputValue={data.employee_id.employee_country_code}
                        parentKey="employee_id"
                        keyElement="employee_country_code"
                        OnUpdateValue={handleChangeData}
                      />
                    </div>
                  </div>
                </span>
              </div>
            </li>
          )}
        {data.contrat_type !== "Rupture Période d'Essai" &&
          data.contrat_type !== "Renouvellement Période d'Essai" &&
          data.contrat_type !== "Promesse d'embauche" && (
            <li className="event-list">
              <div>
                <span
                  style={{
                    fontSize: "18px",
                    margin: "0 0 7px 0",
                    textDecoration: "underline",
                    fontWeight: "normal",
                  }}
                >
                  Date de naissance
                </span>
                <span
                  className="verti-timeline list-unstyled"
                  style={{ border: "none" }}
                >
                  <div
                    style={{
                      fontSize: "14px",
                      fontWeight: "normal",
                    }}
                    className="event-list"
                  >
                    <div
                      style={{
                        backgroundColor: "#e6e2e2a1",
                        padding: "16px",
                        borderRadius: "12px",
                        marginTop: "5px",
                        justifyContent: "space-between",
                      }}
                      className="d-flex"
                    >
                      <span>
                        {data.employee_id === null ||
                        data.employee_id === undefined
                          ? null
                          : convertDate.convertMillisecondToDate(
                              data.employee_id.employee_birthday
                            )}
                      </span>
                      <UpdateContrat
                        typeInput="date"
                        title="Date de naissance"
                        InputValue={data.employee_id.employee_birthday}
                        parentKey="employee_id"
                        keyElement="employee_birthday"
                        OnUpdateValue={handleChangeData}
                      />
                    </div>
                  </div>
                </span>
              </div>
            </li>
          )}

        <li className="event-list">
          <div>
            <span
              style={{
                fontSize: "18px",
                margin: "0 0 7px 0",
                textDecoration: "underline",
                fontWeight: "normal",
              }}
            >
              Nom et prénom
            </span>
            <span
              className="verti-timeline list-unstyled"
              style={{ border: "none" }}
            >
              <div
                style={{
                  fontSize: "14px",
                  fontWeight: "normal",
                }}
                className="event-list"
              >
                <div
                  style={{
                    backgroundColor: "#e6e2e2a1",
                    padding: "16px",
                    borderRadius: "12px",
                    marginTop: "5px",
                    justifyContent: "space-between",
                  }}
                  className="d-flex"
                >
                  <span>
                    {data.employee_id === null || data.employee_id === undefined
                      ? null
                      : data.employee_id.employee_full_name}
                  </span>
                  <UpdateContrat
                    typeInput="default"
                    title="Nom et prénom"
                    InputValue={data.employee_id.employee_full_name}
                    parentKey="employee_id"
                    keyElement="employee_full_name"
                    OnUpdateValue={handleChangeData}
                  />
                </div>
              </div>
            </span>
          </div>
        </li>

        {(data.contrat_type === "Renouvellement Période d'Essai" ||
          data.contrat_type === "Rupture Période d'Essai") && (
          <li className="event-list">
            <div>
              <span
                style={{
                  fontSize: "18px",
                  margin: "0 0 7px 0",
                  textDecoration: "underline",
                  fontWeight: "normal",
                }}
              >
                Lettre
              </span>
              <span
                className="verti-timeline list-unstyled"
                style={{ border: "none" }}
              >
                <div
                  style={{
                    fontSize: "14px",
                    fontWeight: "normal",
                  }}
                  className="event-list"
                >
                  <div
                    style={{
                      backgroundColor: "#e6e2e2a1",
                      padding: "16px",
                      borderRadius: "12px",
                      marginTop: "5px",
                      justifyContent: "space-between",
                    }}
                    className="d-flex"
                  >
                    <span>
                      {data.company_id === null || data.company_id === undefined
                        ? null
                        : data.contrat_letter_type
                        ? StaticData.Radio.LetterDelivered.at(0).name
                        : StaticData.Radio.LetterDelivered.at(1).name}
                    </span>
                    <UpdateContrat
                      typeInput="radio"
                      title="Lettre"
                      InputValue={data.contrat_letter_type}
                      parentKey="contrat_letter_type"
                      typeValue={StaticData.Radio.LetterDelivered}
                      OnUpdateValue={handleChangeData}
                    />
                  </div>
                </div>
              </span>
            </div>
          </li>
        )}

        {(data.contrat_type !== "Renouvellement Période d'Essai" || data.contrat_type !== "CDD") && (
          <li className="event-list">
            <div>
              <span
                style={{
                  fontSize: "18px",
                  margin: "0 0 7px 0",
                  textDecoration: "underline",
                  fontWeight: "normal",
                }}
              >
                Qualité professionnelle
              </span>
              <span
                className="verti-timeline list-unstyled"
                style={{ border: "none" }}
              >
                <div
                  style={{
                    fontSize: "14px",
                    fontWeight: "normal",
                  }}
                  className="event-list"
                >
                  <div
                    style={{
                      backgroundColor: "#e6e2e2a1",
                      padding: "16px",
                      borderRadius: "12px",
                      marginTop: "5px",
                      justifyContent: "space-between",
                    }}
                    className="d-flex"
                  >
                    <span>
                      {data.employee_id === null ||
                      data.employee_id === undefined
                        ? null
                        : data.employee_id.employee_quality}
                    </span>
                    <UpdateContrat
                      typeInput="default"
                      title="Qualité professionnelle"
                      InputValue={data.employee_id.employee_quality}
                      parentKey="employee_id"
                      keyElement="employee_quality"
                      OnUpdateValue={handleChangeData}
                    />
                  </div>
                </div>
              </span>
            </div>
          </li>
        )}

        {data.contrat_type !== "Rupture Période d'Essai" &&
          data.contrat_type !== "Renouvellement Période d'Essai" &&
          data.contrat_type !== "Promesse d'embauche" && (
            <li className="event-list">
              <div>
                <span
                  style={{
                    fontSize: "18px",
                    margin: "0 0 7px 0",
                    textDecoration: "underline",
                    fontWeight: "normal",
                  }}
                >
                  Lieu de naissance
                </span>
                <span
                  className="verti-timeline list-unstyled"
                  style={{ border: "none" }}
                >
                  <div
                    class
                    style={{
                      fontSize: "14px",
                      fontWeight: "normal",
                    }}
                    className="event-list"
                  >
                    <div
                      style={{
                        backgroundColor: "#e6e2e2a1",
                        padding: "16px",
                        borderRadius: "12px",
                        marginTop: "5px",
                        justifyContent: "space-between",
                      }}
                      className="d-flex"
                    >
                      <span>
                        {data.employee_id === null ||
                        data.employee_id === undefined
                          ? null
                          : data.employee_id.employee_birthplace}
                      </span>
                      <UpdateContrat
                        typeInput="default"
                        title="Lieu de naissance"
                        InputValue={data.employee_id.employee_birthplace}
                        parentKey="employee_id"
                        keyElement="employee_birthplace"
                        OnUpdateValue={handleChangeData}
                      />
                    </div>
                  </div>
                </span>
              </div>
            </li>
          )}
      </ul>
    </dt>
  );
};

export default DtComponent;
