import React from 'react'
import Company from "../Company"; 

const SASU = () => {
  return (
    <Company data="SASU" />

  )
}

export default SASU