import React, { useState } from "react";
import Flatpickr from "react-flatpickr";
import convertDate from "../../Utility/hooks/dateConvert";

const DateComponent = (props) => {
  const { defaultValue,onDateChange } = props;
  const [pickDate, setPickDate] = useState(new Date(parseInt(defaultValue)));
 
  const handleDateChange = (e) => {
     const convertToMilliseconds = convertDate.convertDateStringToMilliseconds(...e)
     setPickDate(convertToMilliseconds)
     onDateChange(convertToMilliseconds)
  }
  return (
    <Flatpickr
      value={pickDate}
      className="form-control d-block"
      placeholder="Choisie une date"
       onChange={handleDateChange}
      options={{
        altInput: true,
        dateFormat: "d-m-y",
        allowInput:false
      }}
    />
  );
};

export default DateComponent;
