import React from "react";
import UpdateContrat from "../../../common-component/update-service";
const DdComponent = (props) => {
  const { data, onHandleDdComponentData } = props;

  const handleChangeData = async (result, parentKey, keyElement) => {
    const getResult = typeof result === "string" ? result.trim() : result;
    if (keyElement) data[parentKey][keyElement] = getResult;
    else data[parentKey] = getResult;
    const {
      $collectionId,
      $createdAt,
      $databaseId,
      $permissions,
      $updatedAt,
      $id,
      ...rest
    } = data;

    onHandleDdComponentData($id, rest);
  };
  return (
    <dd className="col-sm-6">
      <ul className="verti-timeline list-unstyled">
        <li className="event-list">
          <div>
            <span
              style={{
                fontSize: "18px",
                margin: "0 0 7px 0",
                textDecoration: "underline",
                fontWeight: "normal",
              }}
            >
              Nom de l'organisme de prévoyance
            </span>
            <span
              className="verti-timeline list-unstyled"
              style={{ border: "none" }}
            >
              <div
                style={{
                  fontSize: "14px",
                  fontWeight: "normal",
                }}
                className="event-list"
              >
                <div
                  style={{
                    backgroundColor: "#e6e2e2a1",
                    padding: "16px",
                    borderRadius: "12px",
                    marginTop: "5px",
                    justifyContent: "space-between",
                  }}
                  className="d-flex"
                >
                  <span>
                    {data.company_id === null || data.company_id === undefined
                      ? null
                      : data.company_id.company_organism_name}
                  </span>
                  <UpdateContrat
                    typeInput="default"
                    title="Nom de l'organisme de prévoyance"
                    InputValue={data.company_id.company_organism_name}
                    parentKey="company_id"
                    keyElement="company_organism_name"
                    OnUpdateValue={handleChangeData}
                  />
                </div>
              </div>
            </span>
          </div>
        </li>
        <li className="event-list">
          <div>
            <span
              style={{
                fontSize: "18px",
                margin: "0 0 7px 0",
                textDecoration: "underline",
                fontWeight: "normal",
              }}
            >
              Adresse complète
            </span>
            <span
              className="verti-timeline list-unstyled"
              style={{ border: "none" }}
            >
              <div
                style={{
                  fontSize: "14px",
                  fontWeight: "normal",
                }}
                className="event-list"
              >
                <div
                  style={{
                    backgroundColor: "#e6e2e2a1",
                    padding: "16px",
                    borderRadius: "12px",
                    marginTop: "5px",
                    justifyContent: "space-between",
                  }}
                  className="d-flex"
                >
                  <span>
                    {data.company_id === null || data.company_id === undefined
                      ? null
                      : data.company_id.company_organism_adress}
                  </span>
                  <UpdateContrat
                    typeInput="default"
                    title="Adresse complète"
                    InputValue={data.company_id.company_organism_adress}
                    parentKey="company_id"
                    keyElement="company_organism_adress"
                    OnUpdateValue={handleChangeData}
                  />
                </div>
              </div>
            </span>
          </div>
        </li>
      </ul>
    </dd>
  );
};

export default DdComponent;
