import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import { getCompany, deleteCompany } from "../../helpers/api_helper";
import logoLegi from "../../assets/images/logo_legihub_test.png";

import { Toast, ToastHeader, ToastBody, Spinner } from "reactstrap";
import convertDate from "../Utility/hooks/dateConvert";
import Icon from "../Utility/hooks/component";
import StaticData from "../Utility/hooks/static";

const Company = (props) => {
  const { data } = props;
  const navigate = useNavigate();

  const [company, setCompany] = useState([]);
  const [loadingIcon, setloadingIcon] = useState({
    loadCDDDdata: true,
    showDeleteIcon: false,
    loadCDDdataRemove: false,
    error: false,
  });
  const [returnID, setReturnID] = useState([]);
  const [toggledClearRows, setToggleClearRows] = useState(false);
  const [toast, settoast] = useState(false);
  const [deleteCount, setDeleteCount] = useState({
    countElement: "",
    totalElement: "",
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [totalRowsCustom, setTotalRowsCustom] = useState(0);
  const [input, setInput] = useState("");
  const [selectedValue, setSelectedValue] = useState("company_name");

  useEffect(() => {
    fetchCompany(1);
  }, []);

  const IconFromStatus = {
    processing: "info",
    created: "success",
    rejected: "warning",
    errored: "danger",
  };
  const handleStateRender = {
    processing: "En attente",
    created: "Accepté",
    rejected: "Rejecté",
    errored: "Erroné",
  };
  const column = [
    {
      name: <span className="font-weight-bold fs-13">Entreprise</span>,
      selector: (row) => (
        <td onClick={() => handleEditClick(row.$id)}> {row.company_name}</td>
      ),
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Représentant</span>,
      selector: (row) => (
        <td onClick={() => handleEditClick(row.$id)}>
          {row.company_price_name}
        </td>
      ),
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Montant</span>,
      selector: (row) => (
        <td onClick={() => handleEditClick(row.$id)}>
          {row.company_capital_amount}
        </td>
      ),
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Date de demande</span>,
      selector: (row) => (
        <td onClick={() => handleEditClick(row.$id)}>
          {convertDate.convertHourlyToDate(row.$createdAt)}
        </td>
      ),

      sortable: true,
    },

    {
      name: <span className="font-weight-bold fs-13">Statut</span>,
      selector: (row) => (
        <td>
          <span
            className={
              "badge badge-soft-" + IconFromStatus[row.state ?? "processing"]
            }
          >
            {handleStateRender[row.state ?? "processing"]}
          </span>
        </td>
      ),

      sortable: true,
    },

    {
      name: <span className="font-weight-bold fs-13">Actions</span>,
      selector: (row) => (
        <td>
          <button
            type="button"
            className="btn btn-outline-success btn-sm me-1"
            onClick={() => handleEditClick(row.$id)}
          >
            <i className="fas fa-eye font-size-15"></i>
          </button>
        </td>
      ),
      sortable: true,
    },
  ];

  const handleEditClick = (selectedContratId) => {
    navigate("/company-detail", {
      replace: false,
      state: selectedContratId,
    });
  };

  const fetchCompany = async (page, field, search, limit) => {
    try {
      setloadingIcon((prev) => ({ ...prev, loadCDDDdata: true }));
      const cursorBefore = page < currentPage;
      const cursor =
        search && page === 1
          ? undefined
          : company.length > 0
          ? cursorBefore
            ? company[0].$id
            : company[company.length - 1].$id
          : undefined;
      const response = await getCompany(
        data,
        limit ?? perPage,
        cursor,
        cursorBefore,
        search,
        field ?? ""
      );
      console.log({ response });

      setCompany(response.documents);
      setTotalRowsCustom(response.total);
      setloadingIcon((prev) => ({ ...prev, loadCDDDdata: false }));
    } catch (error) {
      setloadingIcon((prev) => ({ ...prev, loadCDDDdata: false }));
      setloadingIcon((prev) => ({ ...prev, error: true }));
    }
  };

  const handleSelectElement = async ({ selectedRows }) => {
    console.log({ selectedRows });
    setloadingIcon((prev) => ({ ...prev, showDeleteIcon: false }));

    if (selectedRows.length > 0) {
      setloadingIcon((prev) => ({ ...prev, showDeleteIcon: true }));
      const id = selectedRows.map((map) => map.$id);
      setReturnID(id);
      console.log({ returnID });
    }
  };
  const removeMultipleCompany = async () => {
    setloadingIcon((prev) => ({ ...prev, loadCDDdataRemove: true }));
    setDeleteCount((prev) => ({ ...prev, totalElement: returnID.length }));
    toggleToast();
    for (let index = 0; index < returnID.length; index++) {
      const element = returnID[index];
      const val = await deleteCompany(element);
      if (val) {
        setDeleteCount((prev) => ({ ...prev, countElement: index + 1 }));
      }
    }
    const contrats = await getCompany(data);
    setCompany(contrats);
    setloadingIcon((prev) => ({
      ...prev,
      loadCDDdataRemove: false,
      showDeleteIcon: false,
    }));
    handleClearRows();
  };
  const handleClearRows = () => {
    setToggleClearRows(!toggledClearRows);
  };
  const toggleToast = () => {
    settoast(!toast);
  };
  const handlePageChange = (page) => {
    fetchCompany(page);
    console.log("testHANDLEPAGEchange page", page);
  };
  const handleSearchInput = async (event) => {
    const val = event.target.value;
    setInput(event.target.value);
    if (val.length === 0) {
      const response = await getCompany(data, perPage, "", false);
      console.log({ response });

      setCompany(response.documents);
      setTotalRowsCustom(response.total);
    }
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      if (input.length > 0) {
        handleInputSearch(input);
      }
    }
  };
  const handleClick = () => {
    if (input.length > 0) {
      handleInputSearch(input);
    }
  };
  const handleInputSearch = (searchValue) => {
    console.log(selectedValue);
    fetchCompany(1, selectedValue, searchValue);
  };
  const handleSelect = (optionSelected) => {
    setSelectedValue(optionSelected);
  };
  const renderCompanyList = (companyList) => {
    if (loadingIcon.error) {
      return (
        <div style={{ display: "grid", placeItems: "center", height: "50vh" }}>
          Échec de chargement
        </div>
      );
    }
    if (companyList) {
      return (
        <div className="card">
          <div className="card-body">
            <div className="d-flex justify-content-between mb-4">
              <h4 className="card-title mb-4">{data}</h4>
              <div className="d-flex flex-wrap gap-3">
                <span
                  className=""
                  style={{
                    color: "#74788d",
                    fontWeight: 500,
                    fontSize: 14,
                    paddingTop: "5px",
                  }}
                >
                  Recherche par
                </span>
                <div className="app-search d-flex flex-wrap gap-2 ">
                  <div
                    className="position-relative bottom-50"
                    style={{
                      borderRadius: "5px",
                      backgroundColor: "#f1f5f7",
                      border: "none",
                      padding: "0.5rem",
                    }}
                  >
                    <select
                      value={selectedValue}
                      onChange={(e) => handleSelect(e.target.value)}
                      style={{
                        borderRadius: "5px",
                        backgroundColor: "#f1f5f7",
                        border: "none",
                      }}
                    >
                      {StaticData.CompanyFields.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="position-relative bottom-50">
                    <input
                      type="text"
                      className="form-control"
                      value={input}
                      placeholder="Recherche..."
                      onChange={handleSearchInput}
                      onKeyDown={handleKeyDown}
                    />
                    <span
                      className="ri-search-line"
                      style={{ cursor: "pointer" }}
                      onClick={handleClick}
                    ></span>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-end">
              {loadingIcon.showDeleteIcon && !loadingIcon.loadCDDdataRemove && (
                <i
                  className="fas fa-trash-alt font-size-24"
                  style={{ cursor: "pointer", color: "#fd3b5e" }}
                  onClick={removeMultipleCompany}
                ></i>
              )}
              {loadingIcon.loadCDDdataRemove && (
                <Spinner color="primary" size="md">
                  Loading...
                </Spinner>
              )}
            </div>
            <div className="table-responsive">
              <DataTable
                columns={column}
                highlightOnHover={true}
                pointerOnHover={true}
                onRowClicked={(e) => handleEditClick(e.$id)}
                data={companyList}
                selectableRows
                progressPending={loadingIcon.loadCDDDdata}
                progressComponent={Icon.watch}
                onSelectedRowsChange={handleSelectElement}
                pagination
                paginationServer
                onChangePage={handlePageChange}
                paginationTotalRows={totalRowsCustom}
                noDataComponent="Il n'y a aucun enregistrement à afficher pour le moment"
                clearSelectedRows={toggledClearRows}
                paginationComponentOptions={{
                  noRowsPerPage: true,
                  rangeSeparatorText: "sur",
                  rowsPerPageText: "Nbre par page",
                }}
              />
            </div>
          </div>
        </div>
      );
    }
  };

  return (
    <div className="page-content">
      <div>
        <div>
          <div>
            <h2>Entreprises</h2>
            <div>{renderCompanyList(company)}</div>
            <div
              className="position-fixed bottom-0 end-0 p-3"
              style={{ zIndex: "1005" }}
            >
              <Toast isOpen={toast}>
                <ToastHeader toggle={toggleToast}>
                  <img src={logoLegi} alt="" className="me-2" height="18" />
                </ToastHeader>
                <ToastBody color="primary">
                  {`${deleteCount.countElement} supprimé${
                    deleteCount.countElement > 1 ? "s" : ""
                  } sur ${deleteCount.totalElement}`}
                </ToastBody>
              </Toast>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Company;
