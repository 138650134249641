import React from "react";
import { Row, Card, CardBody } from "reactstrap";
import StaticData from "../../../Utility/hooks/static";
import UpdateContrat from "../../../common-component/update-service";
import convertDate from "../../../Utility/hooks/dateConvert";

const Index = (props) => {
  const { title, data, onUpdateWorkplace } = props;
  const handleChangeData = async (result, parentKey, keyElement) => {
    const getResult = typeof result === "string" ? result.trim() : result;
    if (keyElement) data[parentKey][keyElement] = getResult;
    else data[parentKey] = getResult;

    const {
      $collectionId,
      $createdAt,
      $databaseId,
      $permissions,
      $updatedAt,
      $id,
      ...rest
    } = data;
    onUpdateWorkplace($id, rest);
  };
  return (
    <>
      <Row>
        <Card>
          <CardBody>
            <h4 className="card-title mb-5 text-primary">{title}</h4>
            <dl className="row mb-0">
              <dt className="col-sm-6">
                <ul className="verti-timeline list-unstyled">
                  <li className="event-list">
                    <div>
                      <span
                        style={{
                          fontSize: "15px",
                          margin: "0 0 7px 0",
                          textDecoration: "underline",
                          fontWeight: "normal",
                        }}
                      >
                        Lieu du travail
                      </span>
                      <span
                        className="verti-timeline list-unstyled"
                        style={{ border: "none" }}
                      >
                        <div
                          style={{
                            fontSize: "12px",
                            fontWeight: "normal",
                          }}
                          className="event-list"
                        >
                          <div
                            style={{
                              backgroundColor: "#e6e2e2a1",
                              padding: "16px",
                              borderRadius: "12px",
                              marginTop: "5px",
                              justifyContent: "space-between",
                              fontSize: "14px",
                            }}
                            className="d-flex"
                          >
                            <span>
                              {data.employee_id === null ||
                              data.employee_id === undefined
                                ? null
                                : data.employee_id.employee_working_place}
                            </span>
                            <UpdateContrat
                              title="Lieu du travail"
                              InputValue={
                                data.employee_id.employee_working_place
                              }
                              parentKey="employee_id"
                              keyElement="employee_working_place"
                              OnUpdateValue={handleChangeData}
                              typeInput="radio"
                              typeValue={StaticData.Radio.WorkPlace}
                              radiOption={true}
                            />
                          </div>
                        </div>
                      </span>
                    </div>
                  </li>
                  {data.contrat_type === "CDI" && (
                    <>
                    <li className="event-list">
                      <div>
                        <span
                          style={{
                            fontSize: "15px",
                            margin: "0 0 7px 0",
                            textDecoration: "underline",
                            fontWeight: "normal",
                          }}
                        >
                          Calcule des jours de congé du salarié en
                        </span>
                        <span
                          className="verti-timeline list-unstyled"
                          style={{ border: "none" }}
                        >
                          <div
                            style={{
                              fontSize: "12px",
                              fontWeight: "normal",
                            }}
                            className="event-list"
                          >
                            <div
                              style={{
                                backgroundColor: "#e6e2e2a1",
                                padding: "16px",
                                borderRadius: "12px",
                                marginTop: "5px",
                                justifyContent: "space-between",
                                fontSize: "14px",
                              }}
                              className="d-flex"
                            >
                              <span>
                                {data.employee_id.is_calculate_on_work_days
                                  ? "Jour ouvrables"
                                  : "Jour ouvrés"}
                              </span>
                              <UpdateContrat
                                title="Calcule des jours de congé du salarié en"
                                InputValue={
                                  data.employee_id.is_calculate_on_work_days
                                }
                                parentKey="employee_id"
                                keyElement="is_calculate_on_work_days"
                                OnUpdateValue={handleChangeData}
                                typeInput="radio"
                                typeValue={StaticData.Radio.Holiday}
                              />
                            </div>
                          </div>
                        </span>
                      </div>
                    </li>
                    <li className="event-list">
                    <div>
                      <span
                        style={{
                          fontSize: "15px",
                          margin: "0 0 7px 0",
                          textDecoration: "underline",
                          fontWeight: "normal",
                        }}
                      >
                        Nom de la mutuelle
                      </span>
                      <span
                        className="verti-timeline list-unstyled"
                        style={{ border: "none" }}
                      >
                        <div
                          style={{
                            fontSize: "12px",
                            fontWeight: "normal",
                          }}
                          className="event-list"
                        >
                          <div
                            style={{
                              backgroundColor: "#e6e2e2a1",
                              padding: "16px",
                              borderRadius: "12px",
                              marginTop: "5px",
                              justifyContent: "space-between",
                              fontSize: "14px",
                            }}
                            className="d-flex"
                          >
                            <span>
                              {data.employee_id === null ||
                              data.employee_id === undefined
                                ? null
                                : data.employee_id.insurance_name}
                            </span>
                            <UpdateContrat
                              title="Nom de la mutuelle"
                              InputValue={
                                data.employee_id.insurance_name
                              }
                              parentKey="employee_id"
                              keyElement="insurance_name"
                              OnUpdateValue={handleChangeData}
                              typeInput="default"
                              typeValue={StaticData.Radio.WorkPlace}
                              
                            />
                          </div>
                        </div>
                      </span>
                    </div>
                  </li>
                    </>
                    
                    
                  )}
                </ul>
              </dt>
              {data.contrat_type === "CDI" && (
                <dd className="col-sm-6">
                  <ul className="verti-timeline list-unstyled">
                    <li className="event-list">
                      <div>
                        <span
                          style={{
                            fontSize: "15px",
                            margin: "0 0 7px 0",
                            textDecoration: "underline",
                            fontWeight: "normal",
                          }}
                        >
                          Nombre de jours de congés payés légaux
                        </span>
                        <span
                          className="verti-timeline list-unstyled"
                          style={{ border: "none" }}
                        >
                          <div
                            style={{
                              fontSize: "12px",
                              fontWeight: "normal",
                            }}
                            className="event-list"
                          >
                            <div
                              style={{
                                backgroundColor: "#e6e2e2a1",
                                padding: "16px",
                                borderRadius: "12px",
                                marginTop: "5px",
                                justifyContent: "space-between",
                                fontSize: "14px",
                              }}
                              className="d-flex"
                            >
                              <span>
                                {data.employee_id === null ||
                                data.employee_id === undefined
                                  ? null
                                  : data.employee_id.vacation_duration}
                              </span>
                              <UpdateContrat
                                title="Lieu du travail"
                                InputValue={data.employee_id.vacation_duration}
                                parentKey="employee_id"
                                keyElement="vacation_duration"
                                OnUpdateValue={handleChangeData}
                                typeInput="default"
                              />
                            </div>
                          </div>
                        </span>
                      </div>
                    </li>
                    <li className="event-list">
                      <div>
                        <span
                          style={{
                            fontSize: "15px",
                            margin: "0 0 7px 0",
                            textDecoration: "underline",
                            fontWeight: "normal",
                          }}
                        >
                          Date de début (Période de calcul)
                        </span>
                        <span
                          className="verti-timeline list-unstyled"
                          style={{ border: "none" }}
                        >
                          <div
                            style={{
                              fontSize: "12px",
                              fontWeight: "normal",
                            }}
                            className="event-list"
                          >
                            <div
                              style={{
                                backgroundColor: "#e6e2e2a1",
                                padding: "16px",
                                borderRadius: "12px",
                                marginTop: "5px",
                                justifyContent: "space-between",
                                fontSize: "14px",
                              }}
                              className="d-flex"
                            >
                              <span>
                                {convertDate.convertMillisecondToDate(
                                  data.employee_id.vacation_period_start
                                )}
                              </span>
                              <UpdateContrat
                                title="Date de début (Période de calcul)"
                                InputValue={
                                  data.employee_id.vacation_period_start
                                }
                                parentKey="employee_id"
                                keyElement="vacation_period_start"
                                OnUpdateValue={handleChangeData}
                                typeInput="date"
                              />
                            </div>
                          </div>
                        </span>
                      </div>
                    </li>
                    <li className="event-list">
                      <div>
                        <span
                          style={{
                            fontSize: "15px",
                            margin: "0 0 7px 0",
                            textDecoration: "underline",
                            fontWeight: "normal",
                          }}
                        >
                          Date de Fin (Période de calcul)
                        </span>
                        <span
                          className="verti-timeline list-unstyled"
                          style={{ border: "none" }}
                        >
                          <div
                            style={{
                              fontSize: "12px",
                              fontWeight: "normal",
                            }}
                            className="event-list"
                          >
                            <div
                              style={{
                                backgroundColor: "#e6e2e2a1",
                                padding: "16px",
                                borderRadius: "12px",
                                marginTop: "5px",
                                justifyContent: "space-between",
                                fontSize: "14px",
                              }}
                              className="d-flex"
                            >
                              <span>
                                {convertDate.convertMillisecondToDate(
                                  data.employee_id.vacation_period_end
                                )}
                              </span>
                              <UpdateContrat
                                title="Date de Fin (Période de calcul)"
                                InputValue={
                                  data.employee_id.vacation_period_end
                                }
                                parentKey="employee_id"
                                keyElement="vacation_period_end"
                                OnUpdateValue={handleChangeData}
                                typeInput="date"
                              />
                            </div>
                          </div>
                        </span>
                      </div>
                    </li>
                  </ul>
                </dd>
              )}
            </dl>
          </CardBody>
        </Card>
      </Row>
    </>
  );
};

export default Index;
