import React from "react";
import UpdateContrat from "../../../common-component/update-service";
import StaticData from "../../../Utility/hooks/static";
import convertDate from "../../../Utility/hooks/dateConvert";

const DDComponent = (props) => {
  const { data, onHandleDdComponentData } = props;

  const handleChangeData = (result, parentKey, keyElement) => {
    const getResult = typeof result === "string" ? result.trim() : result;
    if (keyElement) data[parentKey][keyElement] = getResult;
    else data[parentKey] = getResult;
    const {
      $collectionId,
      $createdAt,
      $databaseId,
      $permissions,
      $updatedAt,
      $id,
      ...rest
    } = data;
    rest.employee_id.employee_trial_re_end_date =
      rest.employee_id.employee_trial_re_end_date.toString();
    rest.employee_id.employee_trial_re_start_date =
      rest.employee_id.employee_trial_re_start_date.toString();
    onHandleDdComponentData($id, rest);
  };
  return (
    <dt className="col-sm-6">
      <ul className="verti-timeline list-unstyled">
        {/* Gestion de la durée du travail au niveau du CDI START */}
        {data.contrat_type === "CDI" && (
          <>
            <li className="event-list">
              <div>
                <span
                  style={{
                    fontSize: "18px",
                    margin: "0 0 7px 0",
                    textDecoration: "underline",
                    fontWeight: "normal",
                    justifyContent: "space-between",
                  }}
                  className="d-flex"
                >
                  <span>Durée du travail</span>
                  {/* <UpdateContrat
                    title="Convention"
                    InputValue={data.convention_max_working_days}
                    parentKey="convention_max_working_days"
                    OnUpdateValue={handleChangeData}
                    typeInput="radio"
                     typeValue={StaticData.Radio.NumberDays}
                    // radiOption = {true}
                    /> */}
                </span>
              </div>
            </li>

            {/* LI Temps partiel Start */}
            {(data.employee_id.work_days.length > 0 ||
              data.employee_id.work_weeks.length > 0) && (
              <li className="event-list">
                <div>
                  <span
                    style={{
                      fontSize: "18px",
                      margin: "0 0 7px 0",
                      textDecoration: "underline",
                      fontWeight: "normal",
                    }}
                  >
                    Temps partiel
                  </span>
                </div>
                <ul className="verti-timeline list-unstyled">
                  {data.employee_id.week_worktime_hours !== null && (
                    <li className="event-list">
                      <div>
                        <span
                          style={{
                            fontSize: "18px",
                            margin: "0 0 7px 0",
                            textDecoration: "underline",
                            fontWeight: "normal",
                          }}
                        >
                          {data.employee_id.part_time_period === "weekly"
                            ? "Nombre d'heures Hebdomendaire"
                            : "Nombre d'heures mensuelle"}
                        </span>
                        <span
                          className="verti-timeline list-unstyled"
                          style={{ border: "none" }}
                        >
                          <div
                            style={{
                              fontSize: "14px",
                              fontWeight: "normal",
                            }}
                            className="event-list"
                          >
                            <div
                              style={{
                                backgroundColor: "#e6e2e2a1",
                                padding: "16px",
                                borderRadius: "12px",
                                marginTop: "5px",
                                justifyContent: "space-between",
                              }}
                              className="d-flex"
                            >
                              <span>
                                {data.employee_id === null ||
                                data.employee_id === undefined
                                  ? null
                                  : data.employee_id.employee_working_time}
                              </span>
                              <UpdateContrat
                                typeInput="default"
                                title={
                                  data.employee_id.part_time_period === "weekly"
                                    ? "Nombre d'heures Hebdomendaire"
                                    : "Nombre d'heures mensuelle"
                                }
                                InputValue={
                                  data.employee_id.employee_working_time
                                }
                                parentKey="employee_id"
                                keyElement="employee_working_time"
                                OnUpdateValue={handleChangeData}
                              />
                            </div>
                          </div>
                        </span>
                      </div>
                    </li>
                  )}
                  {data.employee_id.work_days.length > 0 && (
                    <li className="event-list">
                      <div>
                        <div
                          style={{
                            fontSize: "18px",
                            margin: "0 0 7px 0",
                            textDecoration: "underline",
                            fontWeight: "normal",
                            justifyContent: "space-between",
                          }}
                          className="d-flex"
                        >
                          <span>Jour de travail</span>
                          <UpdateContrat
                            title="Jour de travail"
                            InputValue={data.employee_id.work_days}
                            parentKey="employee_id"
                            keyElement="work_days"
                            OnUpdateValue={handleChangeData}
                            typeInput="checkbox"
                            typeValue={StaticData.CheckBox.WorkDays}
                          />
                        </div>
                        {data.employee_id === null ||
                        data.employee_id === undefined ? null : data.employee_id
                            .work_days.length === 0 ? (
                          "Aucun jour"
                        ) : (
                          <dl className="row mb-0">
                            {data.employee_id.work_days.map((val, index) => (
                              <React.Fragment key={index}>
                                {index < data.employee_id.work_days / 2 ? (
                                  <dd className="col-sm-6">
                                    <span
                                      className="verti-timeline list-unstyled"
                                      style={{ border: "none" }}
                                    >
                                      <div
                                        style={{
                                          fontSize: "12px",
                                          fontWeight: "normal",
                                        }}
                                        className="event-list"
                                      >
                                        <div
                                          style={{
                                            backgroundColor: "#e6e2e2a1",
                                            padding: "16px",
                                            borderRadius: "12px",
                                            marginTop: "5px",
                                            fontSize: "14px",
                                          }}
                                        >
                                          - {val}
                                        </div>
                                      </div>
                                    </span>
                                  </dd>
                                ) : (
                                  <dt className="col-sm-6">
                                    <span
                                      className="verti-timeline list-unstyled"
                                      style={{ border: "none" }}
                                    >
                                      <p
                                        style={{
                                          fontSize: "12px",
                                          fontWeight: "normal",
                                        }}
                                        className="event-list"
                                      >
                                        <div
                                          style={{
                                            backgroundColor: "#e6e2e2a1",
                                            padding: "16px",
                                            borderRadius: "12px",
                                            marginTop: "5px",
                                            fontSize: "14px",
                                          }}
                                        >
                                          - {val}
                                        </div>
                                      </p>
                                    </span>
                                  </dt>
                                )}
                              </React.Fragment>
                            ))}
                          </dl>
                        )}
                      </div>
                    </li>
                  )}

                  {data.employee_id.work_weeks.length > 0 && (
                    <li className="event-list">
                      <div>
                        <div
                          style={{
                            fontSize: "18px",
                            margin: "0 0 7px 0",
                            textDecoration: "underline",
                            fontWeight: "normal",
                            justifyContent: "space-between",
                          }}
                          className="d-flex"
                        >
                          <span>Semaine de travail</span>
                          <UpdateContrat
                            title="Semaine de travail"
                            InputValue={data.employee_id.work_weeks}
                            parentKey="employee_id"
                            keyElement="work_weeks"
                            OnUpdateValue={handleChangeData}
                            typeInput="checkbox"
                            typeValue={StaticData.CheckBox.WorkWeeks}
                          />
                        </div>
                        {data.employee_id === null ||
                        data.employee_id === undefined ? null : data.employee_id
                            .work_weeks.length === 0 ? (
                          "Aucune semaine"
                        ) : (
                          <dl className="row mb-0">
                            {data.employee_id.work_weeks.map((val, index) => (
                              <React.Fragment key={index}>
                                {index < data.employee_id.work_weeks / 2 ? (
                                  <dd className="col-sm-6">
                                    <span
                                      className="verti-timeline list-unstyled"
                                      style={{ border: "none" }}
                                    >
                                      <div
                                        style={{
                                          fontSize: "12px",
                                          fontWeight: "normal",
                                        }}
                                        className="event-list"
                                      >
                                        <div
                                          style={{
                                            backgroundColor: "#e6e2e2a1",
                                            padding: "16px",
                                            borderRadius: "12px",
                                            marginTop: "5px",
                                            fontSize: "14px",
                                          }}
                                        >
                                          - {val}
                                        </div>
                                      </div>
                                    </span>
                                  </dd>
                                ) : (
                                  <dt className="col-sm-6">
                                    <span
                                      className="verti-timeline list-unstyled"
                                      style={{ border: "none" }}
                                    >
                                      <p
                                        style={{
                                          fontSize: "12px",
                                          fontWeight: "normal",
                                        }}
                                        className="event-list"
                                      >
                                        <div
                                          style={{
                                            backgroundColor: "#e6e2e2a1",
                                            padding: "16px",
                                            borderRadius: "12px",
                                            marginTop: "5px",
                                            fontSize: "14px",
                                          }}
                                        >
                                          - {val}
                                        </div>
                                      </p>
                                    </span>
                                  </dt>
                                )}
                              </React.Fragment>
                            ))}
                          </dl>
                        )}
                      </div>
                    </li>
                  )}
                </ul>
              </li>
            )}

            {/* LI Temps partiel End */}

            {/* LI Temps Complet Start */}
            {data.employee_id.working_hours_type === "35 hours" && (
              <div
                style={{
                  fontSize: "14px",
                  fontWeight: "normal",
                }}
                className="event-list"
              >
                <div
                  style={{
                    backgroundColor: "#e6e2e2a1",
                    padding: "16px",
                    borderRadius: "12px",
                    marginTop: "5px",
                    justifyContent: "space-between",
                  }}
                  className="d-flex"
                >
                  <span>Le salaire est aux 35 heures</span>
                </div>
              </div>
            )}
            {/* LI Temps Complet End */}

            {/* LI Temps Complet Forfait Start */}
            {data.employee_id.working_hours_type !== "35 hours" &&
              (data.has_periodic_review ||
                data.convention_max_working_days !== null ||
                !data.plus_annual_working_days) && (
                <>
                  <li className="event-list">
                    <div>
                      <span
                        style={{
                          fontSize: "18px",
                          margin: "0 0 7px 0",
                          textDecoration: "underline",
                          fontWeight: "normal",
                        }}
                      >
                        Le salaire est au forfait annuel jours
                      </span>
                      <ul className="verti-timeline list-unstyled">
                        <li className="event-list">
                          <div>
                            <span
                              style={{
                                fontSize: "18px",
                                margin: "0 0 7px 0",
                                textDecoration: "underline",
                                fontWeight: "normal",
                              }}
                            >
                              Convention
                            </span>
                            <span
                              className="verti-timeline list-unstyled"
                              style={{ border: "none" }}
                            >
                              <div
                                style={{
                                  fontSize: "14px",
                                  fontWeight: "normal",
                                }}
                                className="event-list"
                              >
                                <div
                                  style={{
                                    backgroundColor: "#e6e2e2a1",
                                    padding: "16px",
                                    borderRadius: "12px",
                                    marginTop: "5px",
                                    justifyContent: "space-between",
                                  }}
                                  className="d-flex"
                                >
                                  <span>
                                    {data.has_periodic_review
                                      ? StaticData.Radio.Convention.at(0).name
                                      : StaticData.Radio.Convention.at(1).name}
                                  </span>
                                  <UpdateContrat
                                    title="Convention"
                                    InputValue={data.has_periodic_review}
                                    parentKey="has_periodic_review"
                                    OnUpdateValue={handleChangeData}
                                    typeInput="radio"
                                    typeValue={StaticData.Radio.Convention}
                                  />
                                </div>
                              </div>
                            </span>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </li>

                  <li className="event-list">
                    <div>
                      <ul className="verti-timeline list-unstyled">
                        <li className="event-list">
                          <div>
                            <span
                              style={{
                                fontSize: "18px",
                                margin: "0 0 7px 0",
                                textDecoration: "underline",
                                fontWeight: "normal",
                              }}
                            >
                              Nombre de jours annuel
                            </span>
                            <span
                              className="verti-timeline list-unstyled"
                              style={{ border: "none" }}
                            >
                              <div
                                style={{
                                  fontSize: "14px",
                                  fontWeight: "normal",
                                }}
                                className="event-list"
                              >
                                <div
                                  style={{
                                    backgroundColor: "#e6e2e2a1",
                                    padding: "16px",
                                    borderRadius: "12px",
                                    marginTop: "5px",
                                    justifyContent: "space-between",
                                  }}
                                  className="d-flex"
                                >
                                  <span>
                                    {data.convention_max_working_days}
                                  </span>
                                  <UpdateContrat
                                    title="Convention"
                                    InputValue={
                                      data.convention_max_working_days
                                    }
                                    parentKey="convention_max_working_days"
                                    OnUpdateValue={handleChangeData}
                                    typeInput="radio"
                                    typeValue={StaticData.Radio.NumberDays}
                                    radiOption={true}
                                  />
                                </div>
                              </div>
                            </span>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </li>
                </>
              )}
            {/* LI Temps Complet Forfait End */}
          </>
        )}
        {/* Gestion de la durée du travail au niveau du CDI CLOSE */}
        {data.contrat_type !== "Promesse d'embauche" &&
          data.contrat_type !== "Rupture Période d'Essai" &&
          data.contrat_type !== "Renouvellement Période d'Essai" && (
            <li className="event-list">
              <div>
                <div
                  style={{
                    fontSize: "18px",
                    margin: "0 0 7px 0",
                    textDecoration: "underline",
                    fontWeight: "normal",
                    justifyContent: "space-between",
                  }}
                  className="d-flex"
                >
                  <span>Avantages pour le collaborateur</span>
                  <UpdateContrat
                    title="Avantages pour le collaborateur"
                    InputValue={data.employee_id.employee_benefits}
                    parentKey="employee_id"
                    keyElement="employee_benefits"
                    OnUpdateValue={handleChangeData}
                    typeInput="checkbox"
                    typeValue={StaticData.CheckBox.EmployeeAdvantage}
                  />
                </div>
                {data.employee_id === null ||
                data.employee_id === undefined ? null : data.employee_id
                    .employee_benefits.length === 0 ? (
                  "Aucun avantage"
                ) : data.employee_id === null ||
                  data.employee_id === undefined ? null : (
                  <dl className="row mb-0">
                    {data.employee_id.employee_benefits.map((val, index) => (
                      <React.Fragment key={index}>
                        {index <
                        data.employee_id.employee_benefits.length / 2 ? (
                          <dd className="col-sm-6">
                            <span
                              className="verti-timeline list-unstyled"
                              style={{ border: "none" }}
                            >
                              <div
                                style={{
                                  fontSize: "12px",
                                  fontWeight: "normal",
                                }}
                                className="event-list"
                              >
                                <div
                                  style={{
                                    backgroundColor: "#e6e2e2a1",
                                    padding: "16px",
                                    borderRadius: "12px",
                                    marginTop: "5px",
                                    fontSize: "14px",
                                  }}
                                >
                                  - {val}
                                </div>
                              </div>
                            </span>
                          </dd>
                        ) : (
                          <dt className="col-sm-6">
                            <span
                              className="verti-timeline list-unstyled"
                              style={{ border: "none" }}
                            >
                              <div
                                style={{
                                  fontSize: "12px",
                                  fontWeight: "normal",
                                }}
                                className="event-list"
                              >
                                <div
                                  style={{
                                    backgroundColor: "#e6e2e2a1",
                                    padding: "16px",
                                    borderRadius: "12px",
                                    marginTop: "5px",
                                    fontSize: "14px",
                                  }}
                                >
                                  - {val}
                                </div>
                              </div>
                            </span>
                          </dt>
                        )}
                      </React.Fragment>
                    ))}
                  </dl>
                )}
              </div>
            </li>
          )}
        {data.employee_id.employee_gross_pay !== "" &&
          data.contrat_type !== "Rupture Période d'Essai" && (
            <li className="event-list">
              <div>
                <span
                  style={{
                    fontSize: "18px",
                    margin: "0 0 7px 0",
                    textDecoration: "underline",
                    fontWeight: "normal",
                  }}
                >
                  Rémunération mensuelle brute du salarié
                </span>
                <span
                  className="verti-timeline list-unstyled"
                  style={{ border: "none" }}
                >
                  <div
                    style={{
                      fontSize: "14px",
                      fontWeight: "normal",
                    }}
                    className="event-list"
                  >
                    <div
                      style={{
                        backgroundColor: "#e6e2e2a1",
                        padding: "16px",
                        borderRadius: "12px",
                        marginTop: "5px",
                        justifyContent: "space-between",
                      }}
                      className="d-flex"
                    >
                      <span>
                        {data.employee_id === null ||
                        data.employee_id === undefined
                          ? null
                          : data.employee_id.employee_gross_pay}
                      </span>
                      <UpdateContrat
                        title="Rémunération mensuelle brute du salarié"
                        InputValue={data.employee_id.employee_gross_pay}
                        typeInput="default"
                        parentKey="employee_id"
                        keyElement="employee_gross_pay"
                        OnUpdateValue={handleChangeData}
                      />
                    </div>
                  </div>
                </span>
              </div>
            </li>
          )}

        {data.contrat_type !== "Rupture Période d'Essai" &&
          data.contrat_type !== "Promesse d'embauche" &&
          data.contrat_type !== "Renouvellement Période d'Essai" && (
            <li className="event-list">
              <div>
                <span
                  style={{
                    fontSize: "18px",
                    margin: "0 0 7px 0",
                    textDecoration: "underline",
                    fontWeight: "normal",
                  }}
                >
                  Pourcentage indennité fin de contract
                </span>
                <span
                  className="verti-timeline list-unstyled"
                  style={{ border: "none" }}
                >
                  <div
                    style={{
                      fontSize: "14px",
                      fontWeight: "normal",
                    }}
                    className="event-list"
                  >
                    <div
                      style={{
                        backgroundColor: "#e6e2e2a1",
                        padding: "16px",
                        borderRadius: "12px",
                        marginTop: "5px",
                        justifyContent: "space-between",
                      }}
                      className="d-flex"
                    >
                      <span>
                        {data.employee_id === null ||
                        data.employee_id === undefined
                          ? null
                          : data.employee_id.employee_severance_pay}
                      </span>
                      <UpdateContrat
                        title="Pourcentage indennité fin de contract"
                        InputValue={data.employee_id.employee_severance_pay}
                        typeInput="default"
                        parentKey="employee_id"
                        keyElement="employee_severance_pay"
                        OnUpdateValue={handleChangeData}
                      />
                    </div>
                  </div>
                </span>
              </div>
            </li>
          )}

        {(data.contrat_type === "Rupture Période d'Essai" ||
          data.contrat_type === "Renouvellement Période d'Essai") && (
          <li className="event-list">
            <div>
              <span
                style={{
                  fontSize: "18px",
                  margin: "0 0 7px 0",
                  textDecoration: "underline",
                  fontWeight: "normal",
                }}
              >
                Date de fin clause de Renouvellement
              </span>
              <span
                className="verti-timeline list-unstyled"
                style={{ border: "none" }}
              >
                <div
                  style={{
                    fontSize: "14px",
                    fontWeight: "normal",
                  }}
                  className="event-list"
                >
                  <div
                    style={{
                      backgroundColor: "#e6e2e2a1",
                      padding: "16px",
                      borderRadius: "12px",
                      marginTop: "5px",
                      justifyContent: "space-between",
                    }}
                    className="d-flex"
                  >
                    <span>
                      {data.employee_id === null ||
                      data.employee_id === undefined
                        ? null
                        : convertDate.convertMillisecondToDate(
                            data.employee_id.employee_trial_re_end_date
                          )}
                    </span>
                    <UpdateContrat
                      title="Date de fin clause de Renouvellement"
                      InputValue={data.employee_id.employee_trial_re_end_date}
                      typeInput="date"
                      parentKey="employee_id"
                      keyElement="employee_trial_re_end_date"
                      OnUpdateValue={handleChangeData}
                    />
                  </div>
                </div>
              </span>
            </div>
          </li>
        )}
        {data.contrat_type !== "Renouvellement Période d'Essai" &&
          data.contrat_type !== "CDD" &&
          data.contrat_type !== "CDI" && (
            <li className="event-list">
              <div>
                <span
                  style={{
                    fontSize: "18px",
                    margin: "0 0 7px 0",
                    textDecoration: "underline",
                    fontWeight: "normal",
                  }}
                >
                  Date de début clause de Renouvellement
                </span>
                <span
                  className="verti-timeline list-unstyled"
                  style={{ border: "none" }}
                >
                  <div
                    style={{
                      fontSize: "14px",
                      fontWeight: "normal",
                    }}
                    className="event-list"
                  >
                    <div
                      style={{
                        backgroundColor: "#e6e2e2a1",
                        padding: "16px",
                        borderRadius: "12px",
                        marginTop: "5px",
                        justifyContent: "space-between",
                      }}
                      className="d-flex"
                    >
                      <span>
                        {data.employee_id === null ||
                        data.employee_id === undefined
                          ? null
                          : convertDate.convertMillisecondToDate(
                              data.employee_id.employee_trial_re_start_date
                            )}
                      </span>
                      <UpdateContrat
                        title="Pourcentage indennité fin de contract"
                        InputValue={
                          data.employee_id.employee_trial_re_start_date
                        }
                        typeInput="date"
                        parentKey="employee_id"
                        keyElement="employee_trial_re_start_date"
                        OnUpdateValue={handleChangeData}
                      />
                    </div>
                  </div>
                </span>
              </div>
            </li>
          )}
      </ul>
    </dt>
  );
};

export default DDComponent;
