import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import logolight from '../../assets/images/logo-light.png';
import logodark from '../../assets/images/logo-dark.png';
import logoLegi from '../../assets/images/logo_legihub_test.png';
import { Client, Account } from 'appwrite';
import { getUser } from "../../helpers/api_helper";

import { Row, Col, CardBody, Card, Alert, Container, Form, Input, FormFeedback, Label, Spinner, Toast, ToastHeader, ToastBody, InputGroup, InputGroupText } from 'reactstrap';
// router
import { Link, useNavigate } from 'react-router-dom';
import withRouter from '../../components/Common/withRouter';
//redux
import { useSelector, useDispatch } from 'react-redux';
// Formik validation
import * as Yup from 'yup';
import { useFormik } from 'formik';
//Social Media Imports
import { GoogleLogin } from 'react-google-login';
// import TwitterLogin from "react-twitter-auth"
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
// actions
import { apiError, loginUser, socialLogin } from '../../store/actions';
//Import config
import { facebook, google } from '../../config';

//

const Login = (props) => {
  document.title = 'Login | LEGIHUB - Admin Dashboard';
  const [requestIsLoading, setRequestIsLoading] = useState(false);
  const [toast, setToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [isPasswordHidden, setIsPasswordHidden] = useState(true);
  const togglePasswordVisibility = () => {
    setIsPasswordHidden(!isPasswordHidden);
  };

  const toggleToast = () => {
    console.log("setToast before: " + toast);
    setToast(!toast);
    console.log("setToast after: " + toast);

    setTimeout(() => {
      if (!toast === true) {
        setToast(false);
      }
    }, 5000);
  };
  //const navigate = useNavigate();

  const dispatch = useDispatch();

  const onRequestIsLoading = (value) => {
    setRequestIsLoading(value);
  };

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: '' || '',
      password: '' || '',
    },
    validationSchema: Yup.object({
      email: Yup.string().required('Veuillez entrer votre Email'),
      password: Yup.string().required('Veuillez entrer votre mot de passe'),
    }),
    onSubmit: (values) => {
      dispatch(loginUser(values, props.router.navigate));

      // onRequestIsLoading(true);

      // const client = new Client();

      // const account = new Account(client);

      // client.setEndpoint('https://api.shopybase.com/v1').setProject('ai_project_dev');
      // const promise = account.createEmailSession(validation.values.email, validation.values.password);

      // promise.then(
      //   async function (response) {
      //     console.log('Success on session create at login page');
      //     console.log(response);
      //     onRequestIsLoading(false);
      //     localStorage.setItem('authUser', JSON.stringify(response));
      //     localStorage.setItem('user', JSON.stringify(response));

      //     const user = await getUser();
      //     if (user.prefs["finished_onbording"]) {
      //       props.router.navigate('/dashboard', {
      //         replace: false,
      //       });
      //     } else {
      //       props.router.navigate('/setup', {
      //         replace: false,
      //       });
      //     }




      //   },
      //   function (error) {
      //     console.log('error on session check', error);
      //     onRequestIsLoading(false);
      //     dispatch(apiError(error.message));
      //     //   form.error_text = error;
      //   }
      // );
    },
  });

  const { error, loading } = useSelector((state) => ({
    error: state.login.error,
    loading: state.login.loading
  }));

  // handleValidSubmit
  // const handleValidSubmit = (event, values) => {
  //   dispatch(loginUser(values, props.router.navigate));
  // };

  const signIn = (res, type) => {
    if (type === 'google' && res) {
      const postData = {
        name: res.profileObj.name,
        email: res.profileObj.email,
        token: res.tokenObj.access_token,
        idToken: res.tokenId,
      };
      dispatch(socialLogin(postData, props.router.navigate, type));
    } else if (type === 'facebook' && res) {
      const postData = {
        name: res.name,
        email: res.email,
        token: res.accessToken,
        idToken: res.tokenId,
      };
      dispatch(socialLogin(postData, props.router.navigate, type));
    }
  };

  //handleGoogleLoginResponse
  const googleResponse = (response) => {
    signIn(response, 'google');
  };

  //handleTwitterLoginResponse
  // const twitterResponse = e => {}

  //handleFacebookLoginResponse
  const facebookResponse = (response) => {
    signIn(response, 'facebook');
  };

  useEffect(() => {
    dispatch(apiError(''));
    document.body.className = 'bg-pattern';
    // remove classname when component will unmount
    return function cleanup() {
      document.body.className = '';
    };
  }, [dispatch]);

  const svgs = {
    eye_icon: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="" style={{ width: "24px" }}>
      <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z" />
      <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
    </svg>,
    eye_slash_icon: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="" style={{ width: "24px" }}>
      <path strokeLinecap="round" strokeLinejoin="round" d="M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88" />
    </svg>


  }
  return (
    <React.Fragment>
      <div className="bg-overlay"></div>
      <div className="account-pages my-5 pt-5">
        <Container>
          <Row className="justify-content-center">
            <Col lg={6} md={8} xl={4}>
              <Card>
                <CardBody className="p-4">
                  <div>
                    <div className="text-center">
                      <Link to="/">
                        <img src={logoLegi} alt="" height="24" className="auth-logo logo-dark mx-auto" />
                        <img src={logoLegi} alt="" height="24" className="auth-logo logo-light mx-auto" />
                      </Link>
                    </div>
                    <h4 className="font-size-18 text-muted mt-2 text-center">Bienvenue !</h4>
                    <p className="mb-5 text-center">Connectez-vous pour continuer</p>
                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}>
                      {error ? (
                        <Alert color="danger">
                          <div>{error}</div>
                        </Alert>
                      ) : null}
                      <Row>
                        <Col md={12}>
                          <div className="mb-4">
                            <Label className="form-label">Email</Label>
                            <Input
                              name="email"
                              className="form-control"
                              placeholder="Enter email"
                              type="email"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.email || ''}
                              invalid={validation.touched.email && validation.errors.email ? true : false}
                            />
                            {validation.touched.email && validation.errors.email ? (
                              <FormFeedback type="invalid">
                                <div>{validation.errors.email}</div>
                              </FormFeedback>
                            ) : null}
                          </div>
                          <div className="mb-4">
                            <Label className="form-label">Password</Label>
                            <Input
                              name="password"
                              value={validation.values.password || ''}
                              type="password"
                              placeholder="Enter Password"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              invalid={validation.touched.password && validation.errors.password ? true : false}
                            />
                            {validation.touched.password && validation.errors.password ? (
                              <FormFeedback type="invalid">
                                <div> {validation.errors.password} </div>
                              </FormFeedback>
                            ) : null}
                          </div>

                          <Row>
                            <Col>
                              <div className="form-check">
                                <input type="checkbox" className="form-check-input" id="customControlInline" />
                                <label className="form-label form-check-label" htmlFor="customControlInline">
                                  Remember me
                                </label>
                              </div>
                            </Col>
                            <Col className="col-7">
                              <div className="text-md-end mt-3 mt-md-0">
                                <Link to="/auth-recoverpw" className="text-muted">
                                  <i className="mdi mdi-lock"></i> Forgot your password?
                                </Link>
                              </div>
                            </Col>
                          </Row>
                          <div className="d-grid mt-4">
                            <button className="btn btn-primary waves-effect waves-light" type="submit">
                              Log In
                            </button>
                          </div>
                          <div className="mt-4 text-center">
                            <h5 className="font-size-14 mb-3">Sign in with</h5>

                            <ul className="list-inline">
                              <li className="list-inline-item">
                                <FacebookLogin
                                  appId={facebook.APP_ID}
                                  autoLoad={false}
                                  callback={facebookResponse}
                                  render={(renderProps) => (
                                    <Link to="#" className="social-list-item bg-primary text-white border-primary" onClick={renderProps.onClick}>
                                      <i className="mdi mdi-facebook" />
                                    </Link>
                                  )}
                                />
                              </li>

                              <li className="list-inline-item">
                                <GoogleLogin
                                  clientId={google.CLIENT_ID}
                                  render={(renderProps) => (
                                    <Link to="#" className="social-list-item bg-danger text-white border-danger" onClick={renderProps.onClick}>
                                      <i className="mdi mdi-google" />
                                    </Link>
                                  )}
                                  onSuccess={googleResponse}
                                  onFailure={() => {}}
                                />
                              </li>
                            </ul>
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p className="text-white-50">
                  Don't have an account ?{' '}
                  <Link to="/register" className="fw-medium text-primary">
                    {' '}
                    Register{' '}
                  </Link>{' '}
                </p>
                <p className="text-white-50">
                  © {new Date().getFullYear()} LEGIHUB. Crafted with <i className="mdi mdi-heart text-danger"></i> by Themesdesign
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Login);

Login.propTypes = {
  history: PropTypes.object,
};
