import React from 'react'
import { Toast, ToastHeader, ToastBody } from 'reactstrap';
import logoLegi from "../../../assets/images/logo_legihub_test.png";

const ToastComponent = (props) => {
    const {position,handleToast,hasOpen,message} = props
    const positionToast = {
        leftBottom:"position-fixed bottom-0 end-0 p-3",
        leftTop:"position-fixed top-0 end-0 p-3",
    }
  return (
    <div className={`${positionToast[position]}`} style={{ zIndex: '1005' }}>
                        <Toast isOpen={hasOpen}>
                <ToastHeader toggle={handleToast}>
                <img src={logoLegi} alt="" className="me-2" height="18" />
                </ToastHeader>
                <ToastBody color="primary">
                    {message}
                </ToastBody>
              </Toast>
                      </div>
  )
}

export default ToastComponent