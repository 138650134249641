const Icon = {
    watch : ( <div
        style={{ display: "grid", placeItems: "center", height: "50vh" }}
      >
        <svg
          width="50"
          height="50"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm0,20a9,9,0,1,1,9-9A9,9,0,0,1,12,21Z" />
          <rect x="11" y="6" rx="1" width="2" height="7">
            <animateTransform
              attributeName="transform"
              type="rotate"
              dur="9s"
              values="0 12 12;360 12 12"
              repeatCount="indefinite"
            />
          </rect>
          <rect x="11" y="11" rx="1" width="2" height="9">
            <animateTransform
              attributeName="transform"
              type="rotate"
              dur="0.75s"
              values="0 12 12;360 12 12"
              repeatCount="indefinite"
            />
          </rect>
        </svg>
      </div>),
      dot : (<svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        style={{ fill: "white" }}
      >
        <circle cx="4" cy="12" r="3" opacity="1">
          <animate
            id="spinner_qYjJ"
            begin="0;spinner_t4KZ.end-0.25s"
            attributeName="opacity"
            dur="0.75s"
            values="1;.2"
            fill="freeze"
          />
        </circle>
        <circle cx="12" cy="12" r="3" opacity=".4">
          <animate
            begin="spinner_qYjJ.begin+0.15s"
            attributeName="opacity"
            dur="0.75s"
            values="1;.2"
            fill="freeze"
          />
        </circle>
        <circle cx="20" cy="12" r="3" opacity=".3">
          <animate
            id="spinner_t4KZ"
            begin="spinner_qYjJ.begin+0.3s"
            attributeName="opacity"
            dur="0.75s"
            values="1;.2"
            fill="freeze"
          />
        </circle>
      </svg>)
}
export default Icon