 
import React, { Component } from 'react';
import Contrat from "../Contrat"; 
 
const ProtectionDonnees  = () => {
    return (
      <div>
         <Contrat data = "Politique interne de protection des données"/>
      </div>
    );
  };
 
export default ProtectionDonnees;

 
 
 