import React, { useState } from "react";
import { Modal } from "reactstrap";
import RadioSwitch from "./radio-switch";
import InputComponent from "./input-component";
import CheckBoxSwitch from "./checkBox-switch";
import SelectComponent from "./select-component";
import DateComponent from "./date-component";

const Index = (props) => {
  const {
    title,
    InputValue,
    OnUpdateValue,
    parentKey,
    keyElement,
    typeInput,
    typeValue,
    radiOption,
  } = props;

  const [modalToogle, setModalToogle] = useState(false);
  const [handleInputValue, setHandleInputValue] = useState(InputValue);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const toggleModal = () => {
    setModalToogle(!modalToogle);
  };

  const handleSwitchHooks = {
    checkbox: (e) => {
      setHandleInputValue(e);
    },
    radio: (e) => {
      if (radiOption) {
        setHandleInputValue(e.input);
        setBtnDisabled(e.btnDisabled);
      } else setHandleInputValue(e);
    },
    select: (e) => {
      setHandleInputValue(e);
    },
    date: (e) => {
      setHandleInputValue(e);
    },
    default: (e) => {
      setHandleInputValue(e.value);
      setBtnDisabled(e.btnDisabled);
    },
  };
  const handleSwitchComponents = {
    checkbox: (
      <CheckBoxSwitch
        checkBoxValues={typeValue}
        defaultValue={InputValue}
        onCheckBoxChange={handleSwitchHooks[typeInput]}
      />
    ),
    radio: (
      <RadioSwitch
        radioValue={typeValue}
        defaultValue={InputValue}
        onRadioChange={handleSwitchHooks[typeInput]}
        radiOption={radiOption}
      />
    ),
    select: (
      <SelectComponent
        defaultValue={InputValue}
        onSelectChange={handleSwitchHooks[typeInput]}
      />
    ),
    date: (
      <DateComponent
        defaultValue={InputValue}
        onDateChange={handleSwitchHooks[typeInput]}
      />
    ),
    default: (
      <InputComponent
        title={title}
        inputValue={InputValue}
        onInputChange={handleSwitchHooks[typeInput]}
      />
    ),
  };

  return (
    <div>
      <span
        id={`${parentKey}-${keyElement}`}
        style={{ cursor: "pointer" }}
        data-bs-toggle="modal"
        onClick={() => {
          toggleModal();
        }}
      >
        <i
          className="fas fa-edit font-size-16"
          style={{ cursor: "pointer", fontWeight: 100 }}
        ></i>
      </span>

      <div>
        <Modal
          isOpen={modalToogle}
          toggle={() => {
            toggleModal();
          }}
        >
          <div className="modal-header">
            <h5 className="modal-title">{`Modification`} </h5>
            <button
              type="button"
              className="btn-close"
              onClick={() => {
                setModalToogle(false);
              }}
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <form>
              <div className="mb-3">
                <label htmlFor="message-text" className="col-form-label">
                  {title}:
                </label>

                {handleSwitchComponents[typeInput]}
              </div>
            </form>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
              onClick={() => {
                setModalToogle(false);
              }}
            >
              Fermer
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => {
                setModalToogle(!modalToogle);
                OnUpdateValue(handleInputValue, parentKey, keyElement);
              }}
              disabled={btnDisabled}
            >
              Modifier
            </button>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default Index;
