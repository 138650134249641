import React, { useState } from "react";
import { Row, Card, CardBody } from "reactstrap";
import UpdateCompany from "../../common-component/update-service";

const MainBusiness = (props) => {
  const { title, mainBusinessData, onUpdateMainBusiness } = props;
  const [isHovered, setIsHovered] = useState({
    hoverState1: false,
    hoverState2: false,
    hoverState3: false,
    hoverState4: false,
  });
  const handleChangeData = (result, parentKey, keyElement) => {
    const getResult = typeof result === "string" ? result.trim() : result;
    if (keyElement) mainBusinessData[parentKey][keyElement] = getResult;
    else mainBusinessData[parentKey] = getResult;
    const {
      $collectionId,
      $createdAt,
      $databaseId,
      $permissions,
      $updatedAt,
      $id,
      ...rest
    } = mainBusinessData;
    onUpdateMainBusiness($id, rest);
  };
  return (
    <>
      <Row>
        <Card>
          <CardBody>
            <h4 className="card-title mb-5 text-primary">{title}</h4>
            <dl className="row mb-0">
              <dt className="col-sm-6">
                <ul className="verti-timeline list-unstyled">
                  <li className="event-list">
                    <div>
                      <span
                        style={{
                          fontSize: "18px",
                          margin: "0 0 7px 0",
                          textDecoration: "underline",
                          fontWeight: "normal",
                        }}
                      >
                        Intitulé
                      </span>
                      <span
                        className="verti-timeline list-unstyled"
                        style={{ border: "none" }}
                        onMouseEnter={() =>
                          setIsHovered((prev) => ({
                            ...prev,
                            hoverState1: true,
                          }))
                        }
                        onMouseLeave={() =>
                          setIsHovered((prev) => ({
                            ...prev,
                            hoverState1: false,
                          }))
                        }
                      >
                        <div
                          style={{
                            fontSize: "14px",
                            fontWeight: "normal",
                          }}
                          className="event-list"
                        >
                          <div
                            style={{
                              backgroundColor: "#e6e2e2a1",
                              padding: "16px",
                              borderRadius: "12px",
                              marginTop: "5px",
                              justifyContent: "space-between",
                            }}
                            className="d-flex"
                          >
                            <span style={{ fontSize: "14px" }}>
                              {mainBusinessData.company_activity}
                            </span>
                            {isHovered.hoverState1 && (
                              <UpdateCompany
                                typeInput="default"
                                title="Intitulé"
                                InputValue={mainBusinessData.company_activity}
                                parentKey="company_activity"
                                OnUpdateValue={handleChangeData}
                              />
                            )}
                          </div>
                        </div>
                      </span>
                    </div>
                  </li>
                  <li className="event-list">
                    <div>
                      <span
                        style={{
                          fontSize: "15px",
                          margin: "0 0 7px 0",
                          textDecoration: "underline",
                          fontWeight: "normal",
                        }}
                      >
                        Description
                      </span>
                      <span
                        className="verti-timeline list-unstyled"
                        style={{ border: "none" }}
                        onMouseEnter={() =>
                          setIsHovered((prev) => ({
                            ...prev,
                            hoverState2: true,
                          }))
                        }
                        onMouseLeave={() =>
                          setIsHovered((prev) => ({
                            ...prev,
                            hoverState2: false,
                          }))
                        }
                      >
                        <div
                          style={{
                            fontSize: "14px",
                            fontWeight: "normal",
                          }}
                          className="event-list"
                        >
                          <div
                            style={{
                              backgroundColor: "#e6e2e2a1",
                              padding: "16px",
                              borderRadius: "12px",
                              marginTop: "5px",
                              justifyContent: "space-between",
                            }}
                            className="d-flex"
                          >
                            <span style={{ fontSize: "14px" }}>
                              {mainBusinessData.company_activity_description}
                            </span>
                            {isHovered.hoverState2 && (
                              <UpdateCompany
                                typeInput="default"
                                title="Description"
                                InputValue={
                                  mainBusinessData.company_activity_description
                                }
                                parentKey="company_activity_description"
                                OnUpdateValue={handleChangeData}
                              />
                            )}
                          </div>
                        </div>
                      </span>
                    </div>
                  </li>
                </ul>
              </dt>
              {/* DD */}
              <dd className="col-sm-6">
                <ul className="verti-timeline list-unstyled">
                  <li className="event-list">
                    <div>
                      <span
                        style={{
                          fontSize: "15px",
                          margin: "0 0 7px 0",
                          textDecoration: "underline",
                          fontWeight: "normal",
                        }}
                      >
                        Type d'activité
                      </span>
                      <span
                        className="verti-timeline list-unstyled"
                        style={{ border: "none" }}
                        onMouseEnter={() =>
                          setIsHovered((prev) => ({
                            ...prev,
                            hoverState4: true,
                          }))
                        }
                        onMouseLeave={() =>
                          setIsHovered((prev) => ({
                            ...prev,
                            hoverState4: false,
                          }))
                        }
                      >
                        <div
                          style={{
                            fontSize: "14px",
                            fontWeight: "normal",
                          }}
                          className="event-list"
                        >
                          <div
                            style={{
                              backgroundColor: "#e6e2e2a1",
                              padding: "16px",
                              borderRadius: "12px",
                              marginTop: "5px",
                              justifyContent: "space-between",
                            }}
                            className="d-flex"
                          >
                            <span style={{ fontSize: "14px" }}>
                              {mainBusinessData.company_activity_type}
                            </span>
                            {isHovered.hoverState4 && (
                              <UpdateCompany
                                typeInput="default"
                                title="Description"
                                InputValue={
                                  mainBusinessData.company_activity_type
                                }
                                parentKey="company_activity_type"
                                OnUpdateValue={handleChangeData}
                              />
                            )}
                          </div>
                        </div>
                      </span>
                    </div>
                  </li>
                </ul>
              </dd>
            </dl>
          </CardBody>
        </Card>
      </Row>
    </>
  );
};

export default MainBusiness;
