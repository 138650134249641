import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';

// Login Redux States
import { LOGIN_USER, LOGOUT_USER, SOCIAL_LOGIN } from './actionTypes';
import { apiError, loginSuccess, logoutUserSuccess } from './actions';

//Include Both Helper File with needed methods
import { getFirebaseBackend } from '../../../helpers/firebase_helper';
import { postFakeLogin, postJwtLogin, postSocialLogin } from '../../../helpers/fakebackend_helper';
import { logoutUserSession, createEmailSessionPromise, getUser} from '../../../helpers/api_helper';

const fireBaseBackend = getFirebaseBackend();

function* loginUser({ payload: { user, history } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === 'firebase') {
      const response = yield call(fireBaseBackend.loginUser, user.email, user.password);
      yield put(loginSuccess(response));
    } else if (process.env.REACT_APP_DEFAULTAUTH === 'jwt') {
      const response = yield call(postJwtLogin, {
        email: user.email,
        password: user.password,
      });
      localStorage.setItem('authUser', JSON.stringify(response));
      yield put(loginSuccess(response));
    } else if (process.env.REACT_APP_DEFAULTAUTH === 'fake') {
      const response = yield call(postFakeLogin, {
        email: user.email,
        password: user.password,
      });
      localStorage.setItem('authUser', JSON.stringify(response));
      yield put(loginSuccess(response));
    } else if (process.env.REACT_APP_DEFAULTAUTH === 'api') {
      const response = yield call(createEmailSessionPromise, user.email, user.password);
      localStorage.setItem('authUser', JSON.stringify(response));
      yield put(loginSuccess(response));

      localStorage.setItem('authUser', JSON.stringify(response));
      localStorage.setItem('user', JSON.stringify(response));

      getUser().then(function (userData) {

        if (userData.prefs["owner"]) {
          let logData = {
            eventCreatedAt: response.$createdAt,
            author_id: userData.$id,
            company_id: userData.$id,
            countryCode: response.countryCode,
            countryName: response.countryName,
            Ip: response.ip,
            osName: response.osName,
            osVersion: response.osVersion,
            clientType: response.clientType,
            clientName: response.clientName,
            deviceName: response.deviceName,
            deviceBrand: response.deviceBrand,
            deviceModel: response.deviceModel,
          };
          let data = {
            event: "session_created", author_id: userData.$id, company_id: userData.$id, log_data: JSON.stringify(logData),
          };
          // createLog(data);
        }
        // else {
        //   getEmployeeLink(userData.$id).then(employeeLink => {
        //     console.log("employee link: ", employeeLink);
        //     if (employeeLink && employeeLink.length > 0) {
        //       if (employeeLink[0].company_id) { // poursuivre uniquement si la valeur de employeeLink[0].company_id est définie
        //         let logData = {
        //           eventCreatedAt: response.$createdAt,
        //           author_id: userData.$id,
        //           company_id: employeeLink[0].company_id,
        //           countryCode: response.countryCode,
        //           countryName: response.countryName,
        //           Ip: response.ip,
        //           osName: response.osName,
        //           osVersion: response.osVersion,
        //           clientType: response.clientType,
        //           clientName: response.clientName,
        //           deviceName: response.deviceName,
        //           deviceBrand: response.deviceBrand,
        //           deviceModel: response.deviceModel,
        //         };
        //         let data = {
        //           event: "session_created", author_id: userData.$id, company_id: employeeLink[0].company_id, log_data: JSON.stringify(logData),
        //         };

        //         createLog(data);
        //       }
        //     }
        //   });
        // }



        if (userData.prefs["finished_onbording"]) {
          history('/dashboard');
        } else {
          history('/dashboard');
        }
      });


    }


  } catch (error) {
    if (process.env.REACT_APP_DEFAULTAUTH === 'api') {
      let message;
      switch (error.code) {
        case 500:
          message = 'Erreur interne du serveur';
          break;
        case 401:
          message = 'E-mail ou mot de passe incorrect';
          break;
        case 409:
          message = 'Un utilisateur avec le même identifiant, email ou téléphone existe déjà';
          break;
        default:
          message = error.message;
      }
      yield put(apiError(message));
    } else {
      yield put(apiError(error));
    }
  }
}

function* logoutUser() {
  try {
    localStorage.removeItem('authUser');
    localStorage.removeItem('companyId');
    localStorage.removeItem('boxes');
    localStorage.removeItem('user');
    if (process.env.REACT_APP_DEFAULTAUTH === 'firebase') {
      const response = yield call(fireBaseBackend.logout);
      yield put(logoutUserSuccess(LOGOUT_USER, response));
    }
    if (process.env.REACT_APP_DEFAULTAUTH === 'api') {
      const response = yield call(logoutUserSession);
      yield put(logoutUserSuccess(LOGOUT_USER, true));
      localStorage.removeItem('authUser');
    } else {
      yield put(logoutUserSuccess(LOGOUT_USER, true));
    }
  } catch (error) {
    yield put(apiError(LOGOUT_USER, error));
    yield put(logoutUserSuccess(LOGOUT_USER, true));
  }
}

function* socialLogin({ payload: { data, history, type } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === 'firebase') {
      const fireBaseBackend = getFirebaseBackend();
      const response = yield call(fireBaseBackend.socialLoginUser, data, type);
      localStorage.setItem('authUser', JSON.stringify(response));
      yield put(loginSuccess(response));
    } else {
      const response = yield call(postSocialLogin, data);
      localStorage.setItem('authUser', JSON.stringify(response));
      yield put(loginSuccess(response));
    }
    history('/dashboard');
  } catch (error) {
    yield put(apiError(error));
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser);
  yield takeLatest(SOCIAL_LOGIN, socialLogin);
  yield takeEvery(LOGOUT_USER, logoutUser);
}

export default authSaga;
