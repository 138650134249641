import React, { useState } from "react";
import convertDate from "../../../Utility/hooks/dateConvert";
import UpdateContrat from "../../../common-component/update-service";
import StaticData from "../../../Utility/hooks/static";

const DTComponent = (props) => {
  const { data, onHandleDtComponentData } = props;
  const [isHovered, setIsHovered] = useState({
    hoverState1: false,
    hoverState2: false,
    hoverState3: false,
    hoverState4: false,
    hoverState5: false,
  });
  const handleChangeData = async (result, parentKey, keyElement) => {
    const getResult = typeof result === "string" ? result.trim() : result;
    if (keyElement) data[parentKey][keyElement] = getResult;
    else data[parentKey] = getResult;

    const {
      $collectionId,
      $createdAt,
      $databaseId,
      $permissions,
      $updatedAt,
      $id,
      ...rest
    } = data;
    if (rest.employee_id !== null) {
      rest.employee_id.employee_hiring_date =
        rest.employee_id.employee_hiring_date.toString();
    }
    if (rest.employee_id === undefined) {
      onHandleDtComponentData($id, rest);
    }
    onHandleDtComponentData($id, rest);
  };

  return (
    <dt className="col-sm-6">
      <ul className="verti-timeline list-unstyled">
        {data.contrat_type !== "Rupture Période d'Essai" &&
          data.contrat_type !== "Renouvellement Période d'Essai" &&
          data.contrat_type !== "Modèle de Règlement Intérieur" && (
            <li className="event-list">
              <div>
                <span
                  style={{
                    fontSize: "18px",
                    margin: "0 0 7px 0",
                    textDecoration: "underline",
                    fontWeight: "normal",
                  }}
                >
                  Effectif de votre société
                </span>
                <span
                  className="verti-timeline list-unstyled"
                  style={{ border: "none" }}
                  onMouseEnter={() =>
                    setIsHovered((prev) => ({ ...prev, hoverState1: true }))
                  }
                  onMouseLeave={() =>
                    setIsHovered((prev) => ({ ...prev, hoverState1: false }))
                  }
                >
                  <div
                    style={{
                      fontSize: "14px",
                      fontWeight: "normal",
                    }}
                    className="event-list"
                  >
                    <div
                      style={{
                        backgroundColor: "#e6e2e2a1",
                        padding: "16px",
                        borderRadius: "12px",
                        marginTop: "5px",
                        justifyContent: "space-between",
                      }}
                      className="d-flex"
                    >
                      <span>
                        {data.company_id === null ||
                        data.company_id === undefined
                          ? null
                          : data.company_id.company_staff_managment === true
                          ? StaticData.Radio.CompanyWorkforce.at(0).name
                          : StaticData.Radio.CompanyWorkforce.at(1).name}
                      </span>
                      {isHovered.hoverState1 && (
                        <UpdateContrat
                          title="Effectif de votre société"
                          InputValue={data.company_id.company_staff_managment}
                          parentKey="company_id"
                          keyElement="company_staff_managment"
                          OnUpdateValue={handleChangeData}
                          typeInput="radio"
                          typeValue={StaticData.Radio.CompanyWorkforce}
                        />
                      )}
                    </div>
                  </div>
                </span>
              </div>
            </li>
          )}

        {data.contrat_type !== "Modèle de Règlement Intérieur" && (
          <li className="event-list">
            <div>
              <span
                style={{
                  fontSize: "18px",
                  margin: "0 0 7px 0",
                  textDecoration: "underline",
                  fontWeight: "normal",
                }}
              >
                Date d'embauche
              </span>
              <span
                className="verti-timeline list-unstyled"
                style={{ border: "none" }}
                onMouseEnter={() =>
                  setIsHovered((prev) => ({ ...prev, hoverState2: true }))
                }
                onMouseLeave={() =>
                  setIsHovered((prev) => ({ ...prev, hoverState2: false }))
                }
              >
                <div
                  style={{
                    fontSize: "14px",
                    fontWeight: "normal",
                  }}
                  className="event-list"
                >
                  <div
                    style={{
                      backgroundColor: "#e6e2e2a1",
                      padding: "16px",
                      borderRadius: "12px",
                      marginTop: "5px",
                      justifyContent: "space-between",
                    }}
                    className="d-flex"
                  >
                    <span>
                      {data.employee_id === null ||
                      data.employee_id === undefined
                        ? null
                        : convertDate.convertMillisecondToDate(
                            data.employee_id.employee_hiring_date
                          )}
                    </span>
                    {isHovered.hoverState2 && (
                      <UpdateContrat
                        typeInput="date"
                        title="Date d'embauche"
                        InputValue={data.employee_id.employee_hiring_date}
                        parentKey="employee_id"
                        keyElement="employee_hiring_date"
                        OnUpdateValue={handleChangeData}
                      />
                    )}
                  </div>
                </div>
              </span>
            </div>
          </li>
        )}

        {data.contrat_type !== "Rupture Période d'Essai" &&
          data.contrat_type !== "Renouvellement Période d'Essai" &&
          data.contrat_type !== "Modèle de Règlement Intérieur" && (
            <li className="event-list">
              <div>
                <span
                  style={{
                    fontSize: "18px",
                    margin: "0 0 7px 0",
                    textDecoration: "underline",
                    fontWeight: "normal",
                  }}
                >
                  La ville de déclaration
                </span>
                <span
                  className="verti-timeline list-unstyled"
                  style={{ border: "none" }}
                  onMouseEnter={() =>
                    setIsHovered((prev) => ({ ...prev, hoverState3: true }))
                  }
                  onMouseLeave={() =>
                    setIsHovered((prev) => ({ ...prev, hoverState3: false }))
                  }
                >
                  <div
                    style={{
                      fontSize: "14px",
                      fontWeight: "normal",
                    }}
                    className="event-list"
                  >
                    <div
                      style={{
                        backgroundColor: "#e6e2e2a1",
                        padding: "16px",
                        borderRadius: "12px",
                        marginTop: "5px",
                        justifyContent: "space-between",
                      }}
                      className="d-flex"
                    >
                      <span>
                        {data.employee_id === null ||
                        data.employee_id === undefined
                          ? null
                          : data.employee_id.employee_hiring_city}
                      </span>
                      {isHovered.hoverState3 && (
                        <UpdateContrat
                          title="La ville de déclaration"
                          InputValue={data.employee_id.employee_hiring_city}
                          parentKey="employee_id"
                          keyElement="employee_hiring_city"
                          OnUpdateValue={handleChangeData}
                          typeInput="default"
                        />
                      )}
                    </div>
                  </div>
                </span>
              </div>
            </li>
          )}

        {data.contrat_type !== "CDD" &&
          data.contrat_type !== "Rupture Période d'Essai" &&
          data.contrat_type !== "Promesse d'embauche" &&
          data.contrat_type !== "Renouvellement Période d'Essai" &&
          data.contrat_type !== "CDI" && (
            <>
              <li className="event-list">
                <div>
                  <span
                    style={{
                      fontSize: "18px",
                      margin: "0 0 7px 0",
                      textDecoration: "underline",
                      fontWeight: "normal",
                    }}
                  >
                    Horaires d'ouverture de la société
                  </span>
                  <span
                    className="verti-timeline list-unstyled"
                    style={{ border: "none" }}
                    onMouseEnter={() =>
                      setIsHovered((prev) => ({ ...prev, hoverState4: true }))
                    }
                    onMouseLeave={() =>
                      setIsHovered((prev) => ({ ...prev, hoverState4: false }))
                    }
                  >
                    <div
                      style={{
                        fontSize: "14px",
                        fontWeight: "normal",
                      }}
                      className="event-list"
                    >
                      <div
                        style={{
                          backgroundColor: "#e6e2e2a1",
                          padding: "16px",
                          borderRadius: "12px",
                          marginTop: "5px",
                          justifyContent: "space-between",
                        }}
                        className="d-flex"
                      >
                        <span>
                          {data.company_id === null ||
                          data.company_id === undefined
                            ? null
                            : data.company_id.company_open_time}
                        </span>
                        {isHovered.hoverState4 && (
                          <UpdateContrat
                            title="Horaires d'ouverture de la société"
                            InputValue={data.company_id.company_open_time}
                            parentKey="company_id"
                            keyElement="company_open_time"
                            OnUpdateValue={handleChangeData}
                            typeInput="default"
                          />
                        )}
                      </div>
                    </div>
                  </span>
                </div>
              </li>
              <li className="event-list">
                <div>
                  <span
                    style={{
                      fontSize: "18px",
                      margin: "0 0 7px 0",
                      textDecoration: "underline",
                      fontWeight: "normal",
                    }}
                  >
                    Nombre maximal de jours de mise à pieds disciplinaire
                  </span>
                  <span
                    className="verti-timeline list-unstyled"
                    style={{ border: "none" }}
                    onMouseEnter={() =>
                      setIsHovered((prev) => ({ ...prev, hoverState5: true }))
                    }
                    onMouseLeave={() =>
                      setIsHovered((prev) => ({ ...prev, hoverState5: false }))
                    }
                  >
                    <div
                      style={{
                        fontSize: "14px",
                        fontWeight: "normal",
                      }}
                      className="event-list"
                    >
                      <div
                        style={{
                          backgroundColor: "#e6e2e2a1",
                          padding: "16px",
                          borderRadius: "12px",
                          marginTop: "5px",
                          justifyContent: "space-between",
                        }}
                        className="d-flex"
                      >
                        <span>
                          {data.company_id === null ||
                          data.company_id === undefined
                            ? null
                            : data.company_id.company_layoff_days}
                        </span>
                        {isHovered.hoverState5 && (
                          <UpdateContrat
                            title="Nombre maximal de jours de mise à pieds
                    disciplinaire"
                            InputValue={data.company_id.company_layoff_days}
                            parentKey="company_id"
                            keyElement="company_layoff_days"
                            OnUpdateValue={handleChangeData}
                            typeInput="default"
                          />
                        )}
                      </div>
                    </div>
                  </span>
                </div>
              </li>
            </>
          )}
      </ul>
    </dt>
  );
};

export default DTComponent;
