import React from "react";
import UpdateContrat from "../../../common-component/update-service";
import StaticData from "../../../Utility/hooks/static";

const DdComponent = (props) => {
  const { data, onHandleDdComponentData } = props;

  const handleChangeData = (result, parentKey, keyElement) => {
    const getResult = typeof result === "string" ? result.trim() : result;
    if (keyElement) data[parentKey][keyElement] = getResult;
    else data[parentKey] = getResult;
    const {
      $collectionId,
      $createdAt,
      $databaseId,
      $permissions,
      $updatedAt,
      $id,
      ...rest
    } = data;
    onHandleDdComponentData($id, rest);
  };

  return (
    <>
      {data.contrat_type !==
        "Note d'information à la destination des salariés" && (
        <dd className="col-sm-6">
          <ul className="verti-timeline list-unstyled">
            {data.contrat_type !== "Politique de gestion des cookies" && (
              <>
                {data.company_id.company_email === "" &&
                  data.company_id.company_dpo_email !== null && (
                    <li className="event-list">
                      <div>
                        <span
                          style={{
                            fontSize: "18px",
                            margin: "0 0 7px 0",
                            textDecoration: "underline",
                            fontWeight: "normal",
                          }}
                        >
                          Adresse mail du DPO de l'entreprise
                        </span>
                        <span
                          className="verti-timeline list-unstyled"
                          style={{ border: "none" }}
                        >
                          <div
                            style={{
                              fontSize: "14px",
                              fontWeight: "normal",
                            }}
                            className="event-list"
                          >
                            <div
                              style={{
                                backgroundColor: "#e6e2e2a1",
                                padding: "16px",
                                borderRadius: "12px",
                                marginTop: "5px",
                                justifyContent: "space-between",
                              }}
                              className="d-flex"
                            >
                              <span>
                                {data.company_id === null ||
                                data.company_id === undefined
                                  ? null
                                  : data.company_id.company_dpo_email}
                              </span>
                              <UpdateContrat
                                title="Adresse mail du DPO de l'entreprise"
                                InputValue={data.company_id.company_dpo_email}
                                parentKey="company_id"
                                keyElement="company_dpo_email"
                                OnUpdateValue={handleChangeData}
                                typeInput="default"
                              />
                            </div>
                          </div>
                        </span>
                      </div>
                    </li>
                  )}

                {data.contrat_type !==
                  "Politique interne de protection des données" && (
                  <li className="event-list">
                    <div>
                      <span
                        style={{
                          fontSize: "18px",
                          margin: "0 0 7px 0",
                          textDecoration: "underline",
                          fontWeight: "normal",
                        }}
                      >
                        Adresse postal de l'entreprise
                      </span>
                      <span
                        className="verti-timeline list-unstyled"
                        style={{ border: "none" }}
                      >
                        <div
                          style={{
                            fontSize: "14px",
                            fontWeight: "normal",
                          }}
                          className="event-list"
                        >
                          <div
                            style={{
                              backgroundColor: "#e6e2e2a1",
                              padding: "16px",
                              borderRadius: "12px",
                              marginTop: "5px",
                              justifyContent: "space-between",
                            }}
                            className="d-flex"
                          >
                            <span>
                              {data.company_id === null ||
                              data.company_id === undefined
                                ? null
                                : data.company_id.company_mailing_address}
                            </span>
                            <UpdateContrat
                              title="Adresse postal de l'entreprise"
                              InputValue={
                                data.company_id.company_mailing_address
                              }
                              parentKey="company_id"
                              keyElement="company_mailing_address"
                              OnUpdateValue={handleChangeData}
                              typeInput="default"
                            />
                          </div>
                          {/* company_zipcode  */}
                        </div>
                      </span>
                    </div>
                  </li>
                )}
              </>
            )}

            {data.contrat_type !== "Politique de confidentialité" && (
              <>
                {data.contrat_type !==
                  "Politique interne de protection des données" && (
                  <li className="event-list">
                    <div>
                      <div
                        style={{
                          fontSize: "18px",
                          margin: "0 0 7px 0",
                          textDecoration: "underline",
                          fontWeight: "normal",
                          justifyContent: "space-between",
                        }}
                        className="d-flex"
                      >
                        <span>Liste des cookies</span>
                        <UpdateContrat
                          title="Liste des cookies"
                          InputValue={data.cookies_managment}
                          parentKey="cookies_managment"
                          OnUpdateValue={handleChangeData}
                          typeInput="checkbox"
                          typeValue={
                            data.cookies_managment.filter(
                              (element) =>
                                !StaticData.CheckBox.Cookies.includes(element)
                            )
                              ? StaticData.CheckBox.Cookies.concat(
                                  data.cookies_managment.filter(
                                    (element) =>
                                      !StaticData.CheckBox.Cookies.includes(
                                        element
                                      )
                                  )
                                )
                              : StaticData.CheckBox.Cookies
                          }
                        />
                      </div>
                      {data.cookies_managment === undefined ||
                      data.cookies_managment.length === 0 ? (
                        <div
                          className="verti-timeline list-unstyled"
                          style={{ border: "none" }}
                        >
                          <p
                            style={{
                              fontSize: "12px",
                              fontWeight: "normal",
                            }}
                            className="event-list"
                          >
                            Aucun cookie disponible
                          </p>
                        </div>
                      ) : (
                        <dl className="row mb-0">
                          {data.cookies_managment.map((val, index) => (
                            <React.Fragment key={index}>
                              {index < data.cookies_managment / 2 ? (
                                <dd className="col-sm-6">
                                  <span
                                    className="verti-timeline list-unstyled"
                                    style={{ border: "none" }}
                                  >
                                    <p
                                      style={{
                                        fontSize: "12px",
                                        fontWeight: "normal",
                                      }}
                                      className="event-list"
                                    >
                                      <div
                                        style={{
                                          backgroundColor: "#e6e2e2a1",
                                          padding: "16px",
                                          borderRadius: "12px",
                                          marginTop: "5px",
                                          fontSize: "14px",
                                        }}
                                      >
                                        - {val}
                                      </div>
                                    </p>
                                  </span>
                                </dd>
                              ) : (
                                <dt className="col-sm-6">
                                  <span
                                    className="verti-timeline list-unstyled"
                                    style={{ border: "none" }}
                                  >
                                    <p
                                      style={{
                                        fontSize: "12px",
                                        fontWeight: "normal",
                                      }}
                                      className="event-list"
                                    >
                                      <div
                                        style={{
                                          backgroundColor: "#e6e2e2a1",
                                          padding: "16px",
                                          borderRadius: "12px",
                                          marginTop: "5px",
                                          fontSize: "14px",
                                        }}
                                      >
                                        - {val}
                                      </div>
                                    </p>
                                  </span>
                                </dt>
                              )}
                            </React.Fragment>
                          ))}
                        </dl>
                      )}
                    </div>
                  </li>
                )}

                {data.contrat_type !== "Politique de gestion des cookies" && (
                  <>
                    <li className="event-list">
                      <div>
                        <div
                          style={{
                            fontSize: "18px",
                            margin: "0 0 7px 0",
                            textDecoration: "underline",
                            fontWeight: "normal",
                            justifyContent: "space-between",
                          }}
                          className="d-flex"
                        >
                          <span> Catégories de données traitées</span>

                          <UpdateContrat
                            title="Catégories de données traitées"
                            InputValue={data.company_id.company_data_category}
                            parentKey="company_id"
                            keyElement="company_data_category"
                            OnUpdateValue={handleChangeData}
                            typeInput="checkbox"
                            typeValue={
                              data.company_id.company_data_category.filter(
                                (element) =>
                                  !StaticData.CheckBox.Dataprocessed.includes(
                                    element
                                  )
                              )
                                ? StaticData.CheckBox.Dataprocessed.concat(
                                    data.company_id.company_data_category.filter(
                                      (element) =>
                                        !StaticData.CheckBox.Dataprocessed.includes(
                                          element
                                        )
                                    )
                                  )
                                : StaticData.CheckBox.Dataprocessed
                            }
                          />
                        </div>
                        <span
                          className="verti-timeline list-unstyled"
                          style={{ border: "none" }}
                        >
                          <div
                            style={{
                              fontSize: "14px",
                              fontWeight: "normal",
                            }}
                            className="event-list"
                          >
                            {data.company_id === null ||
                            data.company_id === undefined ? null : data
                                .company_id.company_data_category.length ===
                              0 ? null : (
                              <dl className="row mb-0">
                                {data.company_id.company_data_category.map(
                                  (val, index) => (
                                    <React.Fragment key={index}>
                                      {index <
                                      data.company_id.company_data_category /
                                        2 ? (
                                        <dd className="col-sm-6">
                                          <span
                                            className="verti-timeline list-unstyled"
                                            style={{ border: "none" }}
                                          >
                                            <div
                                              style={{
                                                fontSize: "12px",
                                                fontWeight: "normal",
                                              }}
                                              className="event-list"
                                            >
                                              <div
                                                style={{
                                                  backgroundColor: "#e6e2e2a1",
                                                  padding: "16px",
                                                  borderRadius: "12px",
                                                  marginTop: "5px",
                                                  fontSize: "14px",
                                                }}
                                              >
                                                - {val}
                                              </div>
                                            </div>
                                          </span>
                                        </dd>
                                      ) : (
                                        <dt className="col-sm-6">
                                          <span
                                            className="verti-timeline list-unstyled"
                                            style={{ border: "none" }}
                                          >
                                            <div
                                              style={{
                                                fontSize: "12px",
                                                fontWeight: "normal",
                                              }}
                                              className="event-list"
                                            >
                                              <div
                                                style={{
                                                  backgroundColor: "#e6e2e2a1",
                                                  padding: "16px",
                                                  borderRadius: "12px",
                                                  marginTop: "5px",
                                                  fontSize: "14px",
                                                }}
                                              >
                                                - {val}
                                              </div>
                                            </div>
                                          </span>
                                        </dt>
                                      )}
                                    </React.Fragment>
                                  )
                                )}
                              </dl>
                            )}
                          </div>
                        </span>
                      </div>
                    </li>
                    {data.contrat_type ===
                      "Politique interne de protection des données" && (
                      <>
                        <li className="event-list">
                          <div>
                            <span
                              style={{
                                fontSize: "18px",
                                margin: "0 0 7px 0",
                                textDecoration: "underline",
                                fontWeight: "normal",
                              }}
                            >
                              Destinataire des données
                            </span>
                            <span
                              className="verti-timeline list-unstyled"
                              style={{ border: "none" }}
                            >
                              <div
                                style={{
                                  fontSize: "14px",
                                  fontWeight: "normal",
                                }}
                                className="event-list"
                              >
                                <div
                                  style={{
                                    backgroundColor: "#e6e2e2a1",
                                    padding: "16px",
                                    borderRadius: "12px",
                                    marginTop: "5px",
                                    justifyContent: "space-between",
                                  }}
                                  className="d-flex"
                                >
                                  <sapn>
                                    {data.company_id === null ||
                                    data.company_id === undefined
                                      ? null
                                      : data.company_id.company_intended_data
                                      ? StaticData.Radio.DataRecipient.at(0)
                                          .name
                                      : StaticData.Radio.DataRecipient.at(1)
                                          .name}
                                  </sapn>
                                  <UpdateContrat
                                    title="Destinataire des données"
                                    InputValue={
                                      data.company_id.company_intended_data
                                    }
                                    parentKey="company_id"
                                    keyElement="company_intended_data"
                                    OnUpdateValue={handleChangeData}
                                    typeInput="radio"
                                    typeValue={StaticData.Radio.DataRecipient}
                                  />
                                </div>
                              </div>
                            </span>
                          </div>
                        </li>
                        <li className="event-list">
                          <div>
                            <span
                              style={{
                                fontSize: "18px",
                                margin: "0 0 7px 0",
                                textDecoration: "underline",
                                fontWeight: "normal",
                              }}
                            >
                              Flux transfrontières de données
                            </span>
                            <span
                              className="verti-timeline list-unstyled"
                              style={{ border: "none" }}
                            >
                              <div
                                style={{
                                  fontSize: "14px",
                                  fontWeight: "normal",
                                }}
                                className="event-list"
                              >
                                <div
                                  style={{
                                    backgroundColor: "#e6e2e2a1",
                                    padding: "16px",
                                    borderRadius: "12px",
                                    marginTop: "5px",
                                    justifyContent: "space-between",
                                  }}
                                  className="d-flex"
                                >
                                  <sapn>
                                    {data.company_id === null ||
                                    data.company_id === undefined
                                      ? null
                                      : data.company_id.company_data_flow
                                      ? StaticData.Radio.DataFlows.at(0).name
                                      : StaticData.Radio.DataFlows.at(1).name}
                                  </sapn>
                                  <UpdateContrat
                                    title="Destinataire des données"
                                    InputValue={
                                      data.company_id.company_data_flow
                                    }
                                    parentKey="company_id"
                                    keyElement="company_data_flow"
                                    OnUpdateValue={handleChangeData}
                                    typeInput="radio"
                                    typeValue={StaticData.Radio.DataFlows}
                                  />
                                </div>
                              </div>
                            </span>
                          </div>
                        </li>
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </ul>
        </dd>
      )}
    </>
  );
};

export default DdComponent;
