import React from "react";
import DDComponent from "./ddComponent";
import DTComponent from "./dtComponent";
import { Row, Card, CardBody } from "reactstrap";
const Index = (props) => {
  const { title, othersData,onUpdateOthersData } = props;

  return (
    <Row>
      <Card>
        <CardBody>
          <h4 className="card-title mb-5 text-primary">{title}</h4>
          <dl className="row mb-0">
            <DTComponent data={othersData} onHandleDtComponentData={onUpdateOthersData} />
            <DDComponent data={othersData} onHandleDdComponentData={onUpdateOthersData} />
          </dl>
        </CardBody>
      </Card>
    </Row>
  );
};

export default Index;
