import React from "react";
import convertDate from "../../../Utility/hooks/dateConvert";
import UpdateContrat from "../../../common-component/update-service";

const DDComponent = (props) => {
  const { data, onHandleDdComponentData } = props;

  const handleChangeData = (result, parentKey, keyElement) => {
    const getResult = typeof result === "string" ? result.trim() : result;
    if (keyElement) data[parentKey][keyElement] = getResult;
    else data[parentKey] = getResult;
    const {
      $collectionId,
      $createdAt,
      $databaseId,
      $permissions,
      $updatedAt,
      $id,
      ...rest
    } = data;
    rest.renoncement_date = rest.renoncement_date.toString();
    onHandleDdComponentData($id, rest);
  };
  return (
    <dd className="col-sm-6">
      <ul className="verti-timeline list-unstyled mt-8">
        {data.contrat_type !== "CDI" && (
          <li className="event-list">
            <div>
              <span
                style={{
                  fontSize: "18px",
                  margin: "0 0 7px 0",
                  textDecoration: "underline",
                  fontWeight: "normal",
                }}
              >
                Indemnité mensuelle du salarié
              </span>
              <span
                className="verti-timeline list-unstyled"
                style={{ border: "none" }}
              >
                <div
                  style={{
                    fontSize: "14px",
                    fontWeight: "normal",
                  }}
                  className="event-list"
                >
                  <div
                    style={{
                      backgroundColor: "#e6e2e2a1",
                      padding: "16px",
                      borderRadius: "12px",
                      marginTop: "5px",
                      justifyContent: "space-between",
                    }}
                    className="d-flex"
                  >
                    <span>
                      {data.employee_id === null ||
                      data.employee_id === undefined
                        ? null
                        : data.employee_id.employee_allowance}
                    </span>
                    <UpdateContrat
                      title="Indemnité mensuelle du salarié"
                      InputValue={data.employee_id.employee_allowance}
                      typeInput="default"
                      parentKey="employee_id"
                      keyElement="employee_allowance"
                      OnUpdateValue={handleChangeData}
                    />
                  </div>
                </div>
              </span>
            </div>
          </li>
        )}

        <li className="event-list">
          <div>
            <span
              style={{
                fontSize: "18px",
                margin: "0 0 7px 0",
                textDecoration: "underline",
                fontWeight: "normal",
              }}
            >
              Date limite de la décision de renonciation
            </span>
            <span
              className="verti-timeline list-unstyled"
              style={{ border: "none" }}
            >
              <div
                style={{
                  fontSize: "14px",
                  fontWeight: "normal",
                }}
                className="event-list"
              >
                <div
                  style={{
                    backgroundColor: "#e6e2e2a1",
                    padding: "16px",
                    borderRadius: "12px",
                    marginTop: "5px",
                    justifyContent: "space-between",
                  }}
                  className="d-flex"
                >
                  <span>
                    {data.company_id === null || data.company_id === undefined
                      ? null
                      : convertDate.convertMillisecondToDate(
                          data.renoncement_date
                        )}
                  </span>
                  <UpdateContrat
                    title="Date limite de la décision de renonciation"
                    InputValue={data.renoncement_date}
                    typeInput="date"
                    parentKey="renoncement_date"
                    OnUpdateValue={handleChangeData}
                  />
                </div>
              </div>
            </span>
          </div>
        </li>
        <li className="event-list">
          <div>
            <span
              style={{
                fontSize: "18px",
                margin: "0 0 7px 0",
                textDecoration: "underline",
                fontWeight: "normal",
              }}
            >
              Montant versé en cas de non-respect de la clause
            </span>
            <span
              className="verti-timeline list-unstyled"
              style={{ border: "none" }}
            >
              <div
                style={{
                  fontSize: "14px",
                  fontWeight: "normal",
                }}
                className="event-list"
              >
                <div
                  style={{
                    backgroundColor: "#e6e2e2a1",
                    padding: "16px",
                    borderRadius: "12px",
                    marginTop: "5px",
                    justifyContent: "space-between",
                  }}
                  className="d-flex"
                >
                  <span>
                    {data.company_id === null || data.company_id === undefined
                      ? null
                      : data.clause_refound}
                  </span>
                  <UpdateContrat
                    title="Montant versé en cas de non-respect de la clause"
                    InputValue={data.clause_refound}
                    typeInput="default"
                    parentKey="clause_refound"
                    OnUpdateValue={handleChangeData}
                  />
                </div>
              </div>
            </span>
          </div>
        </li>
      </ul>
    </dd>
  );
};

export default DDComponent;
