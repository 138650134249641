import React, { useEffect, useState } from "react";
import { Col, Row, Container } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { useLocation } from "react-router-dom";
import { appwriteDatabase, updateCompany } from "../../helpers/api_helper";
import SocialReason from "./components/social-reason";
import MainBusiness from "./components/main-business";
import President from "./components/president";
import Capital from "./components/capital";
import HeadOffice from "./components/headoffice";
import Partner from "./components/partner";
import Leader from "./components/leader";
import ToastComponent from "../common-component/update-service/customToast";
import Icon from "../Utility/hooks/component";
import ScrollToTopButton from "../common-component/scrollToTop";
import CompanyState from "./components/comapny-state";

const CompanyDetail = () => {
  const { state } = useLocation();
  const [company, setCompany] = useState({});
  const [taostNotification, setTaostNotification] = useState({
    update: false,
    fail: false,
  });
  const [collectData, setCollectData] = useState({ id: "", data: {} });
  const [loadBtn, setloadBtn] = useState(false);

  useEffect(() => {
    getCompany(state);
  }, []);

  async function getCompany(id) {
    appwriteDatabase.getDocument("legihub", "company", id).then(
      (response) => {
        console.log("company is", response);
        setCompany(response);
      },
      function (error) {
        console.log(error);
      }
    );
  }
  const handleDataForm = (id, data) => {
    if (id.length > 0) {
      setCollectData((prev) => ({
        ...prev,
        id:id,
        data: data,
      }));
    }
  };
  async function updateCompanyData() {
    try {
      setloadBtn(true)
      const response = await updateCompany(collectData.id,collectData.data);
      setCompany(response);
      setloadBtn(false)

      setTaostNotification((prev) => ({
        ...prev,
        update: true,
      }));
      setTimeout(() => {
        setTaostNotification((prev) => ({
          ...prev,
          update: false,
        }));
      }, 3000);
    } catch (error) {
      setTaostNotification((prev) => ({
        ...prev,
        fail: true,
      }));
      setTimeout(() => {
        setTaostNotification((prev) => ({
          ...prev,
          fail: false,
        }));
      }, 3000);
      setloadBtn(false)
    }
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          {Object.keys(company).length ? (
            <>
              <Breadcrumbs
                title="Company"
                breadcrumbItem={`${company.company_type}`}
              />
               <div className="d-flex justify-content-end mb-2">
               <button
                      type="button"
                      className="btn btn-success btn-rounded"
                      onClick={updateCompany}
                      disabled={collectData.id.length === 0}
                    >
                     {!loadBtn ? "Sauvegarder": Icon.dot} 
                    </button>
              </div>
              <Row>
                <Col lg={12}>
                  <SocialReason
                    title="Raison Social"
                    socialReasonData={company}
                    onUpdateSocialReason = {handleDataForm}
                  />
                  <MainBusiness
                    title="Activité principale"
                    mainBusinessData={company}
                    onUpdateMainBusiness = {handleDataForm}
                  />
                  <President title="Président" presidentData={company} onUpdatePresident = {handleDataForm}/>
                  <Leader title="Dirigeant" leaderData={company} onUpdateleader = {handleDataForm} />
                  <Partner title="Associé" partnerData={company} onUpdatePatner = {handleDataForm}/>
                  <Capital
                    title="Le capital de la société"
                    capitalData={company}
                    onUpdateCapital={handleDataForm}
                    
                  />
                  <HeadOffice title="Siège Social" headOfficeData={company} onUpdateHeadOffice={handleDataForm} />
                  <CompanyState title="Etat de la demande" companyStateData={company} onUpdateCompanyState={handleDataForm} />
                  <div className="d-flex justify-content-center mb-5 mt-5">
                    <button
                      type="button"
                      className="btn btn-success btn-rounded"
                      onClick={updateCompanyData}
                      disabled={collectData.id.length === 0}
                    >
                     {!loadBtn ? "Sauvegarder": Icon.dot} 
                    </button>
                  </div>
                </Col>
              </Row>
            </>
          ) : (
            Icon.watch
          )}
           {taostNotification.update && (
          <ToastComponent
            position="leftBottom"
            hasOpen={taostNotification.update}
            message={`Sauvegarde éffectuée avec succès`}
          />
        )}
        {taostNotification.fail && (
          <ToastComponent
            position="leftBottom"
            hasOpen={taostNotification.fail}
            message={`"Échec, veuillez réessayer."`}
          />
        )}
        </Container>
        <ScrollToTopButton/>
      </div>
    </React.Fragment>
  );
};

export default CompanyDetail;
