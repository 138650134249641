 
import React, { Component } from 'react';
import Contrat from "../Contrat"; 
 
const PGC  = () => {
    return (
      <div>
         <Contrat data = "Politique de gestion des cookies"/>
      </div>
    );
  };
 
export default PGC;
