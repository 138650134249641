import React from "react";
import convertDate from "../../../Utility/hooks/dateConvert";
import UpdateContrat from "../../../common-component/update-service";

const DdComponent = (props) => {
  const { data, onHandleDdComponentData } = props;

  const handleChangeData = (result, parentKey, keyElement) => {
    const getResult = typeof result === "string" ? result.trim() : result;
    if (keyElement) data[parentKey][keyElement] = getResult;
    else data[parentKey] = getResult;
    const {
      $collectionId,
      $createdAt,
      $databaseId,
      $permissions,
      $updatedAt,
      $id,
      ...rest
    } = data;
    onHandleDdComponentData($id, rest);
  };
  return (
    <>
      <dd className="col-sm-6">
        <ul className="verti-timeline list-unstyled">
          <li className="event-list">
            <div>
              <span
                style={{
                  fontSize: "18px",
                  margin: "0 0 7px 0",
                  textDecoration: "underline",
                  fontWeight: "normal",
                }}
              >
                Date de la convention
              </span>
              <span
                className="verti-timeline list-unstyled"
                style={{ border: "none" }}
              >
                <div
                  style={{
                    fontSize: "14px",
                    fontWeight: "normal",
                  }}
                  className="event-list"
                >
                  <div
                    style={{
                      backgroundColor: "#e6e2e2a1",
                      padding: "16px",
                      borderRadius: "12px",
                      marginTop: "5px",
                      justifyContent: "space-between",
                    }}
                    className="d-flex"
                  >
                    <span>
                      {data.company_id === null || data.company_id === undefined
                        ? null
                        : convertDate.convertMillisecondToDate(
                            data.convention_date
                          )}
                    </span>
                    <UpdateContrat
                      title="Date de la convention"
                      InputValue={data.convention_date}
                      parentKey="convention_date"
                      OnUpdateValue={handleChangeData}
                      typeInput="date"
                    />
                  </div>
                </div>
              </span>
            </div>
          </li>
        </ul>
      </dd>
    </>
  );
};

export default DdComponent;
