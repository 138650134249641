import axios from "axios";
import config from "../config";
import { Account, Client, Databases, Functions, ID, Query } from "appwrite";

// default
axios.defaults.baseURL = config.API_URL;

// content type
axios.defaults.headers.post["Content-Type"] = "application/json";

// intercepting to capture errors
axios.interceptors.response.use(
  function (response) {
    return response.data ? response.data : response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    let message;
    switch (error.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 401:
        message = "Invalid credentials";
        break;
      case 404:
        message = "Sorry! the data you are looking for could not be found";
        break;
      default:
        message = error.message || error;
    }
    return Promise.reject(message);
  }
);

/**
 * Sets the default authorization
 * @param {*} token
 */
const setAuthorization = (token) => {
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
};

class APIClient {
  /**
   * Fetches data from given url
   */
  get = (url, params) => {
    return axios.get(url, params);
  };

  /**
   * post given data to url
   */
  create = (url, data) => {
    return axios.post(url, data);
  };

  /**
   * Updates data
   */
  update = (url, data) => {
    return axios.put(url, data);
  };

  /**
   * Delete
   */
  delete = (url, config) => {
    return axios.delete(url, { ...config });
  };
}

const getLoggedinUser = () => {
  const user = localStorage.getItem("authUser");
  if (!user) {
    return null;
  } else {
    return JSON.parse(user);
  }
};
// Import the necessary modules from the Appwrite SDK.

// Create an Appwrite client for connecting to the server.
const appwriteClient = new Client();
const appwriteAccount = new Databases(appwriteClient);

// Assign the server's API endpoint and the project ID.
appwriteClient
  .setEndpoint("https://api.shopybase.com/v1")
  .setProject("legihub-dev");

export async function getContrats(props, limit, cursor, cursorBefore) {
  try {
    const queries = [
      Query.limit(limit),
      Query.equal("contrat_type", props),
      Query.orderDesc("$createdAt"),
    ];
    if (cursor) {
      if (cursorBefore) {
        queries.push(Query.cursorBefore(cursor));
      } else {
        queries.push(Query.cursorAfter(cursor));
      }
    }

    const response = await appwriteAccount.listDocuments(
      "legihub",
      "contrat",
      queries
    );
    console.log("Successfully fetched contrat info for :" + props, response);
    return response;
  } catch (e) {
    console.log("Failed to fetch contrat:", e);
    return undefined;
  }
}
export async function searchContrat(
  props,
  limit,
  cursor,
  cursorBefore,
  THE_TEXT_TO_SEARCH,
  field
) {
  try {
    const queries = [
      Query.limit(limit),
      Query.equal("contrat_type", props),
      Query.orderDesc("$createdAt"),
    ];
    if (cursor) {
      if (cursorBefore) {
        queries.push(Query.cursorBefore(cursor));
      } else {
        queries.push(Query.cursorAfter(cursor));
      }
    }

    if (field === "user_id") {
      queries.push(Query.equal(field, THE_TEXT_TO_SEARCH));
    } else {
      queries.push(Query.search(field, THE_TEXT_TO_SEARCH));
    }

    const response = await appwriteAccount.listDocuments(
      "legihub",
      "contrat",
      queries
    );
    console.log("Successfully fetched contrat info for :" + props, response);
    return response;
  } catch (e) {
    console.log("Failed to fetch contrat:", e);
    return undefined;
  }
}
export async function deleteContract(contract_id) {
  console.log({ contract_id });

  const promise = appwriteAccount.deleteDocument(
    "legihub",
    "contrat",
    contract_id
  );

  return promise.then(
    async function (response) {
      console.log("Contract deleted ", response);
      return response;
    },
    function (error) {
      console.log("Error deleting Contract", error);
      return null;
    }
  );
}

// ! Company Area
export async function getCompany(
  props,
  limit,
  cursor,
  cursorBefore,
  THE_TEXT_TO_SEARCH,
  field
) {

  try {
    const queries = [
      Query.limit(limit),
      Query.equal("company_type", props),
      Query.orderDesc("$createdAt"),
    ];
    if (cursor) {
      if (cursorBefore) {
        queries.push(Query.cursorBefore(cursor));
      } else {
        queries.push(Query.cursorAfter(cursor));
      }
    }
    if (THE_TEXT_TO_SEARCH) {
      queries.push(Query.search(field, THE_TEXT_TO_SEARCH));
    }
    const response = await appwriteAccount.listDocuments(
      "legihub",
      "company",
      queries
    );

    console.log("Successfully fetched company info for :" + props, response);
    return response;
  } catch (e) {
    console.log("Failed to fetch company:", e);
    return undefined;
  }
}

export async function deleteCompany(company_id) {
  console.log({ company_id });

  const promise = appwriteAccount.deleteDocument(
    "legihub",
    "company",
    company_id
  );

  return promise.then(
    async function (response) {
      console.log("company deleted ", response);
      return response;
    },
    function (error) {
      console.log("Error deleting company", error);
      return null;
    }
  );
}

// Establish the database to use via its ID. Then
// export the database object so that it can be
// imported by other parts of the React project.
async function updateCompany(companyID, data) {
  try {
    const response = await appwriteAccount.updateDocument(
      "legihub",
      "company",
      companyID,
      data
    );
    console.log("Successfully update company for :", response);
    return response;
  } catch (e) {
    console.log("Failed to fetch company:", e);
    return undefined;
  }
}
async function updateContrat(contractID, data) {
  try {
    const response = await appwriteAccount.updateDocument(
      "legihub",
      "contrat",
      contractID,
      data
    );
    console.log("Successfully update contract for :", response);
    return response;
  } catch (e) {
    console.log("Failed to fetch contrat:", e);
    return undefined;
  }
}

export async function searchUsers(search) {
  try {
    const functions = new Functions(appwriteClient);
    const execution = await functions.createExecution(
      "users_management",
      JSON.stringify({ limit: 100, search }),
      false,
      "/search-users",
      "GET"
      // { "X-Custom-Header": "123" }
    );

    const bodyResult = JSON.parse(execution.responseBody);
    return bodyResult.result;
  } catch (err) {
    console.error(err.message);
    return undefined;
  }
}
 // ! Authentication area
 async function logoutUserSession() {
  const client = new Client();

  const account = new Account(client);

  client.setEndpoint('https://api.shopybase.com/v1').setProject('legihub-dev');

  return account.deleteSessions();
}
async function createEmailSessionPromise(email, password) {
  const client = new Client();

  const account = new Account(client);

  client.setEndpoint('https://api.shopybase.com/v1').setProject('legihub-dev');

  return account.createEmailSession(email, password);
}
async function getUser() {
  const client = new Client();

  const account = new Account(client);

  client.setEndpoint('https://api.shopybase.com/v1').setProject('legihub-dev');

  const promise = account.get();

  return promise.then(
    function (response) {
      console.log('user is', response);
      localStorage.setItem('user', JSON.stringify(response));
      return response;
    },
  ).catch(function (error) {
    console.log('error on session check', error);
    return undefined;
  });
}
export const appwriteDatabase = new Databases(appwriteClient, "legihub");

export {
  APIClient,
  setAuthorization,
  getLoggedinUser,
  updateCompany,
  updateContrat,
  logoutUserSession,
  createEmailSessionPromise,
  getUser
};
