 
import React, { Component } from 'react';
import Contrat from "../Contrat"; 
 
const RenouvellementPE  = () => {
    return (
      <div>
         <Contrat data = "Renouvellement Période d'Essai"/>
      </div>
    );
  };
 
export default RenouvellementPE;

 
 
 
 