import React from "react";
import DDComponent from "./ddComponent";
import DTComponent from "./dtComponent";
import { Row, Card, CardBody } from "reactstrap";

const Index = (props) => {
  const { title, nonCompetitionData, onUpdateNonCompetition } = props;
  return (
    <Row>
      <Card>
        <CardBody>
          <h4 className="card-title mb-5 text-primary">{title}</h4>
          <dl className="row mb-0">
            <DTComponent
              data={nonCompetitionData}
              onHandleDtComponentData={onUpdateNonCompetition}
            />
            <DDComponent
              data={nonCompetitionData}
              onHandleDdComponentData={onUpdateNonCompetition}
            />
          </dl>
        </CardBody>
      </Card>
    </Row>
  );
};

export default Index;
