import React from "react";
import { Row, Card, CardBody } from "reactstrap";
import convertDate from "../../../Utility/hooks/dateConvert";
import UpdateContrat from "../../../common-component/update-service";

const Index = (props) => {
  const { title, data, onUpdateInformation } = props;

  const handleChangeData = (result, parentKey, keyElement) => {
    const getResult = typeof result === "string" ? result.trim() : result;
    if (keyElement) data[parentKey][keyElement] = getResult;
    else data[parentKey] = getResult;
    const {
      $collectionId,
      $createdAt,
      $databaseId,
      $permissions,
      $updatedAt,
      $id,
      ...rest
    } = data;
    rest.contrat_writing_date = rest.contrat_writing_date.toString();
    onUpdateInformation($id, rest);
  };
  return (
    <Row>
      <Card>
        <CardBody>
          <h4 className="card-title mb-5 text-primary">{title}</h4>
          <dl className="row mb-0">
            <dt className="col-sm-6">
              <ul className="verti-timeline list-unstyled">
                <li className="event-list">
                  <div>
                    <span
                      style={{
                        fontSize: "18px",
                        margin: "0 0 7px 0",
                        textDecoration: "underline",
                        fontWeight: "normal",
                      }}
                    >
                      Date de rédaction du présent contrat
                    </span>
                    <span
                      className="verti-timeline list-unstyled"
                      style={{ border: "none" }}
                    >
                      <div
                        style={{
                          fontSize: "14px",
                          fontWeight: "normal",
                        }}
                        className="event-list"
                      >
                        <div
                          style={{
                            backgroundColor: "#e6e2e2a1",
                            padding: "16px",
                            borderRadius: "12px",
                            marginTop: "5px",
                            justifyContent: "space-between",
                          }}
                          className="d-flex"
                        >
                          <span>
                            {convertDate.convertMillisecondToDate(
                              data.contrat_writing_date
                            )}
                          </span>
                          <UpdateContrat
                            typeInput="date"
                            title="Date de rédaction du présent contrat"
                            InputValue={data.contrat_writing_date}
                            parentKey="contrat_writing_date"
                            OnUpdateValue={handleChangeData}
                          />
                        </div>
                      </div>
                    </span>
                  </div>
                </li>
                {data.contrat_type !== "Modèle de Règlement Intérieur" && (
                  <li className="event-list">
                    <div>
                      <span
                        style={{
                          fontSize: "18px",
                          margin: "0 0 7px 0",
                          textDecoration: "underline",
                          fontWeight: "normal",
                        }}
                      >
                        Lieu de rédaction du présent contrat
                      </span>
                      <span
                        className="verti-timeline list-unstyled"
                        style={{ border: "none" }}
                      >
                        <div
                          style={{
                            fontSize: "14px",
                            fontWeight: "normal",
                          }}
                          className="event-list"
                        >
                          <div
                            style={{
                              backgroundColor: "#e6e2e2a1",
                              padding: "16px",
                              borderRadius: "12px",
                              marginTop: "5px",
                              justifyContent: "space-between",
                            }}
                            className="d-flex"
                          >
                            <span>
                              {data === null || data === undefined
                                ? null
                                : data.contrat_writing_place}
                            </span>
                            <UpdateContrat
                              typeInput="default"
                              title="Lieu de rédaction du présent contrat"
                              InputValue={data.contrat_writing_place}
                              parentKey="contrat_writing_place"
                              OnUpdateValue={handleChangeData}
                            />
                          </div>
                        </div>
                      </span>
                    </div>
                  </li>
                )}
              </ul>
            </dt>
          </dl>
        </CardBody>
      </Card>
    </Row>
  );
};

export default Index;
