import React from "react";
import { Row, Card, CardBody } from "reactstrap";
import DtComponent from "./common-component/dtComponent";
import DdComponent from "./common-component/ddComponent";
const President = (props) => {
  const { title, presidentData, onUpdatePresident } = props;

  const result =
    presidentData.president !== null
      ? JSON.parse(presidentData.president)
      : null;
  return (
    <>
      {result && Object.keys(result).length && (
        <Row>
          <Card>
            <CardBody>
              <h4 className="card-title mb-5 text-primary">{title}</h4>
              <dl className="row mb-0">
                <DdComponent
                  data={result}
                  parentId="president"
                  allData={presidentData}
                  onHandleDdComponentData={onUpdatePresident}
                />
                <DtComponent
                  data={result}
                  parentId="president"
                  allData={presidentData}
                  onHandleDtComponentData={onUpdatePresident}
                />
              </dl>
            </CardBody>
          </Card>
        </Row>
      )}
    </>
  );
};

export default President;
