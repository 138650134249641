
import React from 'react';
import Contrat from "../Contrat"; 
 
const CDI  = () => {
    return (
      <div>
         <Contrat data = "CDI"/>
      </div>
    );
  };

export default CDI;
