const StaticData = {
  Radio: {
    Civility: [
      { name: "M.", value: true },
      { name: "Mme", value: false },
    ],
    Holiday: [
      { name: "Jour ouvrés", value: false },
      { name: "Jour ouvrables", value: true },
    ],
    CompanyState: [
      { name: "En cours de taitement", value: "processing" },
      { name: "Créer", value: "created" },
      { name: "Rejecter", value: "rejected" },
      { name: "Erroné", value: "errored" },
    ],
    CompanyWorkforce: [
      {
        name: "L'effectif de votre société est supérieur ou égale à 50",
        value: true,
      },
      { name: "L'effectif de votre société est inférieur à 50", value: false },
    ],
    WorkPlace: [
      {
        name: "Au siège social de l’entreprise",
        value: "Au siège social de l’entreprise",
      },
      { name: "A un autre lieu", value: "A un autre lieu" },
    ],
    DataRecipient: [
      {
        name: "Les données à caractère personnel sont transmit vers des partenaires situés en dehors de l’Union Européenne.",
        value: true,
      },
      {
        name: "Les données à caractère personnel ne sont pas transmit vers des partenaires situés en dehors de l’Union Européenne.",
        value: false,
      },
    ],
    DataFlows: [
      {
        name: "Les données à caractère personnel traitées font l’objet de transferts dans des pays situés dans ou hors de l’Union Européenne.",
        value: true,
      },
      {
        name: "Les données à caractère personnel traitées ne font pas l’objet de transferts dans des pays situés dans ou hors de l’Union Européenne.",
        value: false,
      },
    ],
    LetterDelivered: [
      {
        name: "Lettre remise en main propre contre récépissé",
        value: true,
      },
      { name: "Lettre de recommendée avec accusé de réception", value: false },
    ],
    TeenagersRights: [
      { name: "Oui", value: true },
      { name: "Non", value: false },
    ],
    Convention: [
      {
        name: "La convention collective prévoit des modalités spécifiques d'entretien périodiques sur la charge et l'organisation du travail du salarié",
        value: true,
      },
      {
        name: "La convention collective ne prévoit pas des modalités spécifiques d'entretien périodiques sur la charge et l'organisation du travail du salarié",
        value: false,
      },
    ],
    NumberDays: [
      {
        name: "La convention collective ne prévoit pas moins de 218 jours travaillés par an",
        value: 0,
      },
      {
        name: "La convention collective prévoit moins de 218 jours travaillés par an",
        value: 1,
      },
    ],
  },
  CheckBox: {
    EmployeeAdvantage: [
      "Ticket restaurant",
      "Prise en charge par l’Employeur du forfait mobilité durable",
      "Prise en charge à 50% du Pass’Navigo",
      "Prise en charge à 100% des frais de Mutuelle",
      "Ouverture de droits à la réservation d’un berceau en crèche",
    ],
    WorkDays: [
      "Lundi",
      "Mardi",
      "Mercredi",
      "Jeudi",
      "Vendredi",
      "Samedi",
      "Dimanche",
    ],
    CompanyPurposes: [
      "La gestion du personnel (ex: gestion administrative du personnel, mise à disposition des outils informatiques et téléphoniques, gestion des carrières et de la mobilité, gestion de la formation)",
      "La gestion de la paie (ex: calcul du paiement des rémunérations et accessoires, calcul des retenues déductibles ou indemnisables opérées conformément aux dispositions légales et conventionnelles)",
    ],
    Cookies: [
      "Cookies fonctionnels / techniques",
      "Cookies tiers de mesure d’audience",
      "Cookies tiers publicitaires",
      "Cookies de réseaux sociaux",
    ],
    Dataprocessed: [
      "Des données relatives à votre identification",
      "Des données relatives à votre situation familiale, telle que votre situation matrimoniale ou le nombre d’enfants à votre charge",
      "Des données d’ordre économique et financier, telles que les coordonnées bancaires",
      "Des données relatives à vos aptitudes professionnelles, telles que vos expériences professionnelles passées ou le contenu de vos évaluations professionnelles",
      "Des données relatives à votre poste et à votre vie professionnelle, telles que le poste occupé ou les conditions de votre embauche",
      "Des données relatives à votre rémunération et à vos avantages, tels que votre salaire de base ou le montant de vos titres restaurant",
      "Votre numéro de sécurité sociale.",
    ],

    DataCollect: [
      "Le nom",
      "Le prénom",
      "La date de naissance",
      "L'adresse email",
      "L'adresse",
      "Le numéro de téléphone",
      "Le nom de l'entreprise",
      "L'adresse IP",
    ],
    BusinesPpurposes: [
      "Pour fournir et exploiter les Services",
      "Pour développer, personnaliser et améliorer nos Services",
      "Pour répondre à vos besoins et à vos requêtes en termes de documentation",
      "Pour répondre à vos réactions, demandes et requêtes et vous apporter notre assistance",
      "Pour analyser les schémas de demande et d'utilisation",
      "À d'autres fins internes, de recherche ou de statistiques",
      "Pour enquêter sur les éventuelles violations, faire respecter nos condition ou nos politiques, ou se conformer à une loi, une réglementation ou un autorité gouvernementale applicable",
      "Pour répondre aux éventuelles demandes des tiers autorisés",
      "Pour   vous   envoyer   des   mises   à   jour,   des   avis,   des   documents promotionnels et d'autres informations concernant nos Services. Si nous vous envoyons des e-mails promotionnels, vous pouvez choisir de ne plus les recevoir en cliquant sur le lien de désabonnement que ces e-mails contiennent",
    ],
    WorkWeeks: ["Semaine 1", "Semaine 2", "Semaine 3", "Semaine 4"],
  },
  CompanyFields: [
    { label: "Nom entreprise", value: "company_name" },
    // { label: "Repésentant", value: "company_price_name " },
    // { label: "Desecription", value: "company_description " },
    // { label: "Activité description", value: "company_activity_description" },
  ],
};
export default StaticData;
