import React from "react";
import DDComponent from "./ddComponent";
import DTComponent from "./dtComponent";
import { Row, Card, CardBody } from "reactstrap";
const Index = (props) => {
  const { title, presentationEmployeeData, onUpdatePresentationEmployee } =
    props;

  return (
    <Row>
      <Card>
        <CardBody>
          <h4 className="card-title mb-5 text-primary">{title}</h4>
          <dl className="row mb-0">
            <DTComponent
              data={presentationEmployeeData}
              onHandleDtComponentData={onUpdatePresentationEmployee}
            />
            <DDComponent
              data={presentationEmployeeData}
              onHandleDdComponentData={onUpdatePresentationEmployee}
            />
          </dl>
        </CardBody>
      </Card>
    </Row>
  );
};

export default Index;
