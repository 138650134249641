import React from "react";
import DTComponent from "./dtComponent";
import DDComponent from "./ddComponent";
import { Row, Card, CardBody } from "reactstrap";
const Index = (props) => {
  const { title, employmentData, onUpdateEmployment } = props;

  return (
    <Row>
      <Card>
        <CardBody>
          <h4 className="card-title mb-5 text-primary">{title}</h4>
          <dl className="row mb-0">
            <DTComponent
              data={employmentData}
              onHandleDtComponentData={onUpdateEmployment}
            />
            <DDComponent
              data={employmentData}
              onHandleDdComponentData={onUpdateEmployment}
            />
          </dl>
        </CardBody>
      </Card>
    </Row>
  );
};

export default Index;
