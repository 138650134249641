import React, { Component } from 'react';
import Contrat from '../Contrat';

const CDD = () => {
  return (
    <div>
      <Contrat data="CDD" />
    </div>
  );
};

export default CDD;
