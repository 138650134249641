import React, { useState } from "react";
import { Row, Card, CardBody } from "reactstrap";
import UpdateCompany from "../../common-component/update-service";
const Capital = (props) => {
  const { title, capitalData, onUpdateCapital } = props;
  const [isHovered, setIsHovered] = useState(false);
  const handleChangeData =  (result, parentKey, keyElement) => {
    const getResult = typeof result === "string" ? result.trim() : result;
    if (keyElement) capitalData[parentKey][keyElement] = getResult;
    else capitalData[parentKey] = getResult;
    const {
      $collectionId,
      $createdAt,
      $databaseId,
      $permissions,
      $updatedAt,
      $id,
      ...rest
    } = capitalData;
    onUpdateCapital($id, rest);
  };
  return (
    <>
      <Row>
        <Card>
          <CardBody>
            <h4 className="card-title mb-5 text-primary">{title}</h4>

            <ul className="verti-timeline list-unstyled">
              <li className="event-list">
                <div>
                  <span
                    style={{
                      fontSize: "15px",
                      margin: "0 0 7px 0",
                      textDecoration: "underline",
                      fontWeight: "normal",
                    }}
                  >
                    Montant
                  </span>
                  <span
                    className="verti-timeline list-unstyled"
                    style={{ border: "none" }}
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                  >
                    <div
                      style={{
                        fontSize: "12px",
                        fontWeight: "normal",
                        width: "45%",
                      }}
                      className="event-list"
                    >
                      <div
                        style={{
                          backgroundColor: "#e6e2e2a1",
                          padding: "16px",
                          borderRadius: "12px",
                          marginTop: "5px",
                          justifyContent: "space-between",
                        }}
                        className="d-flex"
                      >
                        <span style={{ fontSize: "14px" }}>
                          {capitalData.company_capital_amount}
                        </span>
                        {isHovered && (<UpdateCompany
                          typeInput="default"
                          title="Montant"
                          InputValue={capitalData.company_capital_amount}
                          parentKey="company_capital_amount"
                          OnUpdateValue={handleChangeData}
                        />)}
                        
                      </div>
                    </div>
                  </span>
                </div>
              </li>
            </ul>
          </CardBody>
        </Card>
      </Row>
    </>
  );
};

export default Capital;
