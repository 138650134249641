import React, { useState } from "react";
import convertDate from "../../../Utility/hooks/dateConvert";
import UpdateContrat from "../../../common-component/update-service";
const DtComponent = (props) => {
  const { data, onHandleDtComponentData } = props;
  const [isHovered, setIsHovered] = useState({
    hoverState1: false,
    hoverState2: false,
    hoverState3: false,
    hoverState4: false,
    hoverState5: false,
    hoverState6: false,
    hoverState7: false,
    hoverState8: false,
    hoverState9: false,
    hoverState10: false,
    hoverState11: false,
    hoverState12: false,
    hoverState13: false,
    hoverState14: false,
    hoverState15: false,
    hoverState16: false,

  });
  const handleChangeData = async (result, parentKey, keyElement) => {
    const getResult = typeof result === "string" ? result.trim() : result;
    if (keyElement) data[parentKey][keyElement] = getResult;
    else data[parentKey] = getResult;
    const {
      $collectionId,
      $createdAt,
      $databaseId,
      $permissions,
      $updatedAt,
      $id,
      ...rest
    } = data;
    rest.employee_id.employee_start_date =
      rest.employee_id.employee_start_date.toString();
    rest.employee_id.employee_end_date =
      rest.employee_id.employee_end_date.toString();
    onHandleDtComponentData($id, rest);
  };
  return (
    <>
      {data.contrat_type !== "Rupture Période d'Essai" && (
        <dt className="col-sm-6">
          <ul className="verti-timeline list-unstyled">
            {/* StartMotif du contrat */}
            {data.contrat_type !== "Promesse d'embauche" &&
              data.contrat_type !== "CDI" && (
                <li className="event-list">
                  <div>
                    <span
                      style={{
                        fontSize: "18px",
                        margin: "0 0 7px 0",
                        textDecoration: "underline",
                        fontWeight: "normal",
                      }}
                    >
                      Motif du contrat
                    </span>
                    <ul className="verti-timeline list-unstyled">
                      {/* LI Remplacement d'un salarié absent Start */}
                      {data.contrat_reason ===
                        "Remplacement d'un salarié absent" && (
                        <li className="event-list">
                          <div>
                            <span
                              style={{
                                fontSize: "18px",
                                margin: "0 0 7px 0",
                                textDecoration: "underline",
                                fontWeight: "normal",
                              }}
                            >
                              {data.contrat_reason}
                            </span>
                            <dl className="row mb-0">
                              <dt className="col-sm-6">
                                <ul className="verti-timeline list-unstyled">
                                  <li className="event-list">
                                    <div>
                                      <span
                                        style={{
                                          fontSize: "18px",
                                          margin: "0 0 7px 0",
                                          textDecoration: "underline",
                                          fontWeight: "normal",
                                        }}
                                      >
                                        Civilité du salarié absent
                                      </span>
                                      <span
                                        className="verti-timeline list-unstyled"
                                        style={{ border: "none" }}
                                        onMouseEnter={() =>
                                          setIsHovered((prev) => ({ ...prev, hoverState1: true }))
                                        }
                                        onMouseLeave={() =>
                                          setIsHovered((prev) => ({ ...prev, hoverState1: false }))
                                        }
                                      >
                                        <div
                                          style={{
                                            fontSize: "14px",
                                            fontWeight: "normal",
                                          }}
                                          className="event-list"
                                        >
                                          <div
                                            style={{
                                              backgroundColor: "#e6e2e2a1",
                                              padding: "16px",
                                              borderRadius: "12px",
                                              marginTop: "5px",
                                              justifyContent: "space-between",
                                            }}
                                            className="d-flex"
                                          >
                                            <span>
                                              {
                                                data.employee_id
                                                  .absent_employee_gender
                                              }
                                            </span>
                                            {isHovered.hoverState1 && (<UpdateContrat
                                              title="Civilité du salarié absent"
                                              InputValue={
                                                data.employee_id
                                                  .absent_employee_gender
                                              }
                                              typeInput="default"
                                              parentKey="employee_id"
                                              keyElement="absent_employee_gender"
                                              OnUpdateValue={handleChangeData}
                                            />)}
                                            
                                          </div>
                                        </div>
                                      </span>
                                    </div>
                                  </li>
                                  <li className="event-list">
                                    <div>
                                      <span
                                        style={{
                                          fontSize: "18px",
                                          margin: "0 0 7px 0",
                                          textDecoration: "underline",
                                          fontWeight: "normal",
                                        }}
                                      >
                                        Nom du salarié absent
                                      </span>
                                      <span
                                        className="verti-timeline list-unstyled"
                                        style={{ border: "none" }}
                                        onMouseEnter={() =>
                                          setIsHovered((prev) => ({ ...prev, hoverState2: true }))
                                        }
                                        onMouseLeave={() =>
                                          setIsHovered((prev) => ({ ...prev, hoverState2: false }))
                                        }
                                      >
                                        <div
                                          style={{
                                            fontSize: "14px",
                                            fontWeight: "normal",
                                          }}
                                          className="event-list"
                                        >
                                          <div
                                            style={{
                                              backgroundColor: "#e6e2e2a1",
                                              padding: "16px",
                                              borderRadius: "12px",
                                              marginTop: "5px",
                                              justifyContent: "space-between",
                                            }}
                                            className="d-flex"
                                          >
                                            <span>
                                              {
                                                data.employee_id
                                                  .absent_employee_name
                                              }
                                            </span>
                                            {isHovered.hoverState2 && (<UpdateContrat
                                              title="Nom du salarié absent"
                                              InputValue={
                                                data.employee_id
                                                  .absent_employee_name
                                              }
                                              typeInput="default"
                                              parentKey="employee_id"
                                              keyElement="absent_employee_name"
                                              OnUpdateValue={handleChangeData}
                                            />)}
                                            
                                          </div>
                                        </div>
                                      </span>
                                    </div>
                                  </li>
                                  <li className="event-list">
                                    <div>
                                      <span
                                        style={{
                                          fontSize: "18px",
                                          margin: "0 0 7px 0",
                                          textDecoration: "underline",
                                          fontWeight: "normal",
                                        }}
                                      >
                                        Prénom du salarié absent
                                      </span>
                                      <span
                                        className="verti-timeline list-unstyled"
                                        style={{ border: "none" }}
                                        onMouseEnter={() =>
                                          setIsHovered((prev) => ({ ...prev, hoverState3: true }))
                                        }
                                        onMouseLeave={() =>
                                          setIsHovered((prev) => ({ ...prev, hoverState3: false }))
                                        }
                                      >
                                        <div
                                          style={{
                                            fontSize: "14px",
                                            fontWeight: "normal",
                                          }}
                                          className="event-list"
                                        >
                                          <div
                                            style={{
                                              backgroundColor: "#e6e2e2a1",
                                              padding: "16px",
                                              borderRadius: "12px",
                                              marginTop: "5px",
                                              justifyContent: "space-between",
                                            }}
                                            className="d-flex"
                                          >
                                            <span>
                                              {
                                                data.employee_id
                                                  .absent_employee_surname
                                              }
                                            </span>
                                            {isHovered.hoverState3 && (<UpdateContrat
                                              title="Prénom du salarié absent"
                                              InputValue={
                                                data.employee_id
                                                  .absent_employee_surname
                                              }
                                              typeInput="default"
                                              parentKey="employee_id"
                                              keyElement="absent_employee_surname"
                                              OnUpdateValue={handleChangeData}
                                            />)}
                                            
                                          </div>
                                        </div>
                                      </span>
                                    </div>
                                  </li>
                                </ul>
                              </dt>

                              {/* ddSub component */}
                              <dd className="col-sm-6">
                                <ul className="verti-timeline list-unstyled">
                                  <li className="event-list">
                                    <div>
                                      <span
                                        style={{
                                          fontSize: "18px",
                                          margin: "0 0 7px 0",
                                          textDecoration: "underline",
                                          fontWeight: "normal",
                                        }}
                                      >
                                        Fonctions du salarié absent
                                      </span>
                                      <span
                                        className="verti-timeline list-unstyled"
                                        style={{ border: "none" }}
                                        onMouseEnter={() =>
                                          setIsHovered((prev) => ({...prev, hoverState4: true }))
                                        }
                                        onMouseLeave={() =>
                                          setIsHovered((prev) => ({...prev, hoverState4: false }))
                                        }
                                      >
                                        <div
                                          style={{
                                            fontSize: "14px",
                                            fontWeight: "normal",
                                          }}
                                          className="event-list"
                                        >
                                          <div
                                            style={{
                                              backgroundColor: "#e6e2e2a1",
                                              padding: "16px",
                                              borderRadius: "12px",
                                              marginTop: "5px",
                                              justifyContent: "space-between",
                                            }}
                                            className="d-flex"
                                          >
                                            <span>
                                              {
                                                data.employee_id
                                                  .absent_employee_fonctions
                                              }
                                            </span>
                                            {isHovered.hoverState4 && (<UpdateContrat
                                              title="Fonctions du salarié absent"
                                              InputValue={
                                                data.employee_id
                                                  .absent_employee_fonctions
                                              }
                                              typeInput="default"
                                              parentKey="employee_id"
                                              keyElement="absent_employee_fonctions"
                                              OnUpdateValue={handleChangeData}
                                            />)}
                                            
                                          </div>
                                        </div>
                                      </span>
                                    </div>
                                  </li>
                                  <li className="event-list">
                                    <div>
                                      <span
                                        style={{
                                          fontSize: "18px",
                                          margin: "0 0 7px 0",
                                          textDecoration: "underline",
                                          fontWeight: "normal",
                                        }}
                                      >
                                        Coefficient du salarié absent
                                      </span>
                                      <span
                                        className="verti-timeline list-unstyled"
                                        style={{ border: "none" }}
                                        onMouseEnter={() =>
                                          setIsHovered((prev) => ({...prev, hoverState5: true }))
                                        }
                                        onMouseLeave={() =>
                                          setIsHovered((prev) => ({...prev, hoverState5: false }))
                                        }
                                      >
                                        <div
                                          style={{
                                            fontSize: "14px",
                                            fontWeight: "normal",
                                          }}
                                          className="event-list"
                                        >
                                          <div
                                            style={{
                                              backgroundColor: "#e6e2e2a1",
                                              padding: "16px",
                                              borderRadius: "12px",
                                              marginTop: "5px",
                                              justifyContent: "space-between",
                                            }}
                                            className="d-flex"
                                          >
                                            <span>
                                              {
                                                data.employee_id
                                                  .absent_employee_coef
                                              }
                                            </span>
                                            {isHovered.hoverState5 && (<UpdateContrat
                                              title="Coefficient du salarié absent"
                                              InputValue={
                                                data.employee_id
                                                  .absent_employee_coef
                                              }
                                              typeInput="default"
                                              parentKey="employee_id"
                                              keyElement="absent_employee_coef"
                                              OnUpdateValue={handleChangeData}
                                            />)}
                                            
                                          </div>
                                        </div>
                                      </span>
                                    </div>
                                  </li>
                                  <li className="event-list">
                                    <div>
                                      <span
                                        style={{
                                          fontSize: "18px",
                                          margin: "0 0 7px 0",
                                          textDecoration: "underline",
                                          fontWeight: "normal",
                                        }}
                                      >
                                        Cause de l'absence du salarié
                                      </span>
                                      <span
                                        className="verti-timeline list-unstyled"
                                        style={{ border: "none" }}
                                        onMouseEnter={() =>
                                          setIsHovered((prev) => ({...prev, hoverState6: true }))
                                        }
                                        onMouseLeave={() =>
                                          setIsHovered((prev) => ({...prev, hoverState6: false }))
                                        }
                                      >
                                        <div
                                          style={{
                                            fontSize: "14px",
                                            fontWeight: "normal",
                                          }}
                                          className="event-list"
                                        >
                                          <div
                                            style={{
                                              backgroundColor: "#e6e2e2a1",
                                              padding: "16px",
                                              borderRadius: "12px",
                                              marginTop: "5px",
                                              justifyContent: "space-between",
                                            }}
                                            className="d-flex"
                                          >
                                            <span>
                                              {
                                                data.employee_id
                                                  .absent_employee_reason
                                              }
                                            </span>
                                            {isHovered.hoverState7 && (<UpdateContrat
                                              title="Cause de l'absence du salarié"
                                              InputValue={
                                                data.employee_id
                                                  .absent_employee_reason
                                              }
                                              typeInput="default"
                                              parentKey="employee_id"
                                              keyElement="absent_employee_reason"
                                              OnUpdateValue={handleChangeData}
                                            />)}
                                            
                                          </div>
                                        </div>
                                      </span>
                                    </div>
                                  </li>
                                </ul>
                              </dd>
                            </dl>
                          </div>
                        </li>
                      )}

                      {/* LI Remplacement d'un salarié absent End */}

                      {/* LI Accroissement temporaire d'activité Start */}
                      {data.contrat_reason ===
                        "Accroissement temporaire d'activité" && (
                        <li className="event-list">
                          <div>
                            <span
                              style={{
                                fontSize: "18px",
                                margin: "0 0 7px 0",
                                textDecoration: "underline",
                                fontWeight: "normal",
                              }}
                            >
                              {data.contrat_reason}
                            </span>
                            <span
                              className="verti-timeline list-unstyled"
                              style={{ border: "none" }}
                              onMouseEnter={() =>
                                setIsHovered((prev) => ({...prev, hoverState8: true }))
                              }
                              onMouseLeave={() =>
                                setIsHovered((prev) => ({...prev, hoverState8: false }))
                              }
                            >
                              <div
                                style={{
                                  fontSize: "14px",
                                  fontWeight: "normal",
                                }}
                                className="event-list"
                              >
                                <div
                                  style={{
                                    backgroundColor: "#e6e2e2a1",
                                    padding: "16px",
                                    borderRadius: "12px",
                                    marginTop: "5px",
                                    justifyContent: "space-between",
                                  }}
                                  className="d-flex"
                                >
                                  <span>{data.activity_growth_nature}</span>
                                  {isHovered.hoverState8 && (<UpdateContrat
                                    title={data.contrat_reason}
                                    InputValue={data.activity_growth_nature}
                                    typeInput="default"
                                    parentKey="activity_growth_nature"
                                    OnUpdateValue={handleChangeData}
                                  />)}
                                  
                                </div>
                              </div>
                            </span>
                          </div>
                        </li>
                      )}

                      {/* LI Accroissement temporaire d'activité End */}

                      {/* CDD d'usage Start */}
                      {data.contrat_reason === "CDD d'usage" && (
                        <div>
                          <span
                            className="verti-timeline list-unstyled"
                            style={{ border: "none" }}
                          >
                            <div
                              style={{
                                fontSize: "14px",
                                fontWeight: "normal",
                              }}
                              className="event-list"
                            >
                              <div
                                style={{
                                  backgroundColor: "#e6e2e2a1",
                                  padding: "16px",
                                  borderRadius: "12px",
                                  marginTop: "5px",
                                  justifyContent: "space-between",
                                }}
                                className="d-flex"
                              >
                                <span>{data.contrat_reason}</span>
                                
                              </div>
                            </div>
                          </span>
                        </div>
                      )}

                      {/* LI CDD d'usage End */}

                      {/* LI Attente de la prise de fonctions d'un salarié recruté en CDI Start */}
                      {data.contrat_reason ===
                        "Attente de la prise de fonctions d'un salarié recruté en CDI" && (
                        <li className="event-list">
                          <div>
                            <span
                              style={{
                                fontSize: "18px",
                                margin: "0 0 7px 0",
                                textDecoration: "underline",
                                fontWeight: "normal",
                              }}
                            >
                              {data.contrat_reason}
                            </span>
                            <dl className="row mb-0">
                              <dt className="col-sm-6">
                                <ul className="verti-timeline list-unstyled">
                                  <li className="event-list">
                                    <div>
                                      <span
                                        style={{
                                          fontSize: "18px",
                                          margin: "0 0 7px 0",
                                          textDecoration: "underline",
                                          fontWeight: "normal",
                                        }}
                                      >
                                        Civilité du salarié devant être embauché
                                        en CDI
                                      </span>
                                      <span
                                        className="verti-timeline list-unstyled"
                                        style={{ border: "none" }}
                                        onMouseEnter={() =>
                                          setIsHovered((prev) => ({...prev, hoverState9: true }))
                                        }
                                        onMouseLeave={() =>
                                          setIsHovered((prev) => ({...prev, hoverState9: false }))
                                        }
                                      >
                                        <div
                                          style={{
                                            fontSize: "14px",
                                            fontWeight: "normal",
                                          }}
                                          className="event-list"
                                        >
                                          <div
                                            style={{
                                              backgroundColor: "#e6e2e2a1",
                                              padding: "16px",
                                              borderRadius: "12px",
                                              marginTop: "5px",
                                              justifyContent: "space-between",
                                            }}
                                            className="d-flex"
                                          >
                                            <span>
                                              {
                                                data.employee_id
                                                  .employee_hired_gender
                                              }
                                            </span>
                                            {isHovered.hoverState9 && (<UpdateContrat
                                              title="Civilité du salarié devant être embauché en CDI"
                                              InputValue={
                                                data.employee_id
                                                  .employee_hired_gender
                                              }
                                              typeInput="default"
                                              parentKey="employee_id"
                                              keyElement="employee_hired_gender"
                                              OnUpdateValue={handleChangeData}
                                            />)}
                                            
                                          </div>
                                        </div>
                                      </span>
                                    </div>
                                  </li>
                                  <li className="event-list">
                                    <div>
                                      <span
                                        style={{
                                          fontSize: "18px",
                                          margin: "0 0 7px 0",
                                          textDecoration: "underline",
                                          fontWeight: "normal",
                                        }}
                                      >
                                        Nom du salarié devant être embauché en
                                        CDI
                                      </span>
                                      <span
                                        className="verti-timeline list-unstyled"
                                        style={{ border: "none" }}
                                        onMouseEnter={() =>
                                          setIsHovered((prev) => ({...prev, hoverState10: true }))
                                        }
                                        onMouseLeave={() =>
                                          setIsHovered((prev) => ({...prev, hoverState10: false }))
                                        }
                                      >
                                        <div
                                          style={{
                                            fontSize: "14px",
                                            fontWeight: "normal",
                                          }}
                                          className="event-list"
                                        >
                                          <div
                                            style={{
                                              backgroundColor: "#e6e2e2a1",
                                              padding: "16px",
                                              borderRadius: "12px",
                                              marginTop: "5px",
                                              justifyContent: "space-between",
                                            }}
                                            className="d-flex"
                                          >
                                            <span>
                                              {
                                                data.employee_id
                                                  .employee_hired_name
                                              }
                                            </span>
                                            {isHovered.hoverState10 && (<UpdateContrat
                                              title="Nom du salarié devant être embauché en CDI"
                                              InputValue={
                                                data.employee_id
                                                  .employee_hired_name
                                              }
                                              typeInput="default"
                                              parentKey="employee_id"
                                              keyElement="employee_hired_name"
                                              OnUpdateValue={handleChangeData}
                                            />)}
                                            
                                          </div>
                                        </div>
                                      </span>
                                    </div>
                                  </li>
                                  <li className="event-list">
                                    <div>
                                      <span
                                        style={{
                                          fontSize: "18px",
                                          margin: "0 0 7px 0",
                                          textDecoration: "underline",
                                          fontWeight: "normal",
                                        }}
                                      >
                                        Prénom du salarié devant être embauché
                                        en CDI
                                      </span>
                                      <span
                                        className="verti-timeline list-unstyled"
                                        style={{ border: "none" }}
                                        onMouseEnter={() =>
                                          setIsHovered((prev) => ({...prev, hoverState11: true }))
                                        }
                                        onMouseLeave={() =>
                                          setIsHovered((prev) => ({...prev, hoverState11: false }))
                                        }
                                      >
                                        <div
                                          style={{
                                            fontSize: "14px",
                                            fontWeight: "normal",
                                          }}
                                          className="event-list"
                                        >
                                          <div
                                            style={{
                                              backgroundColor: "#e6e2e2a1",
                                              padding: "16px",
                                              borderRadius: "12px",
                                              marginTop: "5px",
                                              justifyContent: "space-between",
                                            }}
                                            className="d-flex"
                                          >
                                            <span>
                                              {
                                                data.employee_id
                                                  .employee_hired_surname
                                              }
                                            </span>
                                            {isHovered.hoverState11 && (<UpdateContrat
                                              title="Prénom du salarié devant être embauché en CDI"
                                              InputValue={
                                                data.employee_id
                                                  .employee_hired_surname
                                              }
                                              typeInput="default"
                                              parentKey="employee_id"
                                              keyElement="employee_hired_surname"
                                              OnUpdateValue={handleChangeData}
                                            />)}
                                            
                                          </div>
                                        </div>
                                      </span>
                                    </div>
                                  </li>
                                </ul>
                              </dt>

                              {/* ddSub component */}
                              <dd className="col-sm-6">
                                <ul className="verti-timeline list-unstyled">
                                  <li className="event-list">
                                    <div>
                                      <span
                                        style={{
                                          fontSize: "18px",
                                          margin: "0 0 7px 0",
                                          textDecoration: "underline",
                                          fontWeight: "normal",
                                        }}
                                      >
                                        Fonctions du salarié devant être
                                        embauché en CDI
                                      </span>
                                      <span
                                        className="verti-timeline list-unstyled"
                                        style={{ border: "none" }}
                                        onMouseEnter={() =>
                                          setIsHovered((prev) => ({...prev, hoverState12: true }))
                                        }
                                        onMouseLeave={() =>
                                          setIsHovered((prev) => ({...prev, hoverState12: false }))
                                        }
                                      >
                                        <div
                                          style={{
                                            fontSize: "14px",
                                            fontWeight: "normal",
                                          }}
                                          className="event-list"
                                        >
                                          <div
                                            style={{
                                              backgroundColor: "#e6e2e2a1",
                                              padding: "16px",
                                              borderRadius: "12px",
                                              marginTop: "5px",
                                              justifyContent: "space-between",
                                            }}
                                            className="d-flex"
                                          >
                                            <span>
                                              {
                                                data.employee_id
                                                  .employee_hired_fonctions
                                              }
                                            </span>
                                            {isHovered.hoverState12 && (<UpdateContrat
                                              title="Fonctions du salarié devant être embauché en CDI"
                                              InputValue={
                                                data.employee_id
                                                  .employee_hired_fonctions
                                              }
                                              typeInput="default"
                                              parentKey="employee_id"
                                              keyElement="employee_hired_fonctions"
                                              OnUpdateValue={handleChangeData}
                                            />)}
                                            
                                          </div>
                                        </div>
                                      </span>
                                    </div>
                                  </li>
                                  <li className="event-list">
                                    <div>
                                      <span
                                        style={{
                                          fontSize: "18px",
                                          margin: "0 0 7px 0",
                                          textDecoration: "underline",
                                          fontWeight: "normal",
                                        }}
                                      >
                                        Coefficient du salarié devant être
                                        embauché en CDI
                                      </span>
                                      <span
                                        className="verti-timeline list-unstyled"
                                        style={{ border: "none" }}
                                        onMouseEnter={() =>
                                          setIsHovered((prev) => ({...prev, hoverState13: true }))
                                        }
                                        onMouseLeave={() =>
                                          setIsHovered((prev) => ({...prev, hoverState13: false }))
                                        }
                                      >
                                        <div
                                          style={{
                                            fontSize: "14px",
                                            fontWeight: "normal",
                                          }}
                                          className="event-list"
                                        >
                                          <div
                                            style={{
                                              backgroundColor: "#e6e2e2a1",
                                              padding: "16px",
                                              borderRadius: "12px",
                                              marginTop: "5px",
                                              justifyContent: "space-between",
                                            }}
                                            className="d-flex"
                                          >
                                            <span>
                                              {
                                                data.employee_id
                                                  .employee_hired_coef
                                              }
                                            </span>
                                            {isHovered.hoverState13 && (<UpdateContrat
                                              title="Coefficient du salarié devant être embauché en
                                                CDI"
                                              InputValue={
                                                data.employee_id
                                                  .employee_hired_coef
                                              }
                                              typeInput="default"
                                              parentKey="employee_id"
                                              keyElement="employee_hired_coef"
                                              OnUpdateValue={handleChangeData}
                                            />)}
                                            
                                          </div>
                                        </div>
                                      </span>
                                    </div>
                                  </li>
                                </ul>
                              </dd>
                            </dl>
                          </div>
                        </li>
                      )}
                    </ul>
                  </div>
                </li>
              )}
            {/* EnMotif du contrat */}
            {data.contrat_type !== "Promesse d'embauche" &&
              data.contrat_type !== "CDI" && (
                <li className="event-list">
                  <div>
                    <span
                      style={{
                        fontSize: "18px",
                        margin: "0 0 7px 0",
                        textDecoration: "underline",
                        fontWeight: "normal",
                      }}
                    >
                      Date de début du contrat
                    </span>
                    <span
                      className="verti-timeline list-unstyled"
                      style={{ border: "none" }}
                      onMouseEnter={() =>
                        setIsHovered((prev) => ({...prev, hoverState14: true }))
                      }
                      onMouseLeave={() =>
                        setIsHovered((prev) => ({...prev, hoverState14: false }))
                      }
                    >
                      <div
                        style={{
                          fontSize: "14px",
                          fontWeight: "normal",
                        }}
                        className="event-list"
                      >
                        <div
                          style={{
                            backgroundColor: "#e6e2e2a1",
                            padding: "16px",
                            borderRadius: "12px",
                            marginTop: "5px",
                            justifyContent: "space-between",
                          }}
                          className="d-flex"
                        >
                          <span>
                            {data.employee_id === null ||
                            data.employee_id === undefined
                              ? null
                              : convertDate.convertMillisecondToDate(
                                  data.employee_id.employee_start_date
                                )}
                          </span>
                          {isHovered.hoverState14 && (<UpdateContrat
                            typeInput="date"
                            title="Date de début du contrat"
                            InputValue={data.employee_id.employee_start_date}
                            parentKey="employee_id"
                            keyElement="employee_start_date"
                            OnUpdateValue={handleChangeData}
                          />)}
                          
                        </div>
                      </div>
                    </span>
                  </div>
                </li>
              )}
            {data.contrat_type !== "CDI" && (
              <li className="event-list">
                <div>
                  <span
                    style={{
                      fontSize: "18px",
                      margin: "0 0 7px 0",
                      textDecoration: "underline",
                      fontWeight: "normal",
                    }}
                  >
                    Date de fin du contrat
                  </span>
                  <span
                    className="verti-timeline list-unstyled"
                    style={{ border: "none" }}
                    onMouseEnter={() =>
                      setIsHovered((prev) => ({...prev, hoverState15: true }))
                    }
                    onMouseLeave={() =>
                      setIsHovered((prev) => ({...prev, hoverState15: false }))
                    }
                  >
                    <div
                      style={{
                        fontSize: "14px",
                        fontWeight: "normal",
                      }}
                      className="event-list"
                    >
                      <div
                        style={{
                          backgroundColor: "#e6e2e2a1",
                          padding: "16px",
                          borderRadius: "12px",
                          marginTop: "5px",
                          justifyContent: "space-between",
                        }}
                        className="d-flex"
                      >
                        <span>
                          {data.employee_id === null ||
                          data.employee_id === undefined
                            ? null
                            : convertDate.convertMillisecondToDate(
                                data.employee_id.employee_end_date
                              )}
                        </span>
                        {isHovered.hoverState15 && (<UpdateContrat
                          typeInput="date"
                          title="Date de fin du contrat"
                          InputValue={data.employee_id.employee_end_date}
                          parentKey="employee_id"
                          keyElement="employee_end_date"
                          OnUpdateValue={handleChangeData}
                        />)}
                        
                      </div>
                    </div>
                  </span>
                </div>
              </li>
            )}

            {data.contrat_type === "CDI" && (
              <li className="event-list">
                <div>
                  <span
                    style={{
                      fontSize: "18px",
                      margin: "0 0 7px 0",
                      textDecoration: "underline",
                      fontWeight: "normal",
                    }}
                  >
                    Fonction du Salarié
                  </span>
                  <span
                    className="verti-timeline list-unstyled"
                    style={{ border: "none" }}
                    onMouseEnter={() =>
                      setIsHovered((prev) => ({...prev, hoverState16: true }))
                    }
                    onMouseLeave={() =>
                      setIsHovered((prev) => ({...prev, hoverState16: false }))
                    }
                  >
                    <div
                      style={{
                        fontSize: "14px",
                        fontWeight: "normal",
                      }}
                      className="event-list"
                    >
                      <div
                        style={{
                          backgroundColor: "#e6e2e2a1",
                          padding: "16px",
                          borderRadius: "12px",
                          marginTop: "5px",
                          justifyContent: "space-between",
                        }}
                        className="d-flex"
                      >
                        <span>
                          {data.employee_id === null ||
                          data.employee_id === undefined
                            ? null
                            : data.employee_id.employee_fonctions}
                        </span>
                        {isHovered.hoverState16 && (<UpdateContrat
                          typeInput="default"
                          title="Date de fin du contrat"
                          InputValue={data.employee_id.employee_fonctions}
                          parentKey="employee_id"
                          keyElement="employee_fonctions"
                          OnUpdateValue={handleChangeData}
                        />)}
                        
                      </div>
                    </div>
                  </span>
                </div>
              </li>
            )}
          </ul>
        </dt>
      )}
    </>
  );
};

export default DtComponent;
