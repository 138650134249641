import React, { useState } from 'react';

import { Button, Col, Row, Container, Input } from 'reactstrap';

import { LatestTransationData } from '../../CommonData/Data/index';
// Import Images
import img2 from '../../assets/images/users/avatar-2.jpg';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import DataTable from 'react-data-table-component';

import { useContrats } from '../../Hooks/UserHooks';

const ContratRequest = () => {
  const [stale, setStale] = useState({ stale: false });
  const [{ contrats }] = useContrats(stale);

  const [modal_list, setmodal_list] = useState(false);
  function tog_list() {
    setmodal_list(!modal_list);
  }

  document.title = 'Inbox  | LEGIHUB - ADMIN';
  function IconFromStatus(status) {
    switch (status) {
      case 'Confirm':
        return 'primary';
      case 'Cancel':
        return 'danger';
      case 'Pending':
        return 'warning';

      default:
        return 'danger';
    }
  }

  const columns = [
    {
      name: <Input className="form-check-input fs-15" type="checkbox" name="checkAll" value="option1" />,
      cell: () => <input className="form-check-input fs-15" type="checkbox" name="checkAll" value="option1" />,
    },
    {
      name: <span className="font-weight-bold fs-13">Nom</span>,
      sortable: true,
      selector: (cell) => {
        return (
          <React.Fragment>
            <td>
              {contrats.map((item) => (
                <div key={item['$id']} item={item.name} setStale={setStale} />
              ))}
            </td>

            <td>
              {cell.title[1] ? (
                <img src={cell.title[1]} alt="user" className="avatar-xs rounded-circle" />
              ) : (
                <div className="avatar-xs">
                  <span className="avatar-title rounded-circle bg-soft-primary text-success">{cell.title[0].charAt(0)}</span>
                </div>
              )}
            </td>
            <td>
              <h5 className="font-size-15 mb-0">{cell.title[0]}</h5>
            </td>
          </React.Fragment>
        );
      },
    },
    {
      name: <span className="font-weight-bold fs-13">Date</span>,
      selector: (row) => row.year,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Statut</span>,
      selector: (row) => (
        <td>
          <span className={'badge badge-soft-' + IconFromStatus(row.status)}> {row.status} </span>
        </td>
      ),
      sortable: true,
    },

    {
      name: <span className="font-weight-bold fs-13">Actions</span>,
      selector: (row) => (
        <td>
          <button type="button" className="btn btn-outline-success btn-sm me-1">
            Edit
          </button>
          <button type="button" className="btn btn-outline-danger btn-sm me-1">
            Cancel
          </button>
        </td>
      ),
      sortable: true,
    },
  ];

  const data = [
    {
      id: 1,
      title: ['Beetlejuice', img2],
      year: '1988',
      status: 'Confirm',
    },
    {
      id: 2,
      title: ['Ghostbusters', img2],
      year: '1984',
      status: 'Pending',
    },
    {
      id: 2,
      title: ['Ghostbusters', img2],
      year: '1984',
      status: 'Pending',
    },
    {
      id: 2,
      title: ['Ghostbusters', img2],
      year: '1984',
      status: 'Pending',
    },
    {
      id: 2,
      title: ['Ghostbusters', img2],
      year: '1984',
      status: 'Pending',
    },
    {
      id: 2,
      title: ['Ghostbusters', img2],
      year: '1984',
      status: 'Pending',
    },
    {
      id: 2,
      title: ['Ghostbusters', img2],
      year: '1984',
      status: 'Pending',
    },
    {
      id: 2,
      title: ['Ghostbusters', img2],
      year: '1984',
      status: 'Pending',
    },
    {
      id: 2,
      title: ['Ghostbusters', img2],
      year: '1984',
      status: 'Pending',
    },
    {
      id: 2,
      title: ['Ghostbusters', img2],
      year: '1984',
      status: 'Pending',
    },
    {
      id: 2,
      title: ['Ghostbusters', img2],
      year: '1984',
      status: 'Pending',
    },
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Contrats" breadcrumbItem="Demandes" />
          <Row>
            <Col lg={12}>
              <Row className="g-4 mb-3">
                <Col className="col-sm-auto">
                  <div className="d-flex gap-1">
                    <Button color="success" className="add-btn" onClick={() => tog_list()} id="create-btn">
                      <i className="ri-add-line align-bottom me-1"></i> Add
                    </Button>
                    <Button
                      color="soft-danger"
                      // onClick="deleteMultiple()"
                    >
                      <i className="ri-delete-bin-2-line"></i>
                    </Button>
                  </div>
                </Col>
                <Col className="col-sm">
                  <div className="d-flex justify-content-sm-end">
                    <div className="search-box ms-2">
                      <input type="text" className="form-control search" placeholder="Search..." />
                      <i className="ri-search-line search-icon"></i>
                    </div>
                  </div>
                </Col>
              </Row>
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title mb-4">Liste des demandes de contrat</h4>
                  <div className="table-responsive">
                    <table className="table table-centered table-nowrap mb-0">
                      <thead>
                        <tr>
                          <th scope="col" style={{ width: '50px' }}>
                            <div className="form-check">
                              <label className="form-check-label" htmlFor="customCheckall"></label>
                            </div>
                          </th>
                          <th scope="col" style={{ width: '60px' }}></th>
                          <th scope="col">Salarié</th>
                          <th scope="col">Type</th>
                          <th scope="col">Date</th>
                          <th scope="col">Statut</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {LatestTransationData.map((item, key) => (
                          <tr key={key}>
                            <td>
                              <div className="form-check">
                                <input type="checkbox" className="form-check-input" id={item.id} />
                                <label className="form-check-label" htmlFor={item.id}></label>
                              </div>
                            </td>
                            <td>
                              {item.src ? (
                                <img src={item.src} alt="user" className="avatar-xs rounded-circle" />
                              ) : (
                                <div className="avatar-xs">
                                  <span className="avatar-title rounded-circle bg-soft-primary text-success">{item.clientName.charAt(0)}</span>
                                </div>
                              )}
                            </td>
                            <td>
                              <h5 className="font-size-15 mb-0">{item.clientName}</h5>
                            </td>
                            <td>$ {item.price}</td>
                            <td>{item.quantity}</td>
                            <td>{item.date}</td>
                            <td>
                              {' '}
                              <span className={'badge badge-soft-' + IconFromStatus(item.status)}> {item.status} </span>
                              {/*-<i className={"mdi mdi-checkbox-blank-circle me-1 text-" + item.color}></i>*/}
                            </td>
                            <td>
                              <button type="button" className="btn btn-outline-success btn-sm me-1">
                                Edit
                              </button>
                              <button type="button" className="btn btn-outline-danger btn-sm me-1">
                                Cancel
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <DataTable columns={columns} data={data} pagination />
      </div>
    </React.Fragment>
  );
};

export default ContratRequest;
