import React, { useState } from "react";
import { Row, Card, CardBody } from "reactstrap";
import convertDate from "../../Utility/hooks/dateConvert";
import UpdateCompany from "../../common-component/update-service";

const HeadOffice = (props) => {
  const { title, headOfficeData, onUpdateHeadOffice } = props;
  const [isHovered, setIsHovered] = useState({
    hoverState1: false,
    hoverState2: false,
  });
  const handleChangeData = (result, parentKey, keyElement) => {
    const getResult = typeof result === "string" ? result.trim() : result;
    if (keyElement) headOfficeData[parentKey][keyElement] = getResult;
    else headOfficeData[parentKey] = getResult;
    const {
      $collectionId,
      $createdAt,
      $databaseId,
      $permissions,
      $updatedAt,
      $id,
      ...rest
    } = headOfficeData;
    rest.company_year_end_date = rest.company_year_end_date.toString();
    onUpdateHeadOffice($id, rest);
  };
  return (
    <>
      <Row>
        <Card>
          <CardBody>
            <h4 className="card-title mb-5 text-primary">{title}</h4>

            <ul className="verti-timeline list-unstyled">
              <li className="event-list">
                <div>
                  <span
                    style={{
                      fontSize: "15px",
                      margin: "0 0 7px 0",
                      textDecoration: "underline",
                      fontWeight: "normal",
                    }}
                  >
                    Type
                  </span>
                  <span
                    className="verti-timeline list-unstyled"
                    style={{ border: "none" }}
                   
                  >
                    <div
                      style={{
                        fontSize: "12px",
                        fontWeight: "normal",
                        width: "45%",
                      }}
                      className="event-list"
                    >
                      <div
                        style={{
                          backgroundColor: "#e6e2e2a1",
                          padding: "16px",
                          borderRadius: "12px",
                          marginTop: "5px",
                          justifyContent: "space-between",
                        }}
                        className="d-flex"
                      >
                        <span style={{ fontSize: "14px" }}>
                          {headOfficeData
                            ? JSON.parse(headOfficeData.company_headOffice).type
                            : ""}
                        </span>
                      </div>
                    </div>
                  </span>
                </div>
              </li>
              <li className="event-list">
                <div>
                  <span
                    style={{
                      fontSize: "15px",
                      margin: "0 0 7px 0",
                      textDecoration: "underline",
                      fontWeight: "normal",
                    }}
                  >
                    Date de fin d'exercice
                  </span>
                  <span
                    className="verti-timeline list-unstyled"
                    style={{ border: "none" }}
                    onMouseEnter={() =>
                      setIsHovered((prev) => ({ ...prev, hoverState1: true }))
                    }
                    onMouseLeave={() =>
                      setIsHovered((prev) => ({ ...prev, hoverState1: false }))
                    }
                  >
                    <div
                      style={{
                        fontSize: "12px",
                        fontWeight: "normal",
                        width: "45%",
                      }}
                      className="event-list"
                    >
                      <div
                        style={{
                          backgroundColor: "#e6e2e2a1",
                          padding: "16px",
                          borderRadius: "12px",
                          marginTop: "5px",
                          justifyContent: "space-between",
                        }}
                        className="d-flex"
                      >
                        <span style={{ fontSize: "14px" }}>
                          {convertDate.convertMillisecondToDate(
                            headOfficeData.company_year_end_date
                          )}
                        </span>
                        {isHovered.hoverState1 && (<UpdateCompany
                          typeInput="date"
                          title="Date de fin d'exercice"
                          InputValue={headOfficeData.company_year_end_date}
                          parentKey="company_year_end_date"
                          OnUpdateValue={handleChangeData}
                        />)}
                        
                      </div>
                    </div>
                  </span>
                </div>
              </li>

              <li className="event-list">
                <div>
                  <span
                    style={{
                      fontSize: "15px",
                      margin: "0 0 7px 0",
                      textDecoration: "underline",
                      fontWeight: "normal",
                    }}
                  >
                    Durée de la société
                  </span>
                  <span
                    className="verti-timeline list-unstyled"
                    style={{ border: "none" }}
                    onMouseEnter={() =>
                      setIsHovered((prev) => ({...prev, hoverState2: true }))
                    }
                    onMouseLeave={() =>
                      setIsHovered((prev) => ({...prev, hoverState2: false }))
                    }
                  >
                    <div
                      style={{
                        fontSize: "12px",
                        fontWeight: "normal",
                        width: "45%",
                      }}
                      className="event-list"
                    >
                      <div
                        style={{
                          backgroundColor: "#e6e2e2a1",
                          padding: "16px",
                          borderRadius: "12px",
                          marginTop: "5px",
                          justifyContent: "space-between",
                        }}
                        className="d-flex"
                      >
                        <span style={{ fontSize: "14px" }}>
                          {headOfficeData.company_society_duration}
                        </span>
                        {isHovered.hoverState2 && (<UpdateCompany
                          typeInput="default"
                          title="Durée de la société"
                          InputValue={headOfficeData.company_society_duration}
                          parentKey="company_society_duration"
                          OnUpdateValue={handleChangeData}
                        />)}
                        
                      </div>
                    </div>
                  </span>
                </div>
              </li>
            </ul>
          </CardBody>
        </Card>
      </Row>
    </>
  );
};

export default HeadOffice;
