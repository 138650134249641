import React from 'react'
import { Row, Card, CardBody } from "reactstrap";
import DDComponent from "./ddComponent";
import DTComponent from "./dtComponent";

const Index = (props) => {
    const {title,socialProtectionData,onUpdateSocialProtection} = props
  return (
    <Row>
    <Card>
      <CardBody>
        <h4 className="card-title mb-5 text-primary">
          {title}
         
        </h4>
        <dl className="row mb-0">
          <DTComponent data={socialProtectionData} onHandleDtComponentData={onUpdateSocialProtection} />
          <DDComponent data={socialProtectionData} onHandleDdComponentData={onUpdateSocialProtection} />
        </dl>
      </CardBody>
    </Card>
  </Row>
  )
}

export default Index