import React, { useState } from "react";
import UpdateContrat from "../../../common-component/update-service";

const DDComponent = (props) => {
  const { data, onHandleDdComponentData } = props;
  const [isHovered, setIsHovered] = useState({
    hoverState1: false,
    hoverState2: false,
  });
  const handleChangeData = async (result, parentKey, keyElement) => {
    const getResult = typeof result === "string" ? result.trim() : result;
    if (keyElement) data[parentKey][keyElement] = getResult;
    else data[parentKey] = getResult;
    const {
      $collectionId,
      $createdAt,
      $databaseId,
      $permissions,
      $updatedAt,
      $id,
      ...rest
    } = data;
    onHandleDdComponentData($id, rest);
  };
  return (
    <>
      {data.contrat_type !== "Rupture Période d'Essai" &&
        data.contrat_type !== "Renouvellement Période d'Essai" &&
        data.contrat_type !== "Modèle de Règlement Intérieur" && (
          <dd className="col-sm-6">
            <ul className="verti-timeline list-unstyled">
              <li className="event-list">
                <div>
                  <span
                    style={{
                      fontSize: "18px",
                      margin: "0 0 7px 0",
                      textDecoration: "underline",
                      fontWeight: "normal",
                    }}
                  >
                    Nom de la convention collective
                  </span>
                  <span
                    className="verti-timeline list-unstyled"
                    style={{ border: "none" }}
                    onMouseEnter={() =>
                      setIsHovered((prev) => ({ ...prev, hoverState1: true }))
                    }
                    onMouseLeave={() =>
                      setIsHovered((prev) => ({ ...prev, hoverState1: false }))
                    }
                  >
                    <div
                      style={{
                        fontSize: "14px",
                        fontWeight: "normal",
                      }}
                      className="event-list"
                    >
                      <div
                        style={{
                          backgroundColor: "#e6e2e2a1",
                          padding: "16px",
                          borderRadius: "12px",
                          marginTop: "5px",
                          justifyContent: "space-between",
                        }}
                        className="d-flex"
                      >
                        <span>{data.convention_name}</span>
                        {isHovered.hoverState1 && (<UpdateContrat
                          typeInput="default"
                          title="Nom de la convention collective"
                          InputValue={data.convention_name}
                          parentKey="convention_name"
                          OnUpdateValue={handleChangeData}
                        />)}
                        
                      </div>
                    </div>
                  </span>
                </div>
              </li>

              <li className="event-list">
                <div>
                  <span
                    style={{
                      fontSize: "18px",
                      margin: "0 0 7px 0",
                      textDecoration: "underline",
                      fontWeight: "normal",
                    }}
                  >
                    Numéro IDCC
                  </span>
                  <span
                    className="verti-timeline list-unstyled"
                    style={{ border: "none" }}
                    onMouseEnter={() =>
                      setIsHovered((prev) => ({...prev, hoverState2: true }))
                    }
                     onMouseLeave={() =>
                      setIsHovered((prev) => ({...prev, hoverState2: false }))
                    }
                  >
                    <div
                      style={{
                        fontSize: "14px",
                        fontWeight: "normal",
                      }}
                      className="event-list"
                    >
                      <div
                        style={{
                          backgroundColor: "#e6e2e2a1",
                          padding: "16px",
                          borderRadius: "12px",
                          marginTop: "5px",
                          justifyContent: "space-between",
                        }}
                        className="d-flex"
                      >
                        <span>{data.idcc_number}</span>
                        {isHovered.hoverState2 && (<UpdateContrat
                          typeInput="default"
                          title="Numéro IDCC"
                          InputValue={data.idcc_number}
                          parentKey="idcc_number"
                          OnUpdateValue={handleChangeData}
                        />)}
                        
                      </div>
                    </div>
                  </span>
                </div>
              </li>
            </ul>
          </dd>
        )}
    </>
  );
};

export default DDComponent;
