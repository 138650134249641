import React, { useEffect, useState } from "react";

import { Col, Row, Container } from "reactstrap";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import { useLocation } from "react-router-dom";
import PresentationCompany from "./components/presentation-company";
import PresentationEmployee from "./components/presentation-employee";
import Declaration from "./components/declaration";
import ContractEmployee from "./components/contract-employee";
import Employment from "./components/employment";
import Workplace from "./components/workplace";
import RenewalConvention from "./components/renewal-convention";
import NonCompetition from "./components/non-competition";
import RetirementPension from "./components/retirement-pension";
import SocialProtection from "./components/social-protection";
import OthersData from "./components/others-data";
import Information from "./components/information";
import { appwriteDatabase, updateContrat } from "../../helpers/api_helper";
import ToastComponent from "../common-component/update-service/customToast";
import Icon from "../Utility/hooks/component";
import ScrollToTopButton from "../common-component/scrollToTop";

const ContratDetail = () => {
  const { state } = useLocation();
  const [contrat, setContrat] = useState({});
  const [taostNotification, setTaostNotification] = useState({
    update: false,
    fail: false,
  });
  const [collectData, setCollectData] = useState({ id: "", data: {} });
  const [loadBtn, setloadBtn] = useState(false);

  useEffect(() => {
    getContrat(state);
  }, []);

  async function getContrat(id) {
    appwriteDatabase.getDocument("legihub", "contrat", id).then(
      (response) => {
        console.log("contrat is", response);
        setContrat(response);
      },
      function (error) {
        console.log(error);
      }
    );
  }
  const handleDataForm = (id, data) => {
    if (id.length > 0) {
      setCollectData((prev) => ({
        ...prev,
        id:id,
        data: data,
      }));
    }
  };
  async function updateContrats() {
    try {
      setloadBtn(true)
      const response = await updateContrat(collectData.id,collectData.data);
      setContrat(response);
      setloadBtn(false)
      setTaostNotification((prev) => ({
        ...prev,
        update: true,
      }));
      setTimeout(() => {
        setTaostNotification((prev) => ({
          ...prev,
          update: false,
        }));
      }, 3000);
    } catch (error) {
      setTaostNotification((prev) => ({
        ...prev,
        fail: true,
      }));
      setTimeout(() => {
        setTaostNotification((prev) => ({
          ...prev,
          fail: false,
        }));
      }, 3000);
      setloadBtn(false)
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
      
        <Container fluid={true}>
        
          {Object.keys(contrat).length ? (
            <>
              <Breadcrumbs
                title="Contrats"
                breadcrumbItem={`${contrat.contrat_type}`}
              />
              <div className="d-flex justify-content-end mb-2">
                <button
                      type="button"
                      className="btn btn-rounded btn-success"
                      onClick={updateContrats}
                      disabled={collectData.id.length === 0}
                    >{!loadBtn ? "Sauvegarder": Icon.dot}
                    </button>
              </div>
              
              <Row>
                <Col lg={12}>
                  {contrat.contrat_type !== "Politique de confidentialité" &&
                    contrat.contrat_type !==
                      "Politique de gestion des cookies" &&
                    contrat.contrat_type !==
                      "Modèle de Règlement Intérieur" && (
                      <PresentationCompany
                        title="Présentation de l'entreprise"
                        presentationCompanyData={contrat}
                        onUpdatePresentationCompany = {handleDataForm}
                      />
                    )}

                  {contrat.contrat_type !== "Modèle de Règlement Intérieur" &&
                    contrat.contrat_type !==
                      "Note d'information à la destination des salariés" &&
                    contrat.contrat_type !== "Politique de confidentialité" &&
                    contrat.contrat_type !==
                      "Politique de gestion des cookies" &&
                    contrat.contrat_type !==
                      "Politique interne de protection des données" && contrat.contrat_type !==
                      "Information et procédure d'exercice des droits des personnes concernées" && contrat.contrat_type !==
                      "Notification des violations de données à caractère personnel" && (
                      <PresentationEmployee
                        title="Présentation du salarié"
                        presentationEmployeeData={contrat}
                        onUpdatePresentationEmployee = {handleDataForm}
                      />
                    )}

                  {contrat.contrat_type !==
                    "Note d'information à la destination des salariés" &&
                    contrat.contrat_type !== "Politique de confidentialité" &&
                    contrat.contrat_type !==
                      "Politique de gestion des cookies" &&
                    contrat.contrat_type !==
                      "Politique interne de protection des données" && contrat.contrat_type !==
                      "Information et procédure d'exercice des droits des personnes concernées" && contrat.contrat_type !==
                      "Notification des violations de données à caractère personnel" && (
                      <Declaration
                        title="Déclaration"
                        declarationData={contrat}
                        onUpdateDeclaration = {handleDataForm}
                      />
                    )}

                  {contrat.contrat_type !== "Modèle de Règlement Intérieur" &&
                    contrat.contrat_type !== "Renouvellement Période d'Essai" &&
                    contrat.contrat_type !==
                      "Note d'information à la destination des salariés" &&
                    contrat.contrat_type !== "Politique de confidentialité" &&
                    contrat.contrat_type !== "Politique de confidentialité" &&
                    contrat.contrat_type !==
                      "Politique de gestion des cookies" &&
                    contrat.contrat_type !==
                      "Politique interne de protection des données" && contrat.contrat_type !==
                      "Information et procédure d'exercice des droits des personnes concernées" && contrat.contrat_type !==
                      "Notification des violations de données à caractère personnel" && (
                      <ContractEmployee
                        title="Contrat de travail"
                        contratData={contrat}
                        onUpdateContractEmployee = {handleDataForm}
                      />
                    )}

                  {contrat.contrat_type !== "Modèle de Règlement Intérieur" &&
                    contrat.contrat_type !==
                      "Note d'information à la destination des salariés" &&
                    contrat.contrat_type !==
                      "Note d'information à la destination des salariés" &&
                    contrat.contrat_type !== "Politique de confidentialité" &&
                    contrat.contrat_type !==
                      "Politique de gestion des cookies" &&
                    contrat.contrat_type !==
                      "Politique interne de protection des données" && contrat.contrat_type !==
                      "Information et procédure d'exercice des droits des personnes concernées" && contrat.contrat_type !==
                      "Notification des violations de données à caractère personnel" && (
                      <Employment title="L'emploi" employmentData={contrat}
                      onUpdateEmployment = {handleDataForm} />
                    )}

                  {contrat.contrat_type !== "Modèle de Règlement Intérieur" &&
                    contrat.contrat_type !== "Promesse d'embauche" &&
                    contrat.contrat_type !== "Rupture Période d'Essai" &&
                    contrat.contrat_type !== "Renouvellement Période d'Essai" &&
                    contrat.contrat_type !==
                      "Note d'information à la destination des salariés" &&
                    contrat.contrat_type !== "Politique de confidentialité" &&
                    contrat.contrat_type !==
                      "Politique de gestion des cookies" &&
                    contrat.contrat_type !==
                      "Politique interne de protection des données" && contrat.contrat_type !==
                      "Information et procédure d'exercice des droits des personnes concernées" && contrat.contrat_type !==
                      "Notification des violations de données à caractère personnel" && (
                      <Workplace
                        title={`Lieu d'exécution du travail${contrat.contrat_type === "CDI" ? ", Congés payés & RTT":""} `}
                        data={contrat}
                        onUpdateWorkplace = {handleDataForm}
                      />
                    )}

                  {contrat.convention_renewal &&
                    contrat.contrat_type !==
                      "Note d'information à la destination des salariés" &&
                    contrat.contrat_type !== "Politique de confidentialité" &&
                    contrat.contrat_type !==
                      "Politique de gestion des cookies" &&
                    contrat.contrat_type !==
                      "Politique interne de protection des données" && contrat.contrat_type !==
                      "Information et procédure d'exercice des droits des personnes concernées" && contrat.contrat_type !==
                      "Notification des violations de données à caractère personnel" && (
                      <RenewalConvention
                        title="Renouvellement convention ou accord branche"
                        renewalConventionData={contrat}
                        onUpdateRenewalConvention = {handleDataForm}

                      />
                    )}
                  {contrat.contrat_type !== "CDD" &&
                    contrat.contrat_type !== "Promesse d'embauche" &&
                    contrat.contrat_type !== "Rupture Période d'Essai" &&
                    contrat.contrat_type !== "Renouvellement Période d'Essai" &&
                    contrat.contrat_type !== "Modèle de Règlement Intérieur" &&
                    contrat.contrat_type !==
                      "Note d'information à la destination des salariés" &&
                    contrat.contrat_type !== "Politique de confidentialité" &&
                    contrat.contrat_type !==
                      "Politique de gestion des cookies" &&
                    contrat.contrat_type !==
                      "Politique interne de protection des données" && contrat.contrat_type !==
                      "Information et procédure d'exercice des droits des personnes concernées" && contrat.contrat_type !==
                      "Notification des violations de données à caractère personnel" && (
                      <NonCompetition
                        title="Clause de non-concurrence"
                        nonCompetitionData={contrat}
                        onUpdateNonCompetition = {handleDataForm}
                      />
                    )}

                  {contrat.contrat_type !== "Modèle de Règlement Intérieur" &&
                    contrat.contrat_type !== "Promesse d'embauche" &&
                    contrat.contrat_type !== "Rupture Période d'Essai" &&
                    contrat.contrat_type !== "Renouvellement Période d'Essai" &&
                    contrat.contrat_type !==
                      "Note d'information à la destination des salariés" &&
                    contrat.contrat_type !== "Politique de confidentialité" &&
                    contrat.contrat_type !==
                      "Politique de gestion des cookies" &&
                    contrat.contrat_type !==
                      "Politique interne de protection des données" && contrat.contrat_type !==
                      "Information et procédure d'exercice des droits des personnes concernées" && contrat.contrat_type !==
                      "Notification des violations de données à caractère personnel" && (
                      <RetirementPension
                        title="Retraite et prévoyance"
                        retirementPensionData={contrat}
                        onUpdateRetirementPension = {handleDataForm}
                      />
                    )}

                  {contrat.contrat_type !== "Modèle de Règlement Intérieur" &&
                    contrat.contrat_type !== "Promesse d'embauche" &&
                    contrat.contrat_type !== "Rupture Période d'Essai" &&
                    contrat.contrat_type !== "Renouvellement Période d'Essai" &&
                    contrat.contrat_type !==
                      "Note d'information à la destination des salariés" &&
                    contrat.contrat_type !== "Politique de confidentialité" &&
                    contrat.contrat_type !==
                      "Politique de gestion des cookies" &&
                    contrat.contrat_type !==
                      "Politique interne de protection des données" && contrat.contrat_type !==
                      "CDI" && contrat.contrat_type !==
                      "Information et procédure d'exercice des droits des personnes concernées" && contrat.contrat_type !==
                      "Notification des violations de données à caractère personnel" && (
                      <SocialProtection
                        title="Protection Social"
                        socialProtectionData={contrat}
                        onUpdateSocialProtection = {handleDataForm}
                      />
                    )}

                  {contrat.contrat_type !== "CDD" &&
                    contrat.contrat_type !== "Promesse d'embauche" &&
                    contrat.contrat_type !== "Rupture Période d'Essai" &&
                    contrat.contrat_type !== "Renouvellement Période d'Essai" &&
                    contrat.contrat_type !== "Modèle de Règlement Intérieur" &&
                    contrat.contrat_type !== "CDI" && contrat.contrat_type !==
                    "Information et procédure d'exercice des droits des personnes concernées" && contrat.contrat_type !==
                    "Notification des violations de données à caractère personnel" && (
                      <OthersData
                        title="Autres informations"
                        othersData={contrat}
                        onUpdateOthersData = {handleDataForm}
                      />
                    )}
                  {contrat.contrat_type !==
                    "Note d'information à la destination des salariés" &&
                    contrat.contrat_type !== "Politique de confidentialité" &&
                    contrat.contrat_type !==
                      "Politique de gestion des cookies" &&
                    contrat.contrat_type !==
                      "Politique interne de protection des données" && contrat.contrat_type !==
                      "Information et procédure d'exercice des droits des personnes concernées" && contrat.contrat_type !==
                      "Notification des violations de données à caractère personnel" && (
                      <Information
                        title="Informations de création"
                        data={contrat}
                        onUpdateInformation = {handleDataForm}
                      />
                    )}

                  <div className="d-flex justify-content-center mb-5 mt-5">
                    <button
                      type="button"
                      className="btn btn-rounded btn-success"
                      onClick={updateContrats}
                      disabled={collectData.id.length === 0}
                    >
                      {!loadBtn ? "Sauvegarder": Icon.dot}
                    </button>
                  </div>
                </Col>
                
              </Row>
            </>
          ) : Icon.watch}
          {taostNotification.update && (
          <ToastComponent
            position="leftBottom"
            hasOpen={taostNotification.update}
            message={`Sauvegarde éffectuée avec succès`}
          />
        )}
        {taostNotification.fail && (
          <ToastComponent
            position="leftBottom"
            hasOpen={taostNotification.fail}
            message={`"Échec, veuillez réessayer."`}
          />
        )}
        
        </Container>
        <ScrollToTopButton/>

      </div>
    </React.Fragment>
  );
};

export default ContratDetail;
