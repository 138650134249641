import React, { useState } from "react";
import convertDate from "../../../Utility/hooks/dateConvert";
import UpdateContrat from "../../../common-component/update-service";

const DDComponent = (props) => {
  const { data, onHandleDdComponentData } = props;
  const [isHovered, setIsHovered] = useState({
    hoverState1: false,
    hoverState2: false,
    hoverState3: false,
    hoverState4: false,
    hoverState5: false,
  });
  const handleChangeData = (result, parentKey, keyElement) => {
    const getResult = typeof result === "string" ? result.trim() : result;
    if (keyElement) data[parentKey][keyElement] = getResult;
    else data[parentKey] = getResult;
    const {
      $collectionId,
      $createdAt,
      $databaseId,
      $permissions,
      $updatedAt,
      $id,
      ...rest
    } = data;
    rest.employee_id.employee_notice_end_date =
      rest.employee_id.employee_notice_end_date.toString();
    onHandleDdComponentData($id, rest);
  };
  return (
    <>
      {data.contrat_type !== "Promesse d'embauche" && (
        <dt className="col-sm-6">
          <ul className="verti-timeline list-unstyled">
            {data.contrat_type !== "Rupture Période d'Essai" && (
              <>
                <li className="event-list">
                  <div>
                    <span
                      style={{
                        fontSize: "18px",
                        margin: "0 0 7px 0",
                        textDecoration: "underline",
                        fontWeight: "normal",
                      }}
                    >
                      Coefficient
                    </span>
                    <span
                      className="verti-timeline list-unstyled"
                      style={{ border: "none" }}
                      onMouseEnter={() =>
                        setIsHovered((prev) => ({ ...prev, hoverState1: true }))
                      }
                      onMouseLeave={() =>
                        setIsHovered((prev) => ({
                          ...prev,
                          hoverState1: false,
                        }))
                      }
                    >
                      <div
                        style={{
                          fontSize: "14px",
                          fontWeight: "normal",
                        }}
                        className="event-list"
                      >
                        <div
                          style={{
                            backgroundColor: "#e6e2e2a1",
                            padding: "16px",
                            borderRadius: "12px",
                            marginTop: "5px",
                            justifyContent: "space-between",
                          }}
                          className="d-flex"
                        >
                          <span>
                            {data.company_id === null ||
                            data.company_id === undefined
                              ? null
                              : data.job_coefficient}
                          </span>
                          {isHovered.hoverState1 && (
                            <UpdateContrat
                              title="Coefficient"
                              InputValue={data.job_coefficient}
                              parentKey="job_coefficient"
                              OnUpdateValue={handleChangeData}
                              typeInput="default"
                            />
                          )}
                        </div>
                      </div>
                    </span>
                  </div>
                </li>
                {data.contrat_type !== "CDI" && (
                  <li className="event-list">
                    <div>
                      <span
                        style={{
                          fontSize: "18px",
                          margin: "0 0 7px 0",
                          textDecoration: "underline",
                          fontWeight: "normal",
                        }}
                      >
                        Tâche du collaborateur
                      </span>
                      <span
                        className="verti-timeline list-unstyled"
                        style={{ border: "none" }}
                        onMouseEnter={() =>
                          setIsHovered((prev) => ({
                            ...prev,
                            hoverState2: true,
                          }))
                        }
                        onMouseLeave={() =>
                          setIsHovered((prev) => ({
                            ...prev,
                            hoverState2: false,
                          }))
                        }
                      >
                        <div
                          style={{
                            fontSize: "14px",
                            fontWeight: "normal",
                          }}
                          className="event-list"
                        >
                          <div
                            style={{
                              backgroundColor: "#e6e2e2a1",
                              padding: "16px",
                              borderRadius: "12px",
                              marginTop: "5px",
                              justifyContent: "space-between",
                            }}
                            className="d-flex"
                          >
                            <span>
                              {data.employee_id === null ||
                              data.employee_id === undefined
                                ? null
                                : data.employee_id.employee_task}
                            </span>
                            {isHovered.hoverState2 && (
                              <UpdateContrat
                                title="Tâche du collaborateur"
                                InputValue={data.employee_id.employee_task}
                                parentKey="employee_id"
                                keyElement="employee_task"
                                OnUpdateValue={handleChangeData}
                                typeInput="default"
                              />
                            )}
                          </div>
                        </div>
                      </span>
                    </div>
                  </li>
                )}
              </>
            )}

            {data.contrat_type !== "CDD" && data.contrat_type !== "CDI" && (
              <li className="event-list">
                <div>
                  <span
                    style={{
                      fontSize: "18px",
                      margin: "0 0 7px 0",
                      textDecoration: "underline",
                      fontWeight: "normal",
                    }}
                  >
                    Date de prévenance
                  </span>
                  <span
                    className="verti-timeline list-unstyled"
                    style={{ border: "none" }}
                    onMouseEnter={() =>
                      setIsHovered((prev) => ({ ...prev, hoverState3: true }))
                    }
                    onMouseLeave={() =>
                      setIsHovered((prev) => ({ ...prev, hoverState3: false }))
                    }
                  >
                    <div
                      style={{
                        fontSize: "14px",
                        fontWeight: "normal",
                      }}
                      className="event-list"
                    >
                      <div
                        style={{
                          backgroundColor: "#e6e2e2a1",
                          padding: "16px",
                          borderRadius: "12px",
                          marginTop: "5px",
                          justifyContent: "space-between",
                        }}
                        className="d-flex"
                      >
                        <span>
                          {data.employee_id === null ||
                          data.employee_id === undefined
                            ? null
                            : convertDate.convertMillisecondToDate(
                                data.employee_id.employee_notice_end_date
                              )}
                        </span>
                        {isHovered.hoverState3 && (
                          <UpdateContrat
                            typeInput="date"
                            title="Date de prévenance"
                            InputValue={
                              data.employee_id.employee_notice_end_date
                            }
                            parentKey="employee_id"
                            keyElement="employee_notice_end_date"
                            OnUpdateValue={handleChangeData}
                          />
                        )}
                      </div>
                    </div>
                  </span>
                </div>
              </li>
            )}
            {data.contrat_type !== "Rupture Période d'Essai" && (
              <>
                <li className="event-list">
                  <div>
                    <span
                      style={{
                        fontSize: "18px",
                        margin: "0 0 7px 0",
                        textDecoration: "underline",
                        fontWeight: "normal",
                      }}
                    >
                      Echellon
                    </span>
                    <span
                      className="verti-timeline list-unstyled"
                      style={{ border: "none" }}
                      onMouseEnter={() =>
                        setIsHovered((prev) => ({ ...prev, hoverState4: true }))
                      }
                      onMouseLeave={() =>
                        setIsHovered((prev) => ({
                          ...prev,
                          hoverState4: false,
                        }))
                      }
                    >
                      <div
                        style={{
                          fontSize: "14px",
                          fontWeight: "normal",
                        }}
                        className="event-list"
                      >
                        <div
                          style={{
                            backgroundColor: "#e6e2e2a1",
                            padding: "16px",
                            borderRadius: "12px",
                            marginTop: "5px",
                            justifyContent: "space-between",
                          }}
                          className="d-flex"
                        >
                          <span>
                            {data.company_id === null ||
                            data.company_id === undefined
                              ? null
                              : data.job_rung}
                          </span>
                          {isHovered.hoverState4 && (
                            <UpdateContrat
                              title="Echellon"
                              InputValue={data.job_rung}
                              parentKey="job_rung"
                              OnUpdateValue={handleChangeData}
                              typeInput="default"
                            />
                          )}
                        </div>
                      </div>
                    </span>
                  </div>
                </li>
                <li className="event-list">
                  <div>
                    <span
                      style={{
                        fontSize: "18px",
                        margin: "0 0 7px 0",
                        textDecoration: "underline",
                        fontWeight: "normal",
                      }}
                    >
                      Position
                    </span>
                    <span
                      className="verti-timeline list-unstyled"
                      style={{ border: "none" }}
                      onMouseEnter={() =>
                        setIsHovered((prev) => ({ ...prev, hoverState5: true }))
                      }
                      onMouseLeave={() =>
                        setIsHovered((prev) => ({
                          ...prev,
                          hoverState5: false,
                        }))
                      }
                    >
                      <div
                        style={{
                          fontSize: "14px",
                          fontWeight: "normal",
                        }}
                        className="event-list"
                      >
                        <div
                          style={{
                            backgroundColor: "#e6e2e2a1",
                            padding: "16px",
                            borderRadius: "12px",
                            marginTop: "5px",
                            justifyContent: "space-between",
                          }}
                          className="d-flex"
                        >
                          <span>
                            {data.company_id === null ||
                            data.company_id === undefined
                              ? null
                              : data.job_position}
                          </span>
                          {isHovered.hoverState5 && (
                            <UpdateContrat
                              title="Position"
                              InputValue={data.job_position}
                              parentKey="job_position"
                              OnUpdateValue={handleChangeData}
                              typeInput="default"
                            />
                          )}
                        </div>
                      </div>
                    </span>
                  </div>
                </li>
              </>
            )}
          </ul>
        </dt>
      )}
    </>
  );
};

export default DDComponent;
