const convertDate = {
    convertHourlyToDate : (timestamp) => {
  if (timestamp) {
    const dateInput = new Date(timestamp);
    // Tableau des noms de mois en français
    const nomsMois = ['Janv.', 'Fév.', 'Mars', 'Avr.', 'Mai', 'Juin', 'Juil.', 'Août', 'Sept.', 'Oct.', 'Nov.', 'Déc.'];

    // Extraire les composants de la date
    const jour = dateInput.getDate();
    const mois = nomsMois[dateInput.getMonth()];
    const annee = dateInput.getFullYear();

    // Formater la date
    const dateFormatee = `${jour} ${mois} ${annee}`;
    return dateFormatee;
  }

  return '-'
},
 convertMillisecondToDate : (milliseconds) => {
  if ( milliseconds) {
    const date = new Date(parseInt(milliseconds));

    // Tableau des noms de mois en français
    const nomsMois = ['Janv.', 'Fév.', 'Mars', 'Avr.', 'Mai', 'Juin', 'Juil.', 'Août', 'Sept.', 'Oct.', 'Nov.', 'Déc.'];

    // Extraire les composants de la date
    const jour = date.getDate().toString().padStart(2, '0');
    const mois = nomsMois[date.getMonth()]; // Les mois commencent à 0
    const annee = date.getFullYear();

    // Formater la date
    const dateFormatee = `${jour} ${mois} ${annee}`;
    return dateFormatee;
  }

  return '-';
},
 convertDateToMilliseconds : (dateString) => {
 
  const monthsMapping = {
    'Janv.': 0, // January
    'Févr.': 1, // February
    'Mars': 2, // March
    'Avril': 3, // April
    'Mai': 4, // May
    'Juin': 5, // June
    'Juil.': 6, // July
    'Août': 7, // August
    'Sept.': 8, // September
    'Oct.': 9, // October
    'Nov.': 10, // November
    'Déc.': 11, // December
  };

  // Split the date string into day, month, and year
  const [day, month, year] = dateString.split(' ');

  // Get the numerical representation of the month from the mapping
  const numericMonth = monthsMapping[month];

  // Create a new Date object using the provided values
  const convertedDate = new Date(year, numericMonth, day);

  // Return the date in milliseconds
  return convertedDate.getTime();
},
// Function to convert the given date string to milliseconds
 convertDateStringToMilliseconds : (dateString) => {
   const dateObject = new Date(dateString);
   const timestampMilliseconds = dateObject.getTime();
 return timestampMilliseconds;
}


}

export default convertDate;

