import React from "react";
import UpdateContrat from "../../../common-component/update-service";

const DDComponent = (props) => {
  const { data, onHandleDdComponentData } = props;

  const handleChangeData = async (result, parentKey, keyElement) => {
    const getResult = typeof result === "string" ? result.trim() : result;
    if (keyElement) data[parentKey][keyElement] = getResult;
    else data[parentKey] = getResult;
    const {
      $collectionId,
      $createdAt,
      $databaseId,
      $permissions,
      $updatedAt,
      $id,
      ...rest
    } = data;
    onHandleDdComponentData($id, rest);
  };
  return (
    <dd className="col-sm-6">
      <ul className="verti-timeline list-unstyled">
        {data.contrat_type !== "Rupture Période d'Essai" &&
          data.contrat_type !== "Renouvellement Période d'Essai" &&
          data.contrat_type !== "Promesse d'embauche" && (
            <li className="event-list">
              <div>
                <span
                  style={{
                    fontSize: "18px",
                    margin: "0 0 7px 0",
                    textDecoration: "underline",
                    fontWeight: "normal",
                  }}
                >
                  Département de naissance
                </span>
                <span
                  className="verti-timeline list-unstyled"
                  style={{ border: "none" }}
                >
                  <div
                    style={{
                      fontSize: "14px",
                      fontWeight: "normal",
                    }}
                    className="event-list"
                  >
                    <div
                      style={{
                        backgroundColor: "#e6e2e2a1",
                        padding: "16px",
                        borderRadius: "12px",
                        marginTop: "5px",
                        justifyContent: "space-between",
                      }}
                      className="d-flex"
                    >
                      <span>
                        {data.employee_id === null ||
                        data.employee_id === undefined
                          ? null
                          : data.employee_id.employee_birth_department}
                      </span>
                      <UpdateContrat
                        typeInput="default"
                        title="Montant du capital social en euros"
                        InputValue={data.employee_id.employee_birth_department}
                        parentKey="employee_id"
                        keyElement="employee_birth_department"
                        OnUpdateValue={handleChangeData}
                      />
                    </div>
                  </div>
                </span>
              </div>
            </li>
          )}

        <li className="event-list">
          <div>
            <span
              style={{
                fontSize: "18px",
                margin: "0 0 7px 0",
                textDecoration: "underline",
                fontWeight: "normal",
              }}
            >
              Adresse
            </span>
            <span
              className="verti-timeline list-unstyled"
              style={{ border: "none" }}
            >
              <div
                style={{
                  fontSize: "14px",
                  fontWeight: "normal",
                }}
                className="event-list"
              >
                <div
                  style={{
                    backgroundColor: "#e6e2e2a1",
                    padding: "16px",
                    borderRadius: "12px",
                    marginTop: "5px",
                    justifyContent: "space-between",
                  }}
                  className="d-flex"
                >
                  <span>
                    {data.employee_id === null || data.employee_id === undefined
                      ? null
                      : data.employee_id.employee_adress}
                  </span>
                  <UpdateContrat
                    typeInput="default"
                    title="Adresse"
                    InputValue={data.employee_id.employee_adress}
                    parentKey="employee_id"
                    keyElement="employee_adress"
                    OnUpdateValue={handleChangeData}
                  />
                </div>
              </div>
            </span>
          </div>
        </li>
        <li className="event-list">
          <div>
            <span
              style={{
                fontSize: "18px",
                margin: "0 0 7px 0",
                textDecoration: "underline",
                fontWeight: "normal",
              }}
            >
              Code postal
            </span>
            <span
              className="verti-timeline list-unstyled"
              style={{ border: "none" }}
            >
              <div
                style={{
                  fontSize: "14px",
                  fontWeight: "normal",
                }}
                className="event-list"
              >
                <div
                  style={{
                    backgroundColor: "#e6e2e2a1",
                    padding: "16px",
                    borderRadius: "12px",
                    marginTop: "5px",
                    justifyContent: "space-between",
                  }}
                  className="d-flex"
                >
                  <span>
                    {data.employee_id === null || data.employee_id === undefined
                      ? null
                      : data.employee_id.employee_zipcode}
                  </span>
                  <UpdateContrat
                    typeInput="default"
                    title="Code postal"
                    InputValue={data.employee_id.employee_zipcode}
                    parentKey="employee_id"
                    keyElement="employee_zipcode"
                    OnUpdateValue={handleChangeData}
                  />
                </div>
              </div>
            </span>
          </div>
        </li>
        <li className="event-list">
          <div>
            <span
              style={{
                fontSize: "18px",
                margin: "0 0 7px 0",
                textDecoration: "underline",
                fontWeight: "normal",
              }}
            >
              Ville
            </span>
            <span
              className="verti-timeline list-unstyled"
              style={{ border: "none" }}
            >
              <p
                style={{
                  fontSize: "14px",
                  fontWeight: "normal",
                }}
                className="event-list"
              >
                <div
                  style={{
                    backgroundColor: "#e6e2e2a1",
                    padding: "16px",
                    borderRadius: "12px",
                    marginTop: "5px",
                    justifyContent: "space-between",
                  }}
                  className="d-flex"
                >
                  <span>
                    {data.employee_id === null || data.employee_id === undefined
                      ? null
                      : data.employee_id.employee_city}
                  </span>
                  <UpdateContrat
                    typeInput="default"
                    title="Ville"
                    InputValue={data.employee_id.employee_city}
                    parentKey="employee_id"
                    keyElement="employee_city"
                    OnUpdateValue={handleChangeData}
                  />
                </div>
              </p>
            </span>
          </div>
        </li>
        {data.contrat_type !== "Rupture Période d'Essai" && (
          <li className="event-list">
            <div>
              <span
                style={{
                  fontSize: "18px",
                  margin: "0 0 7px 0",
                  textDecoration: "underline",
                  fontWeight: "normal",
                }}
              >
                Intitulé du poste
              </span>
              <span
                className="verti-timeline list-unstyled"
                style={{ border: "none" }}
              >
                <div
                  style={{
                    fontSize: "14px",
                    fontWeight: "normal",
                  }}
                  className="event-list"
                >
                  <div
                    style={{
                      backgroundColor: "#e6e2e2a1",
                      padding: "16px",
                      borderRadius: "12px",
                      marginTop: "5px",
                      justifyContent: "space-between",
                    }}
                    className="d-flex"
                  >
                    <span>
                      {data.employee_id === null ||
                      data.employee_id === undefined
                        ? null
                        : data.employee_id.employee_job_title}
                    </span>
                    <UpdateContrat
                      typeInput="default"
                      title="Intitulé du poste"
                      InputValue={data.employee_id.employee_job_title}
                      parentKey="employee_id"
                      keyElement="employee_job_title"
                      OnUpdateValue={handleChangeData}
                    />
                  </div>
                </div>
              </span>
            </div>
          </li>
        )}
      </ul>
    </dd>
  );
};

export default DDComponent;
