import React, { useState } from 'react'

const InputComponent = (props) => {
    const {title,onInputChange,inputValue} = props
  const [input, setInput] = useState(inputValue);
 
    const handleInputChange = (e) => {
        const value = e.target.value;
        setInput(value);
        onInputChange({btnDisabled:value ==="" ? true : false, value:value});
    }
  return (
    <textarea
    className="form-control"
    id={`${title}-text`}
    value={input}
    onChange={handleInputChange}
  ></textarea>
  )
}

export default InputComponent