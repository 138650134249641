 
import React from 'react';
import Contrat from "../Contrat"; 
 
const ProcedureViolationDonnees  = () => {
    return (
      <div>
         <Contrat data = "Notification des violations de données à caractère personnel"/>
      </div>
    );
  };
 
export default ProcedureViolationDonnees;

 
 
 