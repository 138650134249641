import React from "react";
import UpdateContrat from "../../../common-component/update-service";

const DTComponent = (props) => {
  const { data, onHandleDtComponentData } = props;

  const handleChangeData = (result, parentKey, keyElement) => {
    const getResult = typeof result === "string" ? result.trim() : result;
    if (keyElement) data[parentKey][keyElement] = getResult;
    else data[parentKey] = getResult;
    const {
      $collectionId,
      $createdAt,
      $databaseId,
      $permissions,
      $updatedAt,
      $id,
      ...rest
    } = data;
    onHandleDtComponentData($id, rest);
  };
  return (
    <>
      {data.contrat_type !== "Modèle de Règlement Intérieur" &&
        data.contrat_type !==
          "Note d'information à la destination des salariés" && (
          <dt className="col-sm-6">
            <ul className="verti-timeline list-unstyled">
              <li className="event-list">
                <div>
                  <span
                    style={{
                      fontSize: "18px",
                      margin: "0 0 7px 0",
                      textDecoration: "underline",
                      fontWeight: "normal",
                    }}
                  >
                    Dénomination de l'entreprise
                  </span>
                  <span
                    className="verti-timeline list-unstyled"
                    style={{ border: "none" }}
                  >
                    <div
                      style={{
                        fontSize: "14px",
                        fontWeight: "normal",
                      }}
                      className="event-list "
                    >
                      <div
                        style={{
                          backgroundColor: "#e6e2e2a1",
                          padding: "16px",
                          borderRadius: "12px",
                          marginTop: "5px",
                          justifyContent: "space-between",
                        }}
                        className="d-flex"
                      >
                        <span>
                          {data.company_id === null ||
                          data.company_id === undefined
                            ? null
                            : data.company_id.company_name}
                        </span>
                        <UpdateContrat
                          typeInput="default"
                          title="Dénomination de l'entreprise"
                          InputValue={data.company_id.company_name}
                          parentKey="company_id"
                          keyElement="company_name"
                          OnUpdateValue={handleChangeData}
                        />
                      </div>
                    </div>
                  </span>
                </div>
              </li>
              {data.contrat_type !==
                "Information et procédure d'exercice des droits des personnes concernées" &&
                data.contrat_type !==
                  "Notification des violations de données à caractère personnel" && (
                  <>
                    {data.contrat_type !==
                      "Politique interne de protection des données" && (
                      <li className="event-list">
                        <div>
                          <span
                            style={{
                              fontSize: "18px",
                              margin: "0 0 7px 0",
                              textDecoration: "underline",
                              fontWeight: "normal",
                            }}
                          >
                            Adresse du siège social
                          </span>
                          <span
                            className="verti-timeline list-unstyled"
                            style={{ border: "none" }}
                          >
                            <div
                              style={{
                                fontSize: "14px",
                                fontWeight: "normal",
                              }}
                              className="event-list"
                            >
                              <div
                                style={{
                                  backgroundColor: "#e6e2e2a1",
                                  padding: "16px",
                                  borderRadius: "12px",
                                  marginTop: "5px",
                                  justifyContent: "space-between",
                                }}
                                className="d-flex"
                              >
                                <span>
                                  {data.company_id === null ||
                                  data.company_id === undefined
                                    ? null
                                    : data.company_id.company_headoffice_adress}
                                </span>

                                <UpdateContrat
                                  typeInput="default"
                                  title="Adresse du siège social"
                                  InputValue={
                                    data.company_id.company_headoffice_adress
                                  }
                                  parentKey="company_id"
                                  keyElement="company_headoffice_adress"
                                  OnUpdateValue={handleChangeData}
                                />
                              </div>
                            </div>
                          </span>
                        </div>
                      </li>
                    )}
                    {data.contrat_type !==
                      "Politique interne de protection des données" && (
                      <>
                        <li className="event-list">
                          <div>
                            <span
                              style={{
                                fontSize: "18px",
                                margin: "0 0 7px 0",
                                textDecoration: "underline",
                                fontWeight: "normal",
                              }}
                            >
                              Forme Social
                            </span>
                            <span
                              className="verti-timeline list-unstyled"
                              style={{ border: "none" }}
                            >
                              <div
                                style={{
                                  fontSize: "14px",
                                  fontWeight: "normal",
                                }}
                                className="event-list"
                              >
                                <div
                                  style={{
                                    backgroundColor: "#e6e2e2a1",
                                    padding: "16px",
                                    borderRadius: "12px",
                                    marginTop: "5px",
                                    justifyContent: "space-between",
                                  }}
                                  className="d-flex"
                                >
                                  <span>
                                    {data.company_id === null ||
                                    data.company_id === undefined
                                      ? null
                                      : data.company_id.company_social_form}
                                  </span>

                                  <UpdateContrat
                                    typeInput="default"
                                    title="Forme Social"
                                    InputValue={
                                      data.company_id.company_social_form
                                    }
                                    parentKey="company_id"
                                    keyElement="company_social_form"
                                    OnUpdateValue={handleChangeData}
                                  />
                                </div>
                              </div>
                            </span>
                          </div>
                        </li>
                        <li className="event-list">
                          <div>
                            <span
                              style={{
                                fontSize: "18px",
                                margin: "0 0 7px 0",
                                textDecoration: "underline",
                                fontWeight: "normal",
                              }}
                            >
                              Montant du capital social en euros
                            </span>
                            <span
                              className="verti-timeline list-unstyled"
                              style={{ border: "none" }}
                            >
                              <div
                                style={{
                                  fontSize: "14px",
                                  fontWeight: "normal",
                                }}
                                className="event-list"
                              >
                                <div
                                  style={{
                                    backgroundColor: "#e6e2e2a1",
                                    padding: "16px",
                                    borderRadius: "12px",
                                    marginTop: "5px",
                                    justifyContent: "space-between",
                                  }}
                                  className="d-flex"
                                >
                                  <span>
                                    {data.company_id === null ||
                                    data.company_id === undefined
                                      ? null
                                      : data.company_id.company_capital_amount}
                                  </span>
                                  <UpdateContrat
                                    typeInput="default"
                                    title="Montant du capital social en euros"
                                    InputValue={
                                      data.company_id.company_capital_amount
                                    }
                                    parentKey="company_id"
                                    keyElement="company_capital_amount"
                                    OnUpdateValue={handleChangeData}
                                  />
                                </div>
                              </div>
                            </span>
                          </div>
                        </li>

                        <li className="event-list">
                          <div>
                            <span
                              className=""
                              style={{
                                fontSize: "18px",
                                margin: "0 0 7px 0",
                                textDecoration: "underline",
                                fontWeight: "normal",
                              }}
                            >
                              Code postal de l'entreprise
                            </span>
                            <span
                              className="verti-timeline list-unstyled"
                              style={{ border: "none" }}
                            >
                              <div
                                style={{
                                  fontSize: "14px",
                                  fontWeight: "normal",
                                }}
                                className="event-list"
                              >
                                <div
                                  style={{
                                    backgroundColor: "#e6e2e2a1",
                                    padding: "16px",
                                    borderRadius: "12px",
                                    marginTop: "5px",
                                    justifyContent: "space-between",
                                  }}
                                  className="d-flex"
                                >
                                  <span>
                                    {data.company_id === null ||
                                    data.company_id === undefined
                                      ? null
                                      : data.company_id.company_zipcode}
                                  </span>
                                  <UpdateContrat
                                    typeInput="default"
                                    title="Code postal de l'entreprise"
                                    InputValue={data.company_id.company_zipcode}
                                    parentKey="company_id"
                                    keyElement="company_zipcode"
                                    OnUpdateValue={handleChangeData}
                                  />
                                </div>
                              </div>
                            </span>
                          </div>
                        </li>
                        <li className="event-list">
                          <div>
                            <span
                              style={{
                                fontSize: "18px",
                                margin: "0 0 7px 0",
                                textDecoration: "underline",
                                fontWeight: "normal",
                              }}
                            >
                              Ville du siège social
                            </span>
                            <span
                              className="verti-timeline list-unstyled"
                              style={{ border: "none" }}
                            >
                              <div
                                style={{
                                  fontSize: "14px",
                                  fontWeight: "normal",
                                }}
                                className="event-list"
                              >
                                <div
                                  style={{
                                    backgroundColor: "#e6e2e2a1",
                                    padding: "16px",
                                    borderRadius: "12px",
                                    marginTop: "5px",
                                    justifyContent: "space-between",
                                  }}
                                  className="d-flex"
                                >
                                  <span>
                                    {data.company_id === null ||
                                    data.company_id === undefined
                                      ? null
                                      : data.company_id.company_headoffice_city}
                                  </span>
                                  <UpdateContrat
                                    typeInput="default"
                                    title="Ville du siège social"
                                    InputValue={
                                      data.company_id.company_headoffice_city
                                    }
                                    parentKey="company_id"
                                    keyElement="company_headoffice_city"
                                    OnUpdateValue={handleChangeData}
                                  />
                                </div>
                              </div>
                            </span>
                          </div>
                        </li>
                      </>
                    )}
                    {data.contrat_type === "Modèle de Règlement Intérieur" && (
                      <>
                        <li className="event-list">
                          <div>
                            <span
                              style={{
                                fontSize: "18px",
                                margin: "0 0 7px 0",
                                textDecoration: "underline",
                                fontWeight: "normal",
                              }}
                            >
                              Horaire d'ouverture de votre société
                            </span>
                            <span
                              className="verti-timeline list-unstyled"
                              style={{ border: "none" }}
                            >
                              <p
                                style={{
                                  fontSize: "14px",
                                  fontWeight: "normal",
                                }}
                                className="event-list"
                              >
                                <div
                                  style={{
                                    backgroundColor: "#e6e2e2a1",
                                    padding: "16px",
                                    borderRadius: "12px",
                                    marginTop: "5px",
                                    justifyContent: "space-between",
                                  }}
                                  className="d-flex"
                                >
                                  <span>
                                    {data.company_id === null ||
                                    data.company_id === undefined
                                      ? null
                                      : data.company_id.company_name}
                                  </span>
                                  <UpdateContrat
                                    typeInput="default"
                                    title="Horaire d'ouverture de votre société"
                                    InputValue={data.company_id.company_name}
                                    parentKey="company_id"
                                    keyElement="company_name"
                                    OnUpdateValue={handleChangeData}
                                  />
                                </div>
                              </p>
                            </span>
                          </div>
                        </li>
                        <li className="event-list">
                          <div>
                            <span
                              style={{
                                fontSize: "18px",
                                margin: "0 0 7px 0",
                                textDecoration: "underline",
                                fontWeight: "normal",
                              }}
                            >
                              Nombre de jours maximal de mise à pied
                              disciplinaire
                            </span>
                            <span
                              className="verti-timeline list-unstyled"
                              style={{ border: "none" }}
                            >
                              <p
                                style={{
                                  fontSize: "14px",
                                  fontWeight: "normal",
                                }}
                                className="event-list"
                              >
                                <div
                                  style={{
                                    backgroundColor: "#e6e2e2a1",
                                    padding: "16px",
                                    borderRadius: "12px",
                                    marginTop: "5px",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  {data.company_id === null ||
                                  data.company_id === undefined
                                    ? null
                                    : data.company_id.company_name}
                                </div>
                              </p>
                            </span>
                          </div>
                        </li>
                        <li className="event-list">
                          <div>
                            <span
                              style={{
                                fontSize: "18px",
                                margin: "0 0 7px 0",
                                textDecoration: "underline",
                                fontWeight: "normal",
                              }}
                            >
                              Date rédaction du contract
                            </span>
                            <span
                              className="verti-timeline list-unstyled"
                              style={{ border: "none" }}
                            >
                              <p
                                style={{
                                  fontSize: "14px",
                                  fontWeight: "normal",
                                }}
                                className="event-list"
                              >
                                <div
                                  style={{
                                    backgroundColor: "#e6e2e2a1",
                                    padding: "16px",
                                    borderRadius: "12px",
                                    marginTop: "5px",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  {data.company_id === null ||
                                  data.company_id === undefined
                                    ? null
                                    : data.contrat_writing_date}
                                </div>
                              </p>
                            </span>
                          </div>
                        </li>
                      </>
                    )}

                    {data.contrat_type ===
                      "Politique interne de protection des données" && (
                      <li className="event-list">
                        <div>
                          <span
                            style={{
                              fontSize: "18px",
                              margin: "0 0 7px 0",
                              textDecoration: "underline",
                              fontWeight: "normal",
                            }}
                          >
                            Adresse de l'entreprise
                          </span>
                          <span
                            className="verti-timeline list-unstyled"
                            style={{ border: "none" }}
                          >
                            <div
                              style={{
                                fontSize: "14px",
                                fontWeight: "normal",
                              }}
                              className="event-list"
                            >
                              <div
                                style={{
                                  backgroundColor: "#e6e2e2a1",
                                  padding: "16px",
                                  borderRadius: "12px",
                                  marginTop: "5px",
                                  justifyContent: "space-between",
                                }}
                                className="d-flex"
                              >
                                <span>
                                  {data.company_id === null ||
                                  data.company_id === undefined
                                    ? null
                                    : data.company_id.company_adress}
                                </span>

                                <UpdateContrat
                                  typeInput="default"
                                  title="Adresse de l'entreprise"
                                  InputValue={data.company_id.company_adress}
                                  parentKey="company_id"
                                  keyElement="company_adress"
                                  OnUpdateValue={handleChangeData}
                                />
                              </div>
                            </div>
                          </span>
                        </div>
                      </li>
                    )}
                  </>
                )}
            </ul>
          </dt>
        )}
    </>
  );
};

export default DTComponent;
