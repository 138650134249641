import React, { useState, useEffect } from 'react';

const ScrollToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition  = window.scrollY + window.innerHeight ;
      const scrollThreshold = document.documentElement.scrollHeight - 600;
      setIsVisible(scrollPosition >= scrollThreshold);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    isVisible && (
      <button className='btn btn-rounded btn-danger' onClick={scrollToTop} style={{ position: 'fixed', bottom: '20px', right: '20px', zIndex: '999' }}>
       <i className='  fas fa-arrow-up font-size-18'></i>
      </button>
    )
  );
};

export default ScrollToTopButton;
