import React, { useState } from "react";
import { Row, Card, CardBody } from "reactstrap";
import UpdateCompany from "../../common-component/update-service";

const SocialReason = (props) => {
  const { title, socialReasonData, onUpdateSocialReason } = props;
  const [isHovered, setIsHovered] = useState({
    hoverState1: false,
    hoverState2: false,
    hoverState3: false,
    hoverState4: false,
  });
  const handleChangeData = (result, parentKey, keyElement) => {
    const getResult = typeof result === "string" ? result.trim() : result;
    if (keyElement) socialReasonData[parentKey][keyElement] = getResult;
    else socialReasonData[parentKey] = getResult;
    const {
      $collectionId,
      $createdAt,
      $databaseId,
      $permissions,
      $updatedAt,
      $id,
      ...rest
    } = socialReasonData;
    onUpdateSocialReason($id, rest);
  };
  return (
    <Row>
      <Card>
        <CardBody>
          <h4 className="card-title mb-5 text-primary">{title}</h4>
          <dl className="row mb-0">
            {/* Start DT */}
            <dt className="col-sm-6">
              <ul className="verti-timeline list-unstyled">
                <li className="event-list">
                  <div>
                    <span
                      style={{
                        fontSize: "18px",
                        margin: "0 0 7px 0",
                        textDecoration: "underline",
                        fontWeight: "normal",
                      }}
                    >
                      Société
                    </span>
                    <span
                      className="verti-timeline list-unstyled"
                      style={{ border: "none" }}
                      onMouseEnter={() =>
                        setIsHovered((prev) => ({ ...prev, hoverState1: true }))
                      }
                      onMouseLeave={() =>
                        setIsHovered((prev) => ({ ...prev, hoverState1: false }))
                      }
                    >
                      <div
                        style={{
                          fontSize: "14px",
                          fontWeight: "normal",
                        }}
                        className="event-list"
                      >
                        <div
                          style={{
                            backgroundColor: "#e6e2e2a1",
                            padding: "16px",
                            borderRadius: "12px",
                            marginTop: "5px",
                            justifyContent: "space-between",
                          }}
                          className="d-flex"
                        >
                          <span style={{ fontSize: "14px" }}>
                            {socialReasonData.company_name}
                          </span>
                          {isHovered.hoverState1 && (<UpdateCompany
                            typeInput="default"
                            title="Société"
                            InputValue={socialReasonData.company_name}
                            parentKey="company_name"
                            OnUpdateValue={handleChangeData}
                          />)}
                          
                        </div>
                      </div>
                    </span>
                  </div>
                </li>
                <li className="event-list">
                  <div>
                    <span
                      style={{
                        fontSize: "18px",
                        margin: "0 0 7px 0",
                        textDecoration: "underline",
                        fontWeight: "normal",
                      }}
                    >
                      Adresse email
                    </span>
                    <span
                      className="verti-timeline list-unstyled"
                      style={{ border: "none" }}
                      onMouseEnter={() => setIsHovered({...isHovered, hoverState2: true })}
                      onMouseLeave={() => setIsHovered({...isHovered, hoverState2: false })}
                    >
                      <div
                        style={{
                          fontSize: "14px",
                          fontWeight: "normal",
                        }}
                        className="event-list"
                      >
                        <div
                          style={{
                            backgroundColor: "#e6e2e2a1",
                            padding: "16px",
                            borderRadius: "12px",
                            marginTop: "5px",
                            justifyContent: "space-between",
                          }}
                          className="d-flex"
                        >
                          <span style={{ fontSize: "14px" }}>
                            {socialReasonData.company_email}
                          </span>
                          {isHovered.hoverState2 && (<UpdateCompany
                            typeInput="default"
                            title="Adresse email"
                            InputValue={socialReasonData.company_email}
                            parentKey="company_email"
                            OnUpdateValue={handleChangeData}
                          />)}
                          
                        </div>
                      </div>
                    </span>
                  </div>
                </li>
              </ul>
            </dt>
            {/* End DT */}

            {/* Start DD */}
            
            <dd className="col-sm-6">
              <ul className="verti-timeline list-unstyled">
              {socialReasonData.company_description && (<li className="event-list">
                  <div>
                    <span
                      style={{
                        fontSize: "18px",
                        margin: "0 0 7px 0",
                        textDecoration: "underline",
                        fontWeight: "normal",
                      }}
                    >
                      Description de l'entreprise
                    </span>
                    <span
                      className="verti-timeline list-unstyled"
                      style={{ border: "none" }}
                      onMouseEnter={() =>
                        setIsHovered((prev) => ({ ...prev, hoverState3: true }))
                      }
                      onMouseLeave={() =>
                        setIsHovered((prev) => ({ ...prev, hoverState3: false }))
                      }
                    >
                      <div
                        style={{
                          fontSize: "14px",
                          fontWeight: "normal",
                        }}
                        className="event-list"
                      >
                        <div
                          style={{
                            backgroundColor: "#e6e2e2a1",
                            padding: "16px",
                            borderRadius: "12px",
                            marginTop: "5px",
                            justifyContent: "space-between",
                          }}
                          className="d-flex"
                        >
                          <span style={{ fontSize: "14px" }}>
                            {socialReasonData.company_description}
                          </span>
                          {isHovered.hoverState3 && (<UpdateCompany
                            typeInput="default"
                            title="Description"
                            InputValue={socialReasonData.company_description}
                            parentKey="company_description"
                            OnUpdateValue={handleChangeData}
                          />)}
                          
                        </div>
                      </div>
                    </span>
                  </div>
                </li>)}
                
                <li className="event-list">
                  <div>
                    <span
                      style={{
                        fontSize: "18px",
                        margin: "0 0 7px 0",
                        textDecoration: "underline",
                        fontWeight: "normal",
                      }}
                    >
                      Représentant
                    </span>
                    <span
                      className="verti-timeline list-unstyled"
                      style={{ border: "none" }}
                      onMouseEnter={() =>
                        setIsHovered((prev) => ({...prev, hoverState4: true }))
                      }
                      onMouseLeave={() =>
                        setIsHovered((prev) => ({...prev, hoverState4: false }))
                      }
                    >
                      <div
                        style={{
                          fontSize: "14px",
                          fontWeight: "normal",
                        }}
                        className="event-list"
                      >
                        <div
                          style={{
                            backgroundColor: "#e6e2e2a1",
                            padding: "16px",
                            borderRadius: "12px",
                            marginTop: "5px",
                            justifyContent: "space-between",
                          }}
                          className="d-flex"
                        >
                          <span style={{ fontSize: "14px" }}>
                            {socialReasonData.company_price_name}
                          </span>
                          {isHovered.hoverState4 && (<UpdateCompany
                            typeInput="default"
                            title="Représentant"
                            InputValue={socialReasonData.company_price_name}
                            parentKey="company_price_name"
                            OnUpdateValue={handleChangeData}
                          />)}
                          
                        </div>
                      </div>
                    </span>
                  </div>
                </li>
              </ul>
            </dd>
            {/* End DD */}
          </dl>
        </CardBody>
      </Card>
    </Row>
  );
};

export default SocialReason;
