
import React, { Component } from 'react';
import Contrat from "../Contrat"; 
 
const ModelRI  = () => {
    return (
      <div>
         <Contrat data = "Modèle de Règlement Intérieur"/>
      </div>
    );
  };
 
export default ModelRI;

 
 
 
 

 